import React from 'react';
import { useSelector } from 'react-redux';
import { isImage, isLower } from '../../../shared-logic/taskLevelsTypesHelper';
import KeyboardShortcuts from './KeyboardShortcuts';
import { getSummary } from '../shared-logic';
import { lowerConflictedTeethSelector } from '../../../redux/taskStateImages/stateImagesLowerSlice';
import { upperConflictedTeethSelector } from '../../../redux/taskStateImages/stateImagesUpperSlice';
import {
  currentTaskSelector,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  picturesSelector,
  outputSelector,
} from '../../../redux/taskStateImages/outputSlice';
import { shapeInProgressSelector } from '../../../redux/marks/currentMarkingSlice';
import { isEqual } from 'lodash';

const KeyboardShortcutsContainer = () => {
  const lowerConflictedTeeth = useSelector(lowerConflictedTeethSelector);
  const upperConflictedTeeth = useSelector(upperConflictedTeethSelector);
  const currentTask = useSelector(currentTaskSelector);
  const pictures = useSelector(picturesSelector);
  const tier = useSelector(tierSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const output = useSelector(outputSelector, isEqual);
  const shapeInProgress = useSelector(shapeInProgressSelector);

  const numPictures = pictures.length;

  const conflictedTeeth = isLower(currentTask)
    ? lowerConflictedTeeth
    : upperConflictedTeeth;

  const { committed_images, total_case_images } = getSummary(
    isImage(taskLevel),
    output,
    numPictures,
    tier,
    conflictedTeeth
  );

  return (
    <KeyboardShortcuts
      numMarked={committed_images}
      numTotal={total_case_images}
      taskLevel={taskLevel}
      tier={tier}
      shapeInProgress={shapeInProgress}
    />
  );
};

export default React.memo(KeyboardShortcutsContainer);
