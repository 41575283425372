import { useSelector, useDispatch } from 'react-redux';
import { disableShortcutsSelector } from '../../../redux/labelingTool/labelingToolSlice';
import { commitSelector } from '../../../redux/taskStateImages/outputSlice';
import { shapeInProgressSelector } from '../../../redux/marks/currentMarkingSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { setCommit } from '../../../redux/taskStateImages/outputSlice';
import useShouldDisableMarking from './useShouldDisableMarking';

const useEnterCommitAction = () => {
  const dispatch = useDispatch();
  const disableShortcuts = useSelector(disableShortcutsSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const commit = useSelector(commitSelector);

  const disableMarking = useShouldDisableMarking();
  const enterKeyAction = () => {
    if (!disableShortcuts && !disableMarking && !shapeInProgress) {
      userActionLogs.addActionLog(`commit set to ${!commit} via enter key`);
      dispatch(setCommit(!commit));
    }
  };
  return enterKeyAction;
};

export default useEnterCommitAction;
