import React, { useState, useEffect } from 'react';
import styles from './FindingsConsole.module.css';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import tasksListLogic from '../TasksList.logic';

const name = 'name';
const explanation = 'explanation';
const FindingsConsole = ({ isEdit }) => {
  const [findingName, setFindingName] = useState('');
  const [details, setDetails] = useState([{ name: '', explanation: '' }]);
  const [findingsList, setFindingsList] = useState([]);

  const fetchData = async () => {
    try {
      setFindingsList(await tasksListLogic.getListOfFindings());
    } catch (error) {
      setFindingsList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (index, key, value) => {
    const newDetails = [...details];
    newDetails[index][key] = value;
    setDetails(newDetails);
  };

  const removeDetail = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setDetails(newDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFinding = {
      findingName,
      details,
      isEdit,
    };

    try {
      await tasksListLogic.postListOfFindings(newFinding);
      setFindingName('');
      setDetails([{ name: '', explanation: '' }]);
      fetchData();

      NotificationManager.success(
        isEdit ? 'Finding success edit' : 'Finding success add',
        'Success!'
      );
    } catch (error) {
      NotificationManager.error(
        'An error occurred while adding the finding. Please try again later.',
        'Warning'
      );
    }
  };

  const addDetail = () => {
    setDetails([...details, { name: '', explanation: '' }]);
  };

  const selectfindingName = (findingName) => {
    const finding = findingsList.find(
      (finding) => finding.FindingName === findingName
    );
    if (!finding) return;
    setFindingName(findingName);
    setDetails(JSON.parse(finding.Details));
  };

  return (
    <div className={styles.formContainer}>
      <label className={styles.label}>Finding Name:</label>
      {!isEdit ? (
        <input
          type="text"
          value={findingName}
          onChange={(e) => setFindingName(e.target.value)}
          className={styles.input}
          required
        />
      ) : (
        <select
          value={findingName}
          onChange={(e) => selectfindingName(e.target.value)}
          className={styles.select}
        >
          <option value="">Select a finding</option>
          {findingsList.map((item) => (
            <option key={item.FindingName} value={item.FindingName}>
              {item.FindingName}
            </option>
          ))}
        </select>
      )}
      <br />
      {details.map((detail, index) => (
        <div key={index} className={styles.detailContainer}>
          <label className={styles.label}>Detail Name:</label>
          <input
            type="text"
            value={detail.name}
            onChange={(e) => handleChange(index, name, e.target.value)}
            className={styles.input}
          />

          <br />
          <label className={styles.label}>Detail Explanation:</label>
          <input
            type="text"
            value={detail.explanation}
            onChange={(e) => handleChange(index, explanation, e.target.value)}
            className={styles.input}
          />
          <br />
        </div>
      ))}
      <button type="button" onClick={addDetail} className={styles.addButton}>
        Add Detail
      </button>
      <br />
      <button
        type="submit"
        onClick={handleSubmit}
        className={styles.submitButton}
      >
        {' '}
        {isEdit ? 'Edit Finding' : 'Add Finding'}
      </button>
    </div>
  );
};

FindingsConsole.propTypes = {
  isEdit: PropTypes.bool,
};

export default FindingsConsole;
