import * as AC from './attributeConstants';
import { getUser } from '../../config/adalConfig';
import {
  API_ENDPOINT,
  BUCKET_NAME,
  BUCKET_NAME_CONFIG,
} from '../../config/awsLabelingConfig';
import logger from '../../logger';
import { get, post } from '../../shared-logic/fetchApi';
import Roles from './rolesEnum';
import { TaskLevels, Tiers, TaskTypes } from '../../shared-logic/enums';
import {
  getVariant,
  getTier1LabelersNumber,
  getUserVariants,
  getVariantList,
} from '../../config/configUtil';
import { isTier1 } from '../../shared-logic/tiersHelpers';
import { NotificationManager } from 'react-notifications';

import { postData } from '../LabelingToolPage/LabelingToolPage.logic';

const users = 'users';
const tier2 = 'tier2';
const tier1 = 'tier1';
const tier3 = 'tier3';

const params = 'params';
const tasks_source = 'tasks_source';
const hard_coded = 'hard_coded';
const batch = 'batches';
const number_of_labelers = 'number_of_labelers';

const variantsRoles = new Map();

async function getTasksData(
  url = '',
  isSupervisorOrWatcher = false,
  isTier2Reviewer = false
) {
  const tasks = [];
  try {
    let data = await get(url);
    if (data) {
      const filteredData = filterOutPotentialDeletedTasks(data);
      const filteredCompletedTasks = isSupervisorOrWatcher
        ? filteredData
        : isTier2Reviewer
        ? filterTier2AndCompletedTasks(filteredData)
        : filterNotCompletedTasks(filteredData);
      filteredCompletedTasks.forEach((task) => {
        tasks.push(restructureTask(task));
      });
      return tasks;
    } else {
      return null;
    }
  } catch (err) {
    logger
      .error('getTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

const filterOutPotentialDeletedTasks = (tasks) => {
  return tasks.filter((task) => !task[AC.POTENTIALLY_DISCARDED]);
};

const filterNotCompletedTasks = (tasks) => {
  return tasks.filter(
    (task) =>
      !task[AC.COMPLETED] || task[AC.COMPLETED].toLowerCase() === 'false'
  );
};

const filterTier2AndCompletedTasks = (tasks) => {
  return tasks.filter(
    (task) =>
      task[AC.COMPLETED] &&
      task[AC.COMPLETED].toLowerCase() === 'true' &&
      task[AC.TIER] === 2
  );
};

const restructureTask = (task) => {
  let percentCompleted = 0;
  if (task[AC.COMMITTED_IMAGES]) {
    percentCompleted = calculatePercentCompleted(task);
  }
  const tier = task[AC.TIER] ? parseInt(task[AC.TIER]) : Tiers.TIER_1;
  return {
    TaskId: task[AC.TASK_SHORT_NICKNAME],
    PathToData: task[AC.OBJECT_KEY].split('/DataMarkingTask')[0],
    PercentCompleted: percentCompleted,
    Assignee: task[AC.ASSIGNEE],
    Tier: tier,
    Type: task[AC.TYPE],
    Level: task[AC.LEVEL],
    Batch: task[AC.BATCH],
    Completed: task[AC.COMPLETED],
    InTier1: task[AC.IN_TIER1],
    ShowMissPointsInProgress: task[AC.SHOW_MISSPOINTS_INPROGRESS],
  };
};

async function getDeletedTasksData(url = '') {
  const tasks = [];
  try {
    const data = await get(url);
    data.forEach((task) => {
      const obj = {
        TaskId: task[AC.TASK_SHORT_NICKNAME],
        Type: task[AC.TYPE],
        Reason: task[AC.REASON],
        Level: task[AC.LEVEL],
        Assignee: task[AC.ASSIGNEE],
      };
      tasks.push(obj);
    });

    return tasks;
  } catch (err) {
    logger
      .error('getDeletedTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

async function getPotentiallyDeletedTasksData(url = '') {
  const tasks = [];
  try {
    const data = await get(url);
    data.forEach((task) => {
      const obj = {
        TaskId: task[AC.TASK_SHORT_NICKNAME],
        Type: task[AC.TYPE],
        Reason: task[AC.REASON],
        Level: task[AC.LEVEL],
        PathToData: task[AC.OBJECT_KEY].split('/DataMarkingTask')[0],
        Assignee: task[AC.ASSIGNEE],
        Tier: task[AC.TIER],
        Batch: task[AC.BATCH],
        Completed: task[AC.COMPLETED],
        InTier1: task[AC.IN_TIER1],
        PotentiallyDiscarded: task[AC.POTENTIALLY_DISCARDED],
      };
      tasks.push(obj);
    });

    return tasks;
  } catch (err) {
    logger
      .error('getPotentiallyDeletedTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

function calculatePercentCompleted(task) {
  return Math.floor(
    (task[AC.COMMITTED_IMAGES] / task[AC.TOTAL_CASE_IMAGES]) * 100
  );
}

function variantDoesNotExistOrNoParams(variant) {
  return !variant || !variant.hasOwnProperty(params);
}

async function getTask(url = '') {
  try {
    return await get(url);
  } catch (err) {
    logger
      .error('getTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

async function getLabelersByNickNameData(url = '') {
  try {
    return await get(url);
  } catch (err) {
    logger
      .error('getLabelersByNickName')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

const tasksListLogic = {
  async checkExecutionAndRetrieveOutput(executionArn, maxTimeout = 60000) {
    try {
      const running = 'RUNNING';
      let stepFunctionOutput = running;
      let startTime = Date.now();

      while (stepFunctionOutput === running) {
        stepFunctionOutput = await tasksListLogic.checkExecutionStatus(
          executionArn
        );
        if (stepFunctionOutput === running) {
          if (Date.now() - startTime >= maxTimeout) {
            NotificationManager.error(
              'Timeout: No positive response within configured time'
            );
            throw new Error(
              'Timeout: No positive response within configured time'
            );
          }

          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      }
      return stepFunctionOutput;
    } catch (error) {
      console.error('Failed to retrieve Step Function output:', error);
    }
  },

  getNumberOfLabelersPerTask(config, variantId) {
    const defaultLabelersNumber = getTier1LabelersNumber(config, variantId);
    const variant = getVariant(config, variantId);
    const numberOfLabelers = variant && variant[number_of_labelers];
    return numberOfLabelers ? numberOfLabelers : defaultLabelersNumber;
  },

  getUserName() {
    const user = getUser();
    return user ? user.userName : null;
  },
  getUserVariantsAndRoles(config, user) {
    return getUserVariants(config, user, variantsRoles);
  },
  getListOfUsers() {
    return get(`${API_ENDPOINT}/getUsersList`);
  },
  getListOfBatches() {
    return get(`${API_ENDPOINT}/getBatchesList`);
  },
  getListOfFindings() {
    return get(`${API_ENDPOINT}/getFindingsList`);
  },
  postListOfFindings(newFinding) {
    return postData(`${API_ENDPOINT}/addOrEditFinding`, newFinding);
  },
  getTasksByTier(variant, users, tier, id) {
    let counter = id;
    const filterTasks = Object.keys(variant[users]).filter((name) =>
      variant[users][name].includes(tier)
    );

    return filterTasks.map((task) => ({
      id: counter++,
      name: task,
      tier: tier,
    }));
  },
  getAssignees(config, variantId, user, role) {
    if (!variantsRoles.has(variantId)) return [];
    if (!role) role = variantsRoles.get(variantId)[0];
    const variant = getVariant(config, variantId);
    if (!variant || !variant[users] || !variant[users][user]) return [];
    switch (role) {
      case Roles.SUPERVISOR:
      case Roles.TIER2_REVIEWER:
      case Roles.WATCHER:
        const tier1Tasks = tasksListLogic.getTasksByTier(
          variant,
          users,
          tier1,
          0
        );
        const tier2Tasks = tasksListLogic.getTasksByTier(
          variant,
          users,
          tier2,
          tier1Tasks.length
        );
        const tier3Tasks = tasksListLogic.getTasksByTier(
          variant,
          users,
          tier3,
          tier1Tasks.length + tier2Tasks.length
        );

        return [...tier1Tasks, ...tier2Tasks, ...tier3Tasks];
      default:
        if (variant[users][user].includes(tier1)) {
          return [{ name: user, tier: Tiers.TIER_1 }];
        } else if (variant[users][user].includes(tier2)) {
          return [{ name: user, tier: Tiers.TIER_2 }];
        } else {
          return [{ name: user, tier: Tiers.TIER_3 }];
        }
    }
  },
  async getTasks(
    config,
    variantId,
    labeler,
    isSupervisor = false,
    isTier2Reviewer = false,
    role,
    subNickName
  ) {
    const variant = getVariantList(config, variantId);
    if (!variant || !variant.hasOwnProperty(params)) return [];
    const tasksSource = variant[params][tasks_source];
    if (Object.keys(tasksSource)[0] === hard_coded) {
      return tasksSource[hard_coded][
        isSupervisor ? labeler.labeler : labeler.id
      ];
    } else {
      const batches = variant[params][batch];
      const tierNumber = parseInt(isSupervisor ? labeler.tier[4] : role[4]); // example: role ="tier1", tierNumber = 1;
      let data = await getTasksData(
        `${API_ENDPOINT}/getTasks/?assignee=${
          isSupervisor ? labeler.labeler : labeler.id
        }&batches=${batches}&tier=${tierNumber}&subNickname=${subNickName}`,
        isSupervisor,
        isTier2Reviewer
      );
      if (!data) {
        return [];
      }
      data.forEach((t) => {
        t.id = `${t.TaskId}${t.Level ? t.Level[0] : ''} (${
          t.PercentCompleted
        }%)`;
        t.Type = t.Type || TaskTypes._5D;
        t.Level = t.Level || TaskLevels.IMAGE;
        t.Completed =
          t.Completed && t.Completed.toLowerCase() === 'true' ? true : false;
      });
      return data;
    }
  },
  async getTask(
    config,
    variantId,
    labeler,
    labelersNumber,
    role,
    specificTask
  ) {
    const variant = getVariant(config, variantId);
    if (!variant || !variant.hasOwnProperty(params)) return [];
    const tasksSource = variant[params][tasks_source];
    const tierNumber = parseInt(role[4]); // example: role ="tier1", tierNumber = 1;
    if (Object.keys(variant[params][tasks_source])[0] === hard_coded) {
      return tasksSource[hard_coded][labeler.id];
    } else {
      const batches = variant[params][batch];
      const maxLabelers = isTier1(tierNumber) ? labelersNumber : 1;
      const task = await getTask(
        `${API_ENDPOINT}/getNextTask/?assignee=${labeler.id}&batches=${batches}&bucket=${BUCKET_NAME}&maxLabelers=${maxLabelers}&tier=${tierNumber}&specificTask=${specificTask}`
      );

      return task
        ? {
            TaskId: task[AC.TASK_SHORT_NICKNAME],
            PathToData: task[AC.OBJECT_KEY].split('/DataMarkingTask')[0],
            PercentCompleted: 0,
            Assignee: task[AC.ASSIGNEE],
            Tier: tierNumber,
            Batch: task[AC.BATCH],
            Type: task[AC.TYPE] || TaskTypes._5D,
            Level: task[AC.LEVEL],
            id: `${task[AC.TASK_SHORT_NICKNAME]}${
              task[AC.LEVEL] ? task[AC.LEVEL][0] : ''
            } (0%)`,
            InTier1: task[AC.IN_TIER1],
          }
        : null;
    }
  },
  async getDeletedTasks(config, variantId) {
    const variant = getVariant(config, variantId);
    if (variantDoesNotExistOrNoParams(variant)) return [];
    if (Object.keys(variant[params][tasks_source])[0] === hard_coded) {
      return [];
    } else {
      const batches = variant[params][batch];
      const promises = [];
      promises.push(
        getDeletedTasksData(
          `${API_ENDPOINT}/getDeletedTasks?batches=${batches}`
        ),
        getPotentiallyDeletedTasksData(
          `${API_ENDPOINT}/getPotentiallyDeletedTasks?batches=${batches}`
        )
      );
      const promisesList = await Promise.all(promises);
      return {
        deletedTasks: promisesList[0],
        potentiallyDeletedTasks: promisesList[1],
      };
    }
  },

  async getLabelersByNickName(config, variantId, searchSubNickName) {
    const variant = getVariant(config, variantId);
    if (variantDoesNotExistOrNoParams(variant)) return [];
    const batches = variant[params][batch];
    return await getLabelersByNickNameData(
      `${API_ENDPOINT}/getLabelersByNickName/?batches=${batches}&subNickName=${searchSubNickName}`
    );
  },

  async checkExecutionStatus(executionArn) {
    try {
      const res = await get(
        `${API_ENDPOINT}/getResultStepFunction/?executionArn=${executionArn}`
      );
      return res.status || res;
    } catch (err) {
      return null;
    }
  },

  async importTasks() {
    return await post(`${API_ENDPOINT}/importToTier2`);
  },

  async exportBatch(zipData) {
    const url = `${API_ENDPOINT}/exportData`;
    const data = {
      selectedFile: zipData,
    };
    return await postData(url, data);
  },

  async getPresignedUrlPutImportData(selectedFileName) {
    return await get(
      `${API_ENDPOINT}/getPresignedUrlImportData/?bucketName=${BUCKET_NAME_CONFIG}&path=${selectedFileName}`
    );
  },
  async exportLabelsForAi(type, batches) {
    return await get(
      `${API_ENDPOINT}/exportLabelsForAi/?&type=${type}&batches=${batches}`
    );
  },
  async unTakeTask(taskLevel, tier, selectedLabelerId, path) {
    return await postData(
      `${API_ENDPOINT}/returnTask/?taskLevel=${taskLevel.toLowerCase()}&tier=${tier}&selectedLabeler=${selectedLabelerId}&directory=${path}`
    );
  },
  async getMonitoringReport(
    optionsForExport,
    batchToVariantJson,
    dateFrom,
    dateTo,
    userName
  ) {
    return await postData(`${API_ENDPOINT}/getMonitoringReport`, {
      optionsForExport: optionsForExport,
      batchToVariantJson: batchToVariantJson,
      dateFrom: dateFrom,
      dateTo: dateTo,
      userName: userName,
    });
  },

  async getOutputReport(path) {
    try {
      const res = await get(`${API_ENDPOINT}/fetchConfig/?fileName=${path}`);

      return res;
    } catch (error) {
      logger
        .error('getOutputReport')
        .data({ module: 'TaskList.logic', err: error })
        .end();
      return [];
    }
  },
  async changeFavoriteVariant(userName, favoriteVariant) {
    return await postData(
      `${API_ENDPOINT}/changeFavoriteVariant?userName=${userName}&favoriteVariant=${favoriteVariant}`
    );
  },
  async sendBugReportByUser(user, bug, screenshotBlob, taskShortNickName) {
    return await postData(`${API_ENDPOINT}/sendBugReportByUser`, {
      user: user,
      bug: bug,
      screenshotBlob: screenshotBlob,
      taskShortNickName: taskShortNickName,
    });
  },
};

export default tasksListLogic;
