import { createSlice } from '@reduxjs/toolkit';

export const stateImagesUpperSlice = createSlice({
  name: 'stateImagesUpper',
  initialState: {
    upperState: {},
    upperImages: [],
    upperNotes: undefined,
    upperPrevTierState: undefined,
    upperConflictedTeeth: undefined,
  },
  reducers: {
    setUpperState: (state, action) => {
      state.upperState = action.payload;
    },
    setUpperImages: (state, action) => {
      state.upperImages = action.payload;
    },
    setUpperNotes: (state, action) => {
      state.upperNotes = action.payload;
    },
    setUpperPrevTierState: (state, action) => {
      state.upperPrevTierState = action.payload;
    },
    setUpperConflictedTeeth: (state, action) => {
      state.upperConflictedTeeth = action.payload;
    },
  },
});

export const {
  setUpperState,
  setUpperImages,
  setUpperNotes,
  setUpperPrevTierState,
  setUpperConflictedTeeth,
} = stateImagesUpperSlice.actions;

export const upperStateSelector = (state) => state.stateImagesUpper.upperState;
export const upperImagesSelector = (state) =>
  state.stateImagesUpper.upperImages;
export const upperNotesSelector = (state) => state.stateImagesUpper.upperNotes;
export const upperPrevTierStateSelector = (state) =>
  state.stateImagesUpper.upperPrevTierState;
export const upperConflictedTeethSelector = (state) =>
  state.stateImagesUpper.upperConflictedTeeth;

export default stateImagesUpperSlice.reducer;
