import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './OpenSelect.module.css';
import { findingsDetails } from '../../MarkLabeling/Labeling/FindingsDetails.js';
import KeyCodes from '../../../../shared-logic/enums/keyCodesEnum';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDetails,
  setFindings,
  recentlyUsedDetailsSelector,
  setOpenSelectHistory,
} from '../../../../redux/marks/currentMarkingSlice';
import { labelDetailsTier2Selector } from '../../../../redux/taskState/taskDetailsSlice';
import { findingsList } from '../../../../config/configUtil';
import {
  selectVariant,
  selectConfig,
} from '../../../../redux/tasks/tasksSlice';
import { useSetRecentlyUsedDetails } from '../../customHooks';

const OpenSelect = ({ openSelectHistory, left, top }) => {
  const dispatch = useDispatch();
  const setRecentlyUsedDetails = useSetRecentlyUsedDetails();
  const labelDetailsTier2 = useSelector(labelDetailsTier2Selector);
  const recentlyUsedDetails = useSelector(recentlyUsedDetailsSelector);

  const [selected, setSelected] = useState(null);

  const [active, setActive] = useState(0);
  const [keysPressed, setKeysPressed] = useState([]);
  const ref = useRef(true);
  const variant = useSelector(selectVariant);
  const config = useSelector(selectConfig);

  const [allDetails, setAllDetails] = useState([]);

  useEffect(() => {
    if (openSelectHistory) {
      setFilteredItemList(recentlyUsedDetails);
      setActive(0);
    }
  }, [openSelectHistory, recentlyUsedDetails]);

  useEffect(() => {
    const findingsOfVariant = findingsList(config, variant);

    const findings = findingsOfVariant
      ? findingsOfVariant.map((x) => findingsDetails[x])
      : findingsDetails;

    Object.values(findings).forEach(
      (list) =>
        list &&
        setAllDetails((v) => {
          v.push(...list.slice(1));
          return v;
        })
    );
  }, [config, variant]);

  const [filteredItemList, setFilteredItemList] = useState([]);

  useEffect(() => {
    setKeysPressed([]);
    dispatch(setOpenSelectHistory(false));
    setFilteredItemList([]);
  }, [dispatch]);

  useEffect(() => {
    setKeysPressed([]);
    setFilteredItemList(allDetails);
  }, [allDetails]);

  const getFindings = useCallback(() => {
    const keys = Object.keys(findingsDetails);
    for (let i = 0; i < keys.length; i++) {
      if (findingsDetails[keys[i]].find((value) => value === selected)) {
        return keys[i];
      }
    }
  }, [selected]);

  useEffect(() => {
    if (!ref.current) {
      const findings = getFindings();
      dispatch(setFindings(findings));
      dispatch(setDetails(selected));
    } else {
      ref.current = false;
    }
  }, [selected, dispatch, getFindings]);

  useEffect(() => {
    if (selected) {
      setRecentlyUsedDetails(selected);
    }
  }, [selected, setRecentlyUsedDetails]);

  const handleClick = function (event) {
    const text = event.currentTarget.textContent.trim();
    setSelected(text);
  };

  const isEnterAndPopupOpen = (e) => {
    e.stopPropagation();
    return (
      e.key === KeyCodes.ENTER &&
      (keysPressed.length > 0 || openSelectHistory) &&
      filteredItemList.length > 0
    );
  };

  const resetFilter = () => {
    setFilteredItemList([]);
    setKeysPressed([]);
  };

  const setDetailsAndKeys = (filteredDetails, allPressedKeys) => {
    setFilteredItemList(filteredDetails);
    setKeysPressed(allPressedKeys);
    setActive(0);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
    if (e.key === KeyCodes.ARROW_DOWN && active < filteredItemList.length - 1) {
      setActive(active + 1);
    } else if (e.key === KeyCodes.ARROW_UP && active > 0) {
      setActive(active - 1);
    } else if (isEnterAndPopupOpen(e)) {
      setSelected(filteredItemList[active]);
      document.activeElement.blur();
    } else if (e.key === KeyCodes.ESCAPE) {
      resetFilter();
    } else if (labelDetailsTier2) {
      const filteredDetails = labelDetailsTier2.filter((i) =>
        allDetails.includes(i)
      );
      setDetailsAndKeys(filteredDetails, [e.key]);
    } else {
      let allPressedKeys = [...keysPressed, e.key];
      let filteredDetails = filterDetails(allDetails, allPressedKeys);
      if (filteredDetails.length !== 0) {
        setDetailsAndKeys(filteredDetails, allPressedKeys);
      }
    }
  };

  const filterDetails = (details, keys) =>
    details.filter((val) => val.toLowerCase().includes(keys.join('')));

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const background = (index) => (index === active ? '#1e90ff' : '');
  const color = (index) => (index === active ? 'white' : 'black');

  return (
    filteredItemList &&
    (keysPressed.length > 0 || openSelectHistory) && (
      <div className={styles.openSelect} style={{ left: left, top: top }}>
        {filteredItemList.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.listItem}
              onClick={handleClick}
              onMouseEnter={() => setActive(index)}
              style={{ background: background(index), color: color(index) }}
            >
              {item}
            </div>
          );
        })}
      </div>
    )
  );
};

OpenSelect.propTypes = {
  /**
   * Left placement location
   */
  left: PropTypes.number,
  /**
   * Top placement location
   */
  top: PropTypes.number,
  /**
   * Function to set mark findings
   */
  setFindings: PropTypes.func,
  /**
   * Function to set mark details
   */
  setDetails: PropTypes.func,
  /**
   * boolean if right clicked
   */
  openSelectHistory: PropTypes.bool,
};

export default OpenSelect;
