export const generateMarkId = (markersData, prevMarks = []) => {
  return markersData.length === 0 && prevMarks.length === 0
    ? 1
    : getCurrentMaxId(markersData, prevMarks) + 1;
};

const getCurrentMaxId = (markersData, prevMarks) => {
  return Math.max(
    markersData.length
      ? Math.max(...markersData?.map((item) => item?.id || 0))
      : 0,
    prevMarks.length ? Math.max(...prevMarks?.map((item) => item?.id || 0)) : 0
  );
};

export const getCurrentImagePair = (prev, currentPhotoIndex) => {
  const { imagePairs, teeth } = prev;
  const refToCurrentPairOrTeeth = imagePairs || teeth;
  const arr = refToCurrentPairOrTeeth.filter(
    (pair) => pair.id === currentPhotoIndex
  );
  return arr[0] || null;
};
