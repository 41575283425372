import { getCurrentMarkers } from './imageLogic';

export const getCurrentShapes = (currentPair, type, shape) => {
  const currentMarkers = getCurrentMarkers(currentPair, type);
  return (
    currentMarkers &&
    currentMarkers.reduce(
      (result, item) =>
        getShapeObject(item, shape)
          ? [...result, getShapeObject(item, shape)]
          : result,
      []
    )
  );
};

export const getShapeObject = (item, shape = 'point') =>
  item.contouring.filter((cont) => cont.type === shape)[0]?.data;

export const getPositionObject = (item) =>
  getShapeObject(item, 'point').position;
