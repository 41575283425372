import React, { useState, useEffect, useCallback } from 'react';
import styles from './UsersConsole.module.css';
import { API_ENDPOINT } from '../../../config/awsLabelingConfig';
import { postData } from '../../LabelingToolPage/LabelingToolPage.logic';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { selectUsersList, setUsersList } from '../../../redux/tasks/tasksSlice';
import { useSelector, useDispatch } from 'react-redux';
import tasksListLogic from '../TasksList.logic';
import CreatableSelect from 'react-select/creatable';

const UsersConsole = ({ isEdit }) => {
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [name, setName] = useState('');
  const [group, setGroup] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const usersList = useSelector(selectUsersList);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      const initialUserList = await tasksListLogic.getListOfUsers();
      dispatch(setUsersList(initialUserList));

      const groups = [...new Set(initialUserList.map((item) => item?.Group))];
      const formattedList = groups.map((item) => ({
        value: item,
        label: item,
      }));

      setGroupList(formattedList);
    } catch (error) {
      dispatch(setUsersList([]));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const linkUrl =
    'https://wiki.aligntech.com/display/RDITGITERO/Labelers%3A+How+to+Add+a+Labeler+to+Tool';

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      NotificationManager.error(
        'Please enter a valid email address.',
        'Warning'
      );

      return;
    }
    if (usersList.map((item) => item.UserId).includes(email) && !isEdit) {
      NotificationManager.error('Email exist already.', 'Warning');

      return;
    }

    if (usersList.map((item) => item.Name).includes(name) && !isEdit) {
      NotificationManager.error('Name exist already.', 'Warning');

      return;
    }
    const newUser = {
      email,
      isActive,
      name: name,
      isEdit,
      isAdmin,
      group: group.label,
    };

    try {
      await postData(`${API_ENDPOINT}/addOrEditUser`, newUser);
      setEmail('');
      setIsActive(true);
      setName('');
      setIsAdmin(false);
      setGroup('');

      NotificationManager.success(
        isEdit ? 'User success edit' : 'User success add',
        'Success!'
      );
      fetchData();
    } catch (error) {
      console.error('Error adding user:', error);
      NotificationManager.error(
        'An error occurred while adding the user. Please try again later.',
        'Warning'
      );
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const selectEmail = (email) => {
    const user = usersList.find((user) => user.UserId === email);
    if (!user) return;
    setEmail(email);
    setIsActive(user.IsActive);
    setIsAdmin(user.IsAdmin);
    setName(user.Name);
    setGroup({ value: user.Group || '', label: user.Group || '' });
  };

  const handleGroupChange = (newValue) => {
    setGroup(newValue);
  };

  const handleGroupCreate = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setGroupList([...groupList, newOption]);
    setGroup(newOption);
  };
  return (
    <div className={styles.formContainer}>
      {!isEdit && (
        <a
          className={styles.linkButton}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          ?
        </a>
      )}
      <form onSubmit={handleSubmit}>
        <label>Email: </label>
        {!isEdit ? (
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.input}
          />
        ) : (
          <select
            className={styles.input}
            value={email}
            onChange={(e) => selectEmail(e.target.value)}
            required
          >
            <option value="">Select a user</option>
            {usersList
              .map((item) => item.UserId)
              .map((user) => (
                <option key={user} value={user}>
                  {user}
                </option>
              ))}
          </select>
        )}
        <br />
        <label>Display Name: </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className={styles.input}
        />
        <br />
        <div className={styles.groupSelect}>
          <label>Group: </label>
          <CreatableSelect
            isClearable
            options={groupList}
            value={group}
            onChange={handleGroupChange}
            onCreateOption={handleGroupCreate}
          />
        </div>
        {isEdit && (
          <>
            <label>Active: </label>
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <br />
            <label>Admin: </label>
            <input
              type="checkbox"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
            <br />
          </>
        )}
        <button type="submit">{isEdit ? 'Update User' : 'Add User'}</button>
      </form>
    </div>
  );
};
UsersConsole.propTypes = {
  isEdit: PropTypes.bool,
};

export default UsersConsole;
