import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentTaskSelector } from '../../../redux/taskState/taskDetailsSlice';
import { setOutput } from '../../../redux/taskStateImages/outputSlice';
import { setLowerState } from '../../../redux/taskStateImages/stateImagesLowerSlice';
import { setUpperState } from '../../../redux/taskStateImages/stateImagesUpperSlice';
import { setXRayState } from '../../../redux/taskStateImages/stateImagesXRaySlice';

import { Tasks } from '../../../shared-logic/enums';

const useHandleNewOutputPerType = () => {
  const dispatch = useDispatch();
  const currentTask = useSelector(currentTaskSelector);

  const handleNewOutputPerType = useCallback(
    (output) => {
      dispatch(setOutput(output));
      switch (currentTask) {
        case Tasks.LOWER:
          dispatch(setLowerState(output));
          break;
        case Tasks.UPPER:
          dispatch(setUpperState(output));
          break;
        case Tasks.XRAY:
          dispatch(setXRayState(output));
          break;
        default:
          return;
      }
    },
    [currentTask, dispatch]
  );

  return handleNewOutputPerType;
};

export default useHandleNewOutputPerType;
