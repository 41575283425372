import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import classNames from 'classnames';

import 'rc-slider/assets/index.css';
import styles from './ImageManipulationSlider.module.css';

const MIN = 100;
const MAX = 200;
const STEP = 0.1;
const Vertical_HANDLE_STYLE = {
  height: 22,
  width: 22,
  marginLeft: -9,
};
const HORIZONTAL_HANDLE_STYLE = {
  height: 22,
  width: 22,
  marginTop: -8,
};

const ImageManipulationSlider = ({
  min,
  max,
  value,
  defaultValue,
  onChange,
  icon,
  isVertical,
  disabled,
}) => {
  const handleStyle = isVertical
    ? Vertical_HANDLE_STYLE
    : HORIZONTAL_HANDLE_STYLE;
  const containerClassName = classNames(styles.container, {
    [styles.containerVertical]: isVertical,
    [styles.containerHorizontal]: !isVertical,
    [styles.disabledContainer]: disabled,
  });

  return (
    <div className={containerClassName}>
      <div className={isVertical ? styles.iconVertical : styles.iconHorizontal}>
        <img src={icon} alt="" width={20} height={20} />
      </div>
      <div
        className={
          isVertical
            ? styles.sliderContainerVertical
            : styles.sliderContainerHorizontal
        }
      >
        <Slider
          vertical={isVertical}
          min={min}
          max={max}
          step={STEP}
          value={value}
          handleStyle={handleStyle}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

ImageManipulationSlider.defaultProps = {
  min: MIN,
  max: MAX,
  defaultValue: MIN,
  isVertical: true,
};

ImageManipulationSlider.propTypes = {
  /**
   * Minimum slider value
   */
  min: PropTypes.number,
  /**
   * Maximum slider value
   */
  max: PropTypes.number,
  /**
   * Slider value
   */
  value: PropTypes.number.isRequired,
  /**
   * Callback, fired when value change
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Slider icon
   */
  icon: PropTypes.string,
  /**
   * Default slider value
   */
  defaultValue: PropTypes.number,
  /**
   * isVertical slider or horizontal
   */
  isVertical: PropTypes.bool,
  /**
   * When `true`, slider is disabled
   */
  disabled: PropTypes.bool,
};

export default ImageManipulationSlider;
