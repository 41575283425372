import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as HourglassSvg } from './hourglass.svg';
import styles from './HourglassProgress.module.css';

const HourglassProgress = (props) => {
  const { className: classNameProp, ...other } = props;
  const className = classNames(styles.spinning, classNameProp);

  return <HourglassSvg className={className} {...other} />;
};

HourglassProgress.propTypes = {
  /**
   *
   */
  className: PropTypes.string,
  /**
   * Height of hourglass image
   */
  height: PropTypes.string,
  /**
   * Width of hourglass image
   */
  width: PropTypes.string,
};

HourglassProgress.defaultProps = {
  height: '96',
  width: '66',
};

export default HourglassProgress;
