const KeyCodes = {
  S_KEY: 's',
  CTRL_KEY: 'Control',
  H_KEY: 'h',
  E_KEY: 'E',
  T_KEY: 't',
  F_KEY: 'f',
  ALT_KEY: 'Alt',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ARROW_LEFT: 'ArrowLeft',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

export default KeyCodes;
