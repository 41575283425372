import React, { useState } from 'react';
import styles from './BatchConsole.module.css';
import { API_ENDPOINT } from '../../../config/awsLabelingConfig';
import { postData } from '../../LabelingToolPage/LabelingToolPage.logic';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

const BatchConsole = ({ campaignList }) => {
  const [batchName, setBatchName] = useState('');
  const [isDataForAIDetections, setIsDataForAIDetections] = useState(false);
  const [isDataForGroundTruthLabels, setIsDataForGroundTruthLabels] =
    useState(false);
  const [connectToCampaign, setConnectToCampaign] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newBatch = {
      batchName,
      connectToCampaign: connectToCampaign || 'none',
      isDataForAIDetections,
      isDataForGroundTruthLabels,
    };

    try {
      await postData(`${API_ENDPOINT}/addBatch`, newBatch);
      setBatchName('');
      setIsDataForAIDetections(false);
      setIsDataForGroundTruthLabels(false);
      setConnectToCampaign('');
      NotificationManager.success('Batch success add', 'Success!');
    } catch (error) {
      console.error('Error adding batch:', error);
      NotificationManager.error(
        'An error occurred while adding the batch. Please try again later.',
        'Warning'
      );
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit}>
        <label>BatchName: </label>

        <input
          type="batchName"
          value={batchName}
          onChange={(e) => setBatchName(e.target.value)}
          required
        />
        <br />
        <label>Contains data for AI detections: </label>
        <input
          type="checkbox"
          checked={isDataForAIDetections}
          onChange={(e) => setIsDataForAIDetections(e.target.checked)}
        />
        <br />

        <label>Contains data for Ground truth labels: </label>
        <input
          type="checkbox"
          checked={isDataForGroundTruthLabels}
          onChange={(e) => setIsDataForGroundTruthLabels(e.target.checked)}
        />
        <br />

        <label>Connect To Campaign: </label>
        <select
          className={styles.select}
          value={connectToCampaign}
          onChange={(e) => setConnectToCampaign(e.target.value)}
        >
          <option value="">Select a campaign...</option>
          {campaignList.map((campaign) => (
            <option key={campaign.id} value={campaign.id}>
              {campaign.id}
            </option>
          ))}
        </select>
        <br />

        <button type="submit"> Add Batch</button>
      </form>
    </div>
  );
};
BatchConsole.propTypes = {
  campaignList: PropTypes.object,
};

export default BatchConsole;
