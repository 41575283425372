import { createSlice } from '@reduxjs/toolkit';

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    variant: null,
    labeler: null,
    role: null,
    adminRole: null,
    config: null,
    deletedTasks: [],
    potentiallyDeletedTasks: [],
    minNumberOfLabelers: 0,
    variantGuidance: null,
    userSystem: null,
    favoriteVariant: null,
    usersList: [],
  },
  reducers: {
    setVariant: (state, action) => {
      state.variant = action.payload;
    },
    setFavoriteVariant: (state, action) => {
      state.favoriteVariant = action.payload;
    },
    setLabeler: (state, action) => {
      state.labeler = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setAdminRole: (state, action) => {
      state.adminRole = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setVariantGuidance: (state, action) => {
      state.variantGuidance = action.payload;
    },
    setUserSystem: (state, action) => {
      state.userSystem = action.payload;
    },
    setDeletedTasks: (state, action) => {
      state.deletedTasks = action.payload;
    },
    setPotentiallyDeletedTasks: (state, action) => {
      state.potentiallyDeletedTasks = action.payload;
    },
    setMinNumberOfLabelers: (state, action) => {
      state.minNumberOfLabelers = action.payload;
    },
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
  },
});

export const {
  setVariant,
  setFavoriteVariant,
  setLabeler,
  setRole,
  setAdminRole,
  setConfig,
  setVariantGuidance,
  setUserSystem,
  setDeletedTasks,
  setPotentiallyDeletedTasks,
  setMinNumberOfLabelers,
  setUsersList,
} = tasksSlice.actions;

export const selectVariant = (state) => state.tasks.variant;
export const selectFavoriteVariant = (state) => state.tasks.favoriteVariant;

export const selectLabeler = (state) => state.tasks.labeler;

export const selectRole = (state) => state.tasks.role;
export const selectAdminRole = (state) => state.tasks.adminRole;

export const selectConfig = (state) => state.tasks.config;

export const selectVariantGuidance = (state) => state.tasks.variantGuidance;

export const selectUserSystem = (state) => state.tasks.userSystem;

export const selectUsersList = (state) => state.tasks.usersList;

export const selectDeletedTasks = (state) => state.tasks.deletedTasks;

export const selectPotentiallyDeletedTasks = (state) =>
  state.tasks.potentiallyDeletedTasks;

export const selectMinNumberOfLabelers = (state) =>
  state.tasks.minNumberOfLabelers;

export default tasksSlice.reducer;
