export const LOWER_IMAGE_RESPONSE = 'labeling.toolData.lowerImagesResponse';
export const LOWER_STATE_RESPONSE = 'labeling.toolData.lowerStateResponse';
export const UPPER_IMAGE_RESPONSE = 'labeling.toolData.upperImagesResponse';
export const UPPER_STATE_RESPONSE = 'labeling.toolData.upperStateResponse';
export const XRAY_IMAGE_RESPONSE = 'labeling.toolData.xrayImagesResponse';
export const XRAY_STATE_RESPONSE = 'labeling.toolData.xrayStateResponse';

export const LOWER_SUPERVISOR_NOTES = 'labeling.toolData.lowerSupervisorNotes';
export const UPPER_SUPERVISOR_NOTES = 'labeling.toolData.upperSupervisorNotes';
export const XRAY_SUPERVISOR_NOTES = 'labeling.toolData.xraySupervisorNotes';

export const LOWER_PREVIOUS_AGGREGATION =
  'labeling.toolData.lowerPreviousAggregation';
export const UPPER_PREVIOUS_AGGREGATION =
  'labeling.toolData.upperPreviousAggregation';
export const XRAY_PREVIOUS_AGGREGATION =
  'labeling.toolData.xrayPreviousAggregation';

export const DETECTION_POINTS = 'labeling.toolData.DetectionPoints';
