import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Mark.module.css';
import { setX, setY } from '../../../../shared-logic';
import { useState } from 'react';
import { WHITE, TOMATO } from '../../Shapes/shared-logic';
const Mark = (props) => {
  const {
    size,
    color,
    tier,
    zIndex,
    item,
    onMarkClick,
    blink,
    index,
    imageScale,
    imageSize,
    opacity,
    touchEvent,
    missPoint,
  } = props;

  const HALF_SIZE = size / 2;

  const className = classNames([
    missPoint && !blink ? styles.markerMissPoint : styles.marker,
    blink ? styles.blink : '',
  ]);
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const backgroundColor = color ? color : WHITE;

  const { width, naturalWidth, height, naturalHeight } = imageSize;

  const x = setX(item.x, width, naturalWidth);
  const y = setY(item.y, height, naturalHeight);

  const handleMarkClick = () => {
    !blink &&
      onMarkClick &&
      onMarkClick({
        x: item.x,
        y: item.y,
        tier: item.tier,
        type: item.type,
      });
  };
  if (x && y && width && height)
    return (
      <div
        style={{
          position: 'relative',
          left: x - HALF_SIZE,
          top: missPoint && !blink ? y - size * 2 : y - HALF_SIZE,
        }}
        data-id={`mark${index}-tier${tier === 1 ? 1 : tier === 2 ? 2 : 3}`}
      >
        <div
          className={className}
          style={
            missPoint && !blink
              ? {}
              : {
                  width: size,
                  height: size,
                  zIndex,
                  transform: `scale(${1 / imageScale})`,
                  opacity,
                  backgroundColor:
                    hover && onMarkClick ? TOMATO : backgroundColor,
                }
          }
          onClick={handleMarkClick}
          onTouchStart={() => touchEvent && handleMarkClick()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {missPoint && !blink ? 'x' : null}
        </div>
      </div>
    );
  else return null;
};

Mark.propTypes = {
  /**
   * mark item
   */
  item: PropTypes.object,
  /**
   * size of the marker
   */
  size: PropTypes.number,
  /**
   * Z index
   */
  zIndex: PropTypes.number,
  /**
   * color
   */
  color: PropTypes.string,
  /**
   * whether the mark is a blinking one
   */
  blink: PropTypes.bool,
  /**
   * on mark click
   */
  onMarkClick: PropTypes.func,
  /**
   * mark index in mark array
   */
  index: PropTypes.number,
  /**
   * labeling image scale value used to reduce marks size which caused by zooming
   */
  imageScale: PropTypes.number,
  /**
   * rendered and natural image sizes
   */
  imageSize: PropTypes.object,
  /**
   * tier level of mark
   */
  tier: PropTypes.number,
  /**
   * opacity level for mark
   */
  opacity: PropTypes.number,
  /**
   * should a touch event be attached
   */
  touchEvent: PropTypes.bool,
};

Mark.defaultProps = {
  opacity: 1,
};

export default Mark;
