import React from 'react';
import Button from '../../../components/Button';
import styles from './Done.module.css';
import PropTypes from 'prop-types';

const Done = ({ handleDoneClick }) => {
  const DONE = 'Editing Done';
  return (
    <Button className={styles.button} onClick={handleDoneClick}>
      {DONE}
    </Button>
  );
};

export default Done;

Done.propTypes = {
  /**
   * function to handle click
   */
  handleDoneClick: PropTypes.func,
};
