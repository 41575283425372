import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from '../LabelingToolPage.module.css';
import {
  currentMarkerSelector,
  currentContouringSelector,
  currentTypeSelector,
  typeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  currentTaskSelector,
  taskLevelSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { rowSelectedSelector } from '../../../redux/labelingTool/labelingToolSlice';
import {
  isTooth,
  isXRay,
  isToothLevelWithoutXRay,
} from '../../../shared-logic/taskLevelsTypesHelper';
import { NOT_SELECTED } from '../../../shared-logic/params';
import MarkLabeling from '../MarkLabeling';
import Delete from '../Delete';
import ToothComments from '../ToothComments';
import { useShouldDisableMarking } from '../customHooks';

const MarksDetailsSelection = () => {
  const currentContouring = useSelector(currentContouringSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentTask = useSelector(currentTaskSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const type = useSelector(typeSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const currentMarker = useSelector(currentMarkerSelector);

  const isToothLevel = isTooth(taskLevel);
  const disableMarking = useShouldDisableMarking();

  const isToothWithoutXRay = isToothLevelWithoutXRay(taskLevel, currentTask);

  const labelingWrapperClass = classNames([
    styles.container,
    {
      [styles.labelingWrapper]:
        (currentContouring &&
          !currentMarker?.missPoint &&
          (currentType || isXRay(currentTask))) ||
        (isToothLevel && type.toLowerCase() !== NOT_SELECTED.toLowerCase()),
    },
  ]);
  return (
    <div className={labelingWrapperClass}>
      {currentType && currentContouring && !rowSelected && (
        <div className={styles.statusContainer}>
          Please classify the newly selected mark
        </div>
      )}

      <div className={styles.flexRow}>
        {!(isToothLevel && isXRay(currentTask)) && <MarkLabeling />}
        {(isToothLevel || (currentMarker && !currentMarker?.missPoint)) &&
          !(isToothLevel && isXRay(currentTask)) && (
            <Delete disableMarking={disableMarking} />
          )}
      </div>
      {isToothWithoutXRay && <ToothComments />}
    </div>
  );
};

export default MarksDetailsSelection;
