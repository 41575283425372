import { createSlice } from '@reduxjs/toolkit';

export const contrastBrightnessSlice = createSlice({
  name: 'contrastBrightness',
  initialState: {
    colorBrightness: 100,
    colorContrast: 100,
    niriBrightness: 100,
    niriContrast: 100,
    contrast: 100,
    brightness: 100,
    autoCorrection: false,
  },
  reducers: {
    setColorBrightness: (state, action) => {
      state.colorBrightness = action.payload;
    },
    setColorContrast: (state, action) => {
      state.colorContrast = action.payload;
    },
    setNiriBrightness: (state, action) => {
      state.niriBrightness = action.payload;
    },
    setNiriContrast: (state, action) => {
      state.niriContrast = action.payload;
    },
    setContrast: (state, action) => {
      state.contrast = action.payload;
    },
    setBrightness: (state, action) => {
      state.brightness = action.payload;
    },
    setAutoCorrection: (state, action) => {
      state.autoCorrection = action.payload;
    },
  },
});

export const {
  setColorBrightness,
  setColorContrast,
  setNiriBrightness,
  setNiriContrast,
  setContrast,
  setBrightness,
  setAutoCorrection,
} = contrastBrightnessSlice.actions;

export const colorBrightnessSelector = (state) =>
  state.contrastBrightness.colorBrightness;
export const colorContrastSelector = (state) =>
  state.contrastBrightness.colorContrast;
export const niriBrightnessSelector = (state) =>
  state.contrastBrightness.niriBrightness;
export const niriContrastSelector = (state) =>
  state.contrastBrightness.niriContrast;
export const contrastSelector = (state) => state.contrastBrightness.contrast;
export const brightnessSelector = (state) =>
  state.contrastBrightness.brightness;
export const autoCorrectionSelector = (state) =>
  state.contrastBrightness.autoCorrection;

export default contrastBrightnessSlice.reducer;
