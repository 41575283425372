import {
  fdiTeethLower,
  fdiTeethUpper,
  universalTeethLower,
  universalTeethUpper,
} from './teeth';

const fdiTeeth = [...fdiTeethLower, ...fdiTeethUpper];
const universalTeeth = [...universalTeethLower, ...universalTeethUpper];

export const fromUniversalToFDI = (number) => {
  const index = universalTeeth.findIndex((x) => x === number);
  if (index === -1) return null;
  return fdiTeeth[index];
};

export const fromFDIToUniversal = (number) => {
  const index = fdiTeeth.findIndex((x) => x === number);
  if (index === -1) return null;
  return universalTeeth[index];
};
