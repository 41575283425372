import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-notifications/lib/notifications.css';
import styles from './LabelingToolPage.module.css';
import KeyboardShortcutsContainer from './KeyboardShortcuts';
import HotKeysShortcuts from './HotKeysShortcuts/HotKeysShortcuts';
import LabelingHeader from './LabelingHeader/LabelingHeader';
import NavigationArrowsContainer from './NavigationArrows/NavigationArrowsContainer';
import TaskButtonsContainer from '../LabelingToolPage/TaskButtons/TaskButtonsContainer';
import {
  useCommitOrApprovalChange,
  useCopyToothIndication,
  useContrastBrightnessChange,
  useActivityMonitor,
  useOnMarkDetailsChange,
  useOnMarkerSelected,
  useOnToothNavigation,
  useSelectMarkingHook,
  useSetCommitToValue,
  useOnImagePairChange,
} from './customHooks';
import MarksDetailsTable from './MarksDetailsTable/MarksDetailsTable';
import MarkDetailsSelection from './MarkDetailsSelection';
import JawModel from './JawModel';
import ImagesAndImageFunctionality from './ImagesAndImageFunctionality';

function LabelingToolPage() {
  useActivityMonitor();
  useOnImagePairChange();
  useOnToothNavigation();
  useSetCommitToValue();
  useSelectMarkingHook();
  useCommitOrApprovalChange();
  useOnMarkDetailsChange();
  useContrastBrightnessChange();
  useCopyToothIndication();
  const onMarkerSelected = useOnMarkerSelected();

  return (
    <div className={styles.pluginWrapper}>
      <HotKeysShortcuts />
      <div className={styles.flexRow}>
        <ImagesAndImageFunctionality />
        <div className={styles.rightControls}>
          <NavigationArrowsContainer />

          <div className={styles.flexRow}>
            <div className={styles.imageFrame}>
              <JawModel />
              <TaskButtonsContainer />
            </div>
            <KeyboardShortcutsContainer />
          </div>
          <LabelingHeader />
          <MarkDetailsSelection />
          <MarksDetailsTable onMarkerSelected={onMarkerSelected} />
        </div>
      </div>
    </div>
  );
}

export default LabelingToolPage;
