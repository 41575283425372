import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentContouringSelector,
  currentShapeDataSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
  setCurrentShapeData,
  setShapeInProgress,
  currentTypeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import { BOUNDING_BOX, TYPE_NIRI } from '../../../shared-logic/enums';
import { getRectDimensions } from '../ImageFrameManipulation/ImageMarking/Shapes/Rectangles/Rectangle/Rectangle.logic';
import { setSavedX, setSavedY } from '../shared-logic';
import useActionsCurrentShapeData from './useActionsCurrentShapeData';

const useSaveShapeData = () => {
  const dispatch = useDispatch();
  const currentType = useSelector(currentTypeSelector);

  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);
  const imageSize = currentType === TYPE_NIRI ? imageSizeNiri : imageSizeColor;
  const currentContouring = useSelector(currentContouringSelector);
  const currentShapeData = useSelector(currentShapeDataSelector);

  const { addCurrentShapeData } = useActionsCurrentShapeData();

  const saveShapeData = useCallback(
    (shapeName, shapeDimensions) => {
      dispatch(setShapeInProgress(null));
      if (!currentShapeData || isEmpty(currentShapeData)) {
        return;
      }
      const dataToSave =
        shapeName === BOUNDING_BOX
          ? getRectDimensions(currentShapeData, imageSize, shapeDimensions)
          : currentShapeData.map((point) => [
              setSavedX(point[0], imageSize.width, imageSize.naturalWidth),
              setSavedY(point[1], imageSize.height, imageSize.naturalHeight),
            ]);

      if (currentContouring) {
        addCurrentShapeData(dataToSave, shapeName);
      }
      dispatch(setCurrentShapeData(undefined));
    },
    [
      addCurrentShapeData,
      currentContouring,
      currentShapeData,
      dispatch,
      imageSize,
    ]
  );

  return { saveShapeData };
};

export default useSaveShapeData;
