import React from 'react';
import { useDispatch } from 'react-redux';
import { setAutoCorrection } from '../../../redux/taskState/contrastBrightnessSlice';
import { Button } from '../../../components';
import styles from '../LabelingToolPage.module.css';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { useHistory } from 'react-router-dom';
import { resetImagesACsrc } from '../shared-logic';

const ReturnToTasksButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const returnToTasks = () => {
    dispatch(setAutoCorrection(false));
    resetImagesACsrc(dispatch);
    userActionLogs.addActionLog(`returning to tasks`);
    history.push('/');
  };

  return (
    <Button className={styles.gotobtn} onClick={returnToTasks}>
      Return To Tasks
    </Button>
  );
};

export default ReturnToTasksButton;
