import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { disableShortcutsSelector } from '../../../../redux/labelingTool/labelingToolSlice';
import { commitSelector } from '../../../../redux/taskStateImages/outputSlice';
import {
  useEnterCommitAction,
  useShouldDisableMarking,
} from '../../customHooks';

const CaseLevelShortcuts = () => {
  const commit = useSelector(commitSelector);
  const disableShortcuts = useSelector(disableShortcutsSelector);
  const enterCommitAction = useEnterCommitAction();
  const disableMarking = useShouldDisableMarking();

  useHotkeys(
    'enter',
    () => {
      enterCommitAction();
    },
    [commit, disableShortcuts, disableMarking]
  );

  return null;
};

export default CaseLevelShortcuts;
