import { useRef } from 'react';
import cancelTaskDialog from '../Dialogs/cancelTaskDialog';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  batchSelector,
  imagesTypeSelector,
  pathSelector,
  taskLevelSelector,
  taskShortNickNameSelector,
  tierSelector,
  setIsDeletedTask,
  assigneeSelector,
  completedSelector,
  inTier1Selector,
  potentiallyDiscardedSelector,
  reasonSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import labelingToolLogic from '../../LabelingToolPage/LabelingToolPage.logic';
import { useCreateCaseIndex } from '../customHooks';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { selectRole } from '../../../redux/tasks/tasksSlice';
import { isSupervisor } from '../../TasksList/rolesUtil';
import { setAutoCorrection } from '../../../redux/taskState/contrastBrightnessSlice';
import { resetImagesACsrc } from '../shared-logic';
import { useHistory } from 'react-router-dom';

const SET = 'SET';

export const useCancelTask = () => {
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const batch = useSelector(batchSelector);
  const path = useSelector(pathSelector);
  const tier = useSelector(tierSelector);
  const imagesType = useSelector(imagesTypeSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const role = useSelector(selectRole);
  const assignee = useSelector(assigneeSelector);
  const completed = useSelector(completedSelector);
  const inTier1 = useSelector(inTier1Selector);
  const reason = useSelector(reasonSelector);
  const dispatch = useDispatch();
  const isSupervisorRole = isSupervisor(role);
  const potentiallyDiscarded = useSelector(potentiallyDiscardedSelector);
  const history = useHistory();

  const reasonRef = useRef('');
  const validationRef = useRef(null);

  const caseSummaryHook = useCreateCaseIndex();

  const isReasonTextEmpty = () => reasonRef.current.value.trim().length === 0;

  const onReasonInput = (event) => {
    if (event.target.value.trim().length > 0) {
      validationRef.current.innerText = '';
    }
  };

  const removeTask = async () => {
    userActionLogs.addActionLog('task inapplicable called');
    const cancelIndex = buildCancelIndex();

    try {
      if (isSupervisorRole) {
        await labelingToolLogic.cancelTask(path, cancelIndex);
      } else {
        const data = {
          action: SET,
          taskShortNickName,
          level: taskLevel,
          assignee,
          reason: reasonRef.current.value,
        };
        await labelingToolLogic.potentiallyDeleteRevert(data);
      }
      dispatch(setIsDeletedTask(true));
      NotificationManager.success('Task deleted', 'Success!');
    } catch (err) {
      NotificationManager.error(
        'an Error Occured when deleting task',
        'Warning'
      );
    }
  };

  const deleteTask = async () => {
    if (isReasonTextEmpty()) {
      validationRef.current.innerText = 'You must provide a reason to continue';
      return false;
    }
    await removeTask();
    await returnToTaskList();
    return true;
  };

  const returnToTaskList = () => {
    dispatch(setAutoCorrection(false));
    resetImagesACsrc(dispatch);
    userActionLogs.addActionLog(`returning to tasks`);
    history.push('/');
  };

  const cancelTask = async () => {
    if (isSupervisorRole && potentiallyDiscarded) {
      reasonRef.current = { value: reason };
      deleteTask();
    } else {
      cancelTaskDialog(deleteTask, reasonRef, onReasonInput, validationRef);
    }
  };

  const buildCancelIndex = () => {
    const caseSummary = caseSummaryHook();
    return {
      DataMarkingTask: {
        Reason: reasonRef.current.value,
        TaskShortNickName: taskShortNickName,
        Batch: batch,
        Tier: parseInt(tier),
        Type: imagesType,
        Summary: caseSummary.DataMarkingTask.Summary,
        Level: taskLevel,
        Assignee: assignee,
        Completed: isSupervisorRole ? undefined : completed,
        InTier1: isSupervisorRole ? undefined : inTier1,
      },
    };
  };

  return cancelTask;
};

export default useCancelTask;
