import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { selectConfig } from '../../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  getAllVariantListActive,
  getAllVariantListHidden,
  getVariantList,
} from '../../../../config/configUtil';
import 'react-dropdown-tree-select/dist/styles.css';
import styles from '../ExportType.module.css';
import { LabelsAndVariants } from './LabelsAndVariants';
import tasksListLogic from '../../TasksList.logic';

const Active = 'Active';
const Hidden = 'Hidden';
const params = 'params';
const batch = 'batches';

export const ExportLabelsforAI = () => {
  const config = useSelector(selectConfig);
  const userName = tasksListLogic.getUserName();

  const variantListActive = getAllVariantListActive(config, userName);
  const variantListHidden = getAllVariantListHidden(config, userName);

  const [optionsForExport, setOptionsForExport] = useState('');
  const [showVariants, setShowVariants] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState(false);
  const convertStringToObject = (stringValue) => ({
    label: stringValue,
    value: stringValue.replace(/\s+/g, '-').toLowerCase(),
  });

  const updateDataWithChildren = (nodeValue, childrenList, data) => {
    return data.map((node) =>
      node.value === nodeValue ? { ...node, children: childrenList } : node
    );
  };

  const fillData = () => {
    const dataOriginal = [
      {
        label: Active,
        value: Active,
        children: [],
      },
      {
        label: Hidden,
        value: Hidden,
        children: [],
      },
    ];
    const dataWithActive = updateDataWithChildren(
      Active,
      variantListActive.map(convertStringToObject),
      dataOriginal
    );
    return updateDataWithChildren(
      Hidden,
      variantListHidden.map(convertStringToObject),
      dataWithActive
    );
  };
  const data = fillData();

  const onDataChange = (value) => {
    setShowVariants(true);
    setOptionsForExport(value);
    setShowExport(false);
  };

  const exportLabelsForAi = async (variantList, optionsForExport) => {
    NotificationManager.success(
      'Request received. Data will be downloaded soon',
      'Success!'
    );
    for (const variant of variantList) {
      try {
        const variants = getVariantList(config, variant);
        if (!variants || !variants.hasOwnProperty(params)) continue;

        const batches = variants[params][batch];

        tasksListLogic
          .exportLabelsForAi(optionsForExport, batches)
          .then((res) => {
            if (!res.executionArn) {
              throw new Error('Failed to retrieve execution ARN.');
            }
            return tasksListLogic.checkExecutionAndRetrieveOutput(
              res.executionArn
            );
          })
          .then((output) => {
            if (!output.url) {
              throw new Error('Failed to retrieve download URL.');
            }
            return fetch(output.url);
          })
          .then((data) => data.blob())
          .then((blob) => {
            const link = document.createElement('a');
            link.download = `${variant}-${optionsForExport}.zip`;
            link.href = URL.createObjectURL(blob);
            link.click();
          })
          .catch((err) => {
            console.error('Error:', err);
            NotificationManager.error(
              err.message || 'An error occurred.',
              'Download Error'
            );
          });
      } catch (err) {
        NotificationManager.error(err, 'Warning');
      }
    }
  };

  const onExportClick = () => {
    const valuesToExport = selectedNodes
      .map((node) =>
        node._parent
          ? node.label
          : node._children
          ? data
              .find((d) => d.value === node.value)
              ?.children.map((x) => x.label)
          : node.label
      )
      .flat();

    exportLabelsForAi(valuesToExport, optionsForExport);
  };

  const onTreeChange = (currentNode, selectedNodes) => {
    setShowExport(true);
    setSelectedNodes(selectedNodes);
  };

  return (
    <div className={styles.formcontainer}>
      <LabelsAndVariants
        optionsForExport={optionsForExport}
        onDataChange={onDataChange}
        showVariants={showVariants}
        data={data}
        onTreeChange={onTreeChange}
      />
      <button
        // className={styles.button}
        onClick={onExportClick}
        disabled={!showExport}
      >
        Export
      </button>
    </div>
  );
};

ExportLabelsforAI.propTypes = {
  exportLabelsForAi: PropTypes.func.isRequired,
};

export default ExportLabelsforAI;
