import React from 'react';

import styles from './ToothComments.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  commentsSelector,
  setComments,
} from '../../../redux/marks/currentMarkingSlice';
import {
  rowSelectedSelector,
  setMarkEdited,
} from '../../../redux/labelingTool/labelingToolSlice';
import TextArea from '../../../components/TextArea';

const ToothComments = () => {
  const dispatch = useDispatch();
  const comments = useSelector(commentsSelector);
  const rowSelected = useSelector(rowSelectedSelector);

  const header = 'Comments';

  const updateComments = (e) => {
    dispatch(setComments(e.target.value));
    if (rowSelected) {
      dispatch(setMarkEdited(true));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      dispatch(setComments(comments + '\n'));
    }
  };

  return (
    <TextArea
      content={comments}
      header={header}
      onChange={updateComments}
      onKeyDown={handleKeyDown}
      textClassNameProp={styles.commentsTextArea}
      rows="3"
    />
  );
};

export default ToothComments;
