import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isTooth, isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import {
  outputSelector,
  setApprovedPrevTier,
  setCommit,
} from '../../../redux/taskStateImages/outputSlice';
import {
  currentTaskSelector,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  setPrevPhotoIndex,
  currentPhotoIndexSelector,
  prevPhotoIndexSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setBrightness,
  setColorBrightness,
  setColorContrast,
  setContrast,
  setNiriBrightness,
  setNiriContrast,
} from '../../../redux/taskState/contrastBrightnessSlice';
import { setRotationFull } from '../../../redux/taskState/rotationSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { isTierWithPrevMarks } from '../../../shared-logic/tiersHelpers';
import { isEqual } from 'lodash';

const initialRotationState = {
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
};

const useOnImagePairChange = () => {
  const dispatch = useDispatch();
  const output = useSelector(outputSelector, isEqual);
  const taskLevel = useSelector(taskLevelSelector);
  const prevPhotoIndex = useSelector(prevPhotoIndexSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentTask = useSelector(currentTaskSelector);
  const tier = useSelector(tierSelector);
  const isToothLevel = isTooth(taskLevel);

  const currentImagePair =
    (!isToothLevel &&
      output.imagePairs.filter((pair) => pair.id === currentPhotoIndex)) ||
    [];
  const currentPair = currentImagePair ? currentImagePair[0] : null;

  useEffect(() => {
    const rotationObjectRef =
      currentPair &&
      currentPair.rotation &&
      Object.keys(currentPair.rotation).length > 0
        ? currentPair.rotation
        : initialRotationState;
    if (prevPhotoIndex === currentPhotoIndex) {
      return;
    }

    if (currentPair) {
      dispatch(setPrevPhotoIndex(currentPhotoIndex));
      if (isXRay(currentTask)) {
        dispatch(setBrightness(currentPair.brightness));
        dispatch(setContrast(currentPair.contrast));
        dispatch(setRotationFull(rotationObjectRef));
      } else {
        dispatch(setColorBrightness(currentPair.color.brightness));
        dispatch(setColorContrast(currentPair.color.contrast));
        dispatch(setNiriBrightness(currentPair.niri.brightness));
        dispatch(setNiriContrast(currentPair.niri.contrast));
      }
      isTierWithPrevMarks(tier) &&
        userActionLogs.addActionLog(
          `commit set to ${currentPair.commited} via useEffect`
        );
      isTierWithPrevMarks(tier) && dispatch(setCommit(currentPair.commited));
      isTierWithPrevMarks(tier) &&
        dispatch(setApprovedPrevTier(currentPair.approvedPrevTier));
    }
  }, [
    currentPair,
    currentPhotoIndex,
    currentTask,
    dispatch,
    prevPhotoIndex,
    tier,
  ]);
};

export default useOnImagePairChange;
