import { confirmAlert } from 'react-confirm-alert';

const loginDialog = (loginAction) => {
  return confirmAlert({
    title: 'Session Timeout',
    message: 'Your session has timed out.\n Please login again.',
    buttons: [
      {
        label: 'Ok',
        onClick: async () => {
          await loginAction();
        },
      },
    ],
  });
};

export default loginDialog;
