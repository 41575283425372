import { confirmAlert } from 'react-confirm-alert';
import {
  ADDITION_IN_PROGRESS_MESSAGE,
  MODIFICATION_IN_PROGRESS_MESSAGE,
} from './markInProgressMessages';

const inProgressDialog = (rowSelected, navigateAway, setSelectedTableRowId) => {
  confirmAlert({
    title: 'Confirm to submit',
    message: rowSelected
      ? MODIFICATION_IN_PROGRESS_MESSAGE
      : ADDITION_IN_PROGRESS_MESSAGE,
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          navigateAway();
        },
      },
      {
        label: 'No',
        onClick: () => {
          setSelectedTableRowId(-1);
        },
      },
    ],
    closeOnClickOutside: false,
  });
};

export default inProgressDialog;
