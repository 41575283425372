import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './NiriColor.module.css';
import { rowSelectedSelector } from '../../../redux/labelingTool/labelingToolSlice';
import {
  currentContouringSelector,
  currentMarkerSelector,
  currentPhotoIndexSelector,
  currentTypeSelector,
  detailsSelector,
  findingsSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setColorBrightness,
  setColorContrast,
  setNiriBrightness,
  setNiriContrast,
} from '../../../redux/taskState/contrastBrightnessSlice';
import { outputSelector } from '../../../redux/taskStateImages/outputSlice';
import ImageFrameManipulation from '../ImageFrameManipulation';
import { TYPE_COLOR, TYPE_NIRI } from '../../../shared-logic/enums';
import { isNG, isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import {
  imagesTypeSelector,
  recentIndicationSelector,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  colorBrightnessSelector,
  colorContrastSelector,
  niriBrightnessSelector,
  niriContrastSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';
import {
  getCurrentImageOrPair,
  getMarkers,
  getPrevMarkers,
  onMarkerCreated,
} from '../shared-logic';
import { isEqual } from 'lodash';
import { useMissPoint } from '../customHooks';

const NiriColor = ({
  disableMarking,
  pictures,
  prevTier,
  onMarkerSelected,
}) => {
  const dispatch = useDispatch();
  const currentMarker = useSelector(currentMarkerSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const details = useSelector(detailsSelector);
  const findings = useSelector(findingsSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const output = useSelector(outputSelector, isEqual);
  const niriContrast = useSelector(niriContrastSelector);
  const colorContrast = useSelector(colorContrastSelector);
  const niriBrightness = useSelector(niriBrightnessSelector);
  const colorBrightness = useSelector(colorBrightnessSelector);
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);

  const tier = useSelector(tierSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const imagesType = useSelector(imagesTypeSelector);
  const { naturalWidth, naturalHeight } =
    currentType === TYPE_NIRI ? imageSizeNiri : imageSizeColor;
  const recentIndication = useSelector(recentIndicationSelector);

  const isToothLevel = isTooth(taskLevel);
  const niriMarkings = [currentMarker].filter(({ type }) => type === TYPE_NIRI);
  const colorMarkings = [currentMarker].filter(
    ({ type }) => type === TYPE_COLOR
  );

  const currentPair = !isToothLevel
    ? getCurrentImageOrPair(output, currentPhotoIndex)
    : null;

  const niriMarkers = getMarkers(TYPE_NIRI, currentPair);
  const colorMarkers = getMarkers(TYPE_COLOR, currentPair);

  const niriPrevMarkings =
    tier > 1 ? getPrevMarkers(TYPE_NIRI, currentPair, prevTier) : [];
  const colorPrevMarkings =
    tier > 1 ? getPrevMarkers(TYPE_COLOR, currentPair, prevTier) : [];

  const containerClass = classNames(styles.container, {
    [styles.ngContainer]: isNG(imagesType),
  });

  const missPointsRes = useMissPoint();
  const missPoints = missPointsRes?.missPoints;
  const markersWrongUser = missPointsRes?.markersWrongUser;
  const missPointsNiri =
    missPoints && missPoints?.filter((point) => point.type === 'niri');
  const missPointsColor =
    missPoints && missPoints?.filter((point) => point.type === 'color');

  const markersWrongUserNiri =
    markersWrongUser &&
    markersWrongUser?.filter((point) => point.type === 'niri');
  const markersWrongUserColor =
    markersWrongUser &&
    markersWrongUser?.filter((point) => point.type === 'color');

  return [TYPE_NIRI, TYPE_COLOR].map((type, index) => {
    const isNiri = type === TYPE_NIRI;
    return (
      <div key={index} className={containerClass}>
        <ImageFrameManipulation
          isNiri={isNiri}
          markings={isNiri ? niriMarkings : colorMarkings}
          isSelected={rowSelected}
          markers={isNiri ? niriMarkers : colorMarkers}
          contrast={isNiri ? niriContrast : colorContrast}
          brightness={isNiri ? niriBrightness : colorBrightness}
          onChangedContrast={(val) =>
            isNiri
              ? dispatch(setNiriContrast(val))
              : dispatch(setColorContrast(val))
          }
          onChangedBrightness={(val) =>
            isNiri
              ? dispatch(setNiriBrightness(val))
              : dispatch(setColorBrightness(val))
          }
          markingMode={!isToothLevel}
          disableMarking={disableMarking}
          prevMarkings={isNiri ? niriPrevMarkings : colorPrevMarkings}
          src={`data:image/png;base64,  ${
            isNiri
              ? pictures[currentPhotoIndex].niri
              : pictures[currentPhotoIndex].color
          }`}
          onMarkerCreated={(item, filteredDetails) =>
            onMarkerCreated(
              item,
              filteredDetails,
              type,
              findings,
              details,
              currentType,
              currentContouring,
              rowSelected,
              recentIndication,
              isNiri ? imageSizeNiri : imageSizeColor,
              dispatch
            )
          }
          onMarkClick={(item) => {
            onMarkerSelected(item);
          }}
          naturalHeight={naturalHeight}
          naturalWidth={naturalWidth}
          currentPair={currentPair}
          missPoints={isNiri ? missPointsNiri : missPointsColor}
          markersWrongUser={
            isNiri ? markersWrongUserNiri : markersWrongUserColor
          }
        />
      </div>
    );
  });
};

NiriColor.propTypes = {
  /**
   * Should marking be disabled
   */
  disableMarking: PropTypes.bool,
  /**
   * On marker selected
   */
  onMarkerSelected: PropTypes.func,
  /**
   * Pictures
   */
  pictures: PropTypes.array,
  /**
   * Object with prev tier markings
   */
  prevTier: PropTypes.array,
};

export default NiriColor;
