import React from 'react';
import VariantGuidanceEdit from './VariantGuidanceEdit';
import { useSelector, useDispatch } from 'react-redux';
import { selectVariant } from './../../redux/tasks/tasksSlice';
import {
  selectRole,
  selectVariantGuidance,
  setVariantGuidance,
} from './../../redux/tasks/tasksSlice';
import { isSupervisor } from './rolesUtil';
import {
  API_ENDPOINT,
  BUCKET_NAME_CONFIG,
} from '../../config/awsLabelingConfig';
import { cloneDeep } from 'lodash';
import { postData } from '../LabelingToolPage/LabelingToolPage.logic';

export const VariantGuidance = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const isSupervisorRole = isSupervisor(role);
  const selectedVariant = useSelector(selectVariant);
  const variantGuidance = useSelector(selectVariantGuidance);
  var variantGuidanceList = cloneDeep(variantGuidance);
  const emptyGuidance = '<p></p>';
  const valueOfVariant =
    selectedVariant && variantGuidanceList[selectedVariant]
      ? variantGuidanceList[selectedVariant]
      : emptyGuidance;

  const saveVariantGuidance = async (text) => {
    variantGuidanceList[selectedVariant] = text;
    dispatch(setVariantGuidance(variantGuidanceList));
    await postData(
      `${API_ENDPOINT}/uploadVariantGuidance/?bucketName=${BUCKET_NAME_CONFIG}`,
      variantGuidanceList
    );
  };
  return (
    <div>
      <VariantGuidanceEdit
        textToEdit={valueOfVariant}
        isSupervisorRole={isSupervisorRole}
        saveVariantGuidance={saveVariantGuidance}
      />
    </div>
  );
};
export default VariantGuidance;
