import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setLabelingDisabled,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import { useSetSelectedMarkData } from '../customHooks';

const useSelectMarking = ({
  missPoints,
  prevMarks,
  markersData,
  disableMarking,
}) => {
  const dispatch = useDispatch();
  const setSelectedMarkData = useSetSelectedMarkData();
  const selectMarking = useCallback(
    (item) => {
      const data = markersData.concat(prevMarks);
      const points = data.concat(missPoints);

      var mark = points.find(
        (mark) =>
          mark.x === item.x &&
          mark.y === item.y &&
          (!mark.type || !item.type || mark.type === item.type)
      );

      if (!mark) return;
      !disableMarking && !mark.missPoint
        ? dispatch(setLabelingDisabled(false))
        : dispatch(setLabelingDisabled(true));

      setSelectedMarkData(mark);

      dispatch(setSelectedTableRowId(mark.id));
    },
    [
      disableMarking,
      dispatch,
      markersData,
      prevMarks,
      setSelectedMarkData,
      missPoints,
    ]
  );

  const selectXRayMarking = useCallback(
    (item) => {
      const data = item.tier === 1 ? prevMarks : markersData;
      const mark = data.find((mark) => mark.x === item.x && mark.y === item.y);
      if (!mark) return;
      !disableMarking
        ? dispatch(setLabelingDisabled(false))
        : dispatch(setLabelingDisabled(true));

      setSelectedMarkData(mark);

      item.tier === 1
        ? dispatch(setSelectedTableRowId(-1))
        : dispatch(setSelectedTableRowId(mark.id));
    },
    [disableMarking, dispatch, markersData, prevMarks, setSelectedMarkData]
  );

  const selectToothMarking = useCallback(
    (item) => {
      const data = item.tier === 1 ? prevMarks : markersData;
      const mark = data.find(
        (mark) =>
          mark.point.x === item.x &&
          mark.point.y === item.y &&
          mark.point.type === item.type
      );
      if (!mark) return;
      !disableMarking
        ? dispatch(setLabelingDisabled(false))
        : dispatch(setLabelingDisabled(true));
      item.tier === 1
        ? dispatch(setSelectedTableRowId(-1))
        : dispatch(setSelectedTableRowId(mark.id));
    },
    [disableMarking, dispatch, markersData, prevMarks]
  );

  return { selectMarking, selectXRayMarking, selectToothMarking };
};

export default useSelectMarking;
