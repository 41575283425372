import React, { useRef, useEffect } from 'react';
import styles from './SupervisorNotes.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { supervisorNotesListSelector } from '../../../../redux/taskStateImages/outputSlice';
import {
  currentTaskSelector,
  taskShortNickNameSelector,
} from '../../../../redux/taskState/taskDetailsSlice';
import {
  currentPhotoIndexSelector,
  setSupervisorNote,
  supervisorNoteSelector,
} from '../../../../redux/marks/currentMarkingSlice';
import { useHandleNewSupervisorNotesList } from '../../customHooks';
import TextArea from '../../../../components/TextArea';

import SupervisorNotesNavigation from './SupervisorNotesNavigation';

const SupervisorNotes = () => {
  const supervisorNotesList = useSelector(supervisorNotesListSelector);
  const supervisorNote = useSelector(supervisorNoteSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentTask = useSelector(currentTaskSelector);
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const header = 'Notes';

  const textRef = useRef();
  const dispatch = useDispatch();

  const handleNewSupervisorNotesList = useHandleNewSupervisorNotesList();

  const findCurrentNote = () =>
    supervisorNotesList?.find(
      (item) => parseInt(item.id) === parseInt(currentPhotoIndex)
    );

  const handleContentChange = () => {
    const currentNote = supervisorNotesList?.find(
      (item) => item.id === currentPhotoIndex
    );

    if (currentNote) {
      dispatch(setSupervisorNote(currentNote.notesContent));
    } else {
      dispatch(setSupervisorNote(''));
    }
  };

  const handleNotesUpdate = () => {
    const currentNote = findCurrentNote();

    if (
      (currentNote &&
        currentNote.notesContent !== supervisorNote &&
        supervisorNote !== undefined) ||
      (!currentNote && supervisorNote !== '')
    ) {
      handleNewSupervisorNotesList();
    }
  };

  useEffect(handleNotesUpdate, [supervisorNote]);
  useEffect(handleContentChange, [
    currentPhotoIndex,
    currentTask,
    taskShortNickName,
  ]);

  const updateNotes = (e) => {
    dispatch(setSupervisorNote(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      dispatch(setSupervisorNote(supervisorNote + '\n'));
    }
  };

  const supervisorNotesNavigation = (
    <div className={styles.notesNavigation}>
      <SupervisorNotesNavigation />
    </div>
  );

  return (
    <div className={styles.notesContainer}>
      <TextArea
        content={supervisorNote}
        onChange={updateNotes}
        onKeyDown={handleKeyDown}
        header={header}
        buttons={supervisorNotesNavigation}
        wrapperClassNameProp={styles.notes}
        headerClassNameProp={styles.notesHeader}
        textClassNameProp={styles.notesTextArea}
        rows="3"
        textRef={textRef}
        disabled={false}
      />
    </div>
  );
};

export default SupervisorNotes;
