import { KeyCodes } from './enums';

const hebrewCases = {
  [KeyCodes.T_KEY]: 'א',
  [KeyCodes.H_KEY]: 'י',
  [KeyCodes.S_KEY]: 'ד',
  [KeyCodes.F_KEY]: 'כ',
  [KeyCodes.E_KEY]: 'ק',
};

function handleCtrlKey(e, keyCode) {
  if (
    isKeyCode(e, keyCode) &&
    (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
  ) {
    e.preventDefault();
  }
}

function handleAltKey(e, keyCode) {
  if (isKeyCode(e, keyCode) && e.altKey) {
    e.preventDefault();
  }
}

function handleKeyOrCtrlUp(e, keyCode, callback) {
  if (
    (isKeyCode(e, keyCode) &&
      (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) ||
    e.key === KeyCodes.CTRL_KEY
  ) {
    callback();
  }
}

function handleKeyup(e, keyCode, callback) {
  if (isKeyCode(e, keyCode)) {
    callback();
  }
}

export const isKeyCode = (e, keyCode) => {
  return (
    e.key === keyCode.toUpperCase() ||
    e.key === keyCode.toLowerCase() ||
    e.key === hebrewCases[keyCode]
  );
};

export const overrideCtrlKey = {
  add(keyCode) {
    document.addEventListener(
      'keydown',
      (e) => handleCtrlKey(e, keyCode),
      false
    );
  },
  remove(keyCode) {
    document.removeEventListener(
      'keydown',
      (e) => handleCtrlKey(e, keyCode),
      false
    );
  },
};

export const overrideAltKey = {
  add(keyCode) {
    document.addEventListener(
      'keydown',
      (e) => handleAltKey(e, keyCode),
      false
    );
  },
  remove(keyCode) {
    document.removeEventListener(
      'keydown',
      (e) => handleAltKey(e, keyCode),
      false
    );
  },
};

export const keyOrCtrUpEvent = {
  add(keyCode, callback) {
    document.addEventListener('keyup', (e) =>
      handleKeyOrCtrlUp(e, keyCode, callback)
    );
  },
  remove(keyCode, callback) {
    document.removeEventListener('keyup', (e) =>
      handleKeyOrCtrlUp(e, keyCode, callback)
    );
  },
};

export const keyupEvent = {
  add(keyCode, callback) {
    document.addEventListener('keyup', (e) =>
      handleKeyup(e, keyCode, callback)
    );
  },
  remove(keyCode, callback) {
    document.removeEventListener('keyup', (e) =>
      handleKeyup(e, keyCode, callback)
    );
  },
};
