import React from 'react';
import PropTypes from 'prop-types';
import styles from './Toggle.module.css';
import ToggleButton from 'react-toggle-button';
import { isTier2 } from '../../../../shared-logic/tiersHelpers';

const Toggle = ({
  tier,
  approvedPrevTier,
  value,
  text,
  onToggle,
  disabledToggle,
}) => {
  const wrapper = isTier2(tier) ? styles.toggleWrapper : styles.commitWrapper;
  const toggleColors =
    approvedPrevTier || disabledToggle
      ? {
          active: {
            base: 'rgb(207,221,245)',
          },
        }
      : {};

  return (
    <div className={wrapper}>
      <div className={styles.flexContainer}>{text}</div>
      <div className={styles.flexContainer}>
        <ToggleButton value={value} colors={toggleColors} onToggle={onToggle} />
      </div>
    </div>
  );
};

Toggle.propTypes = {
  /**
   * tier
   */
  tier: PropTypes.number,
  /**
   * if tier 2 - is approved by prev tier
   */
  approvedPrevTier: PropTypes.bool,
  /**
   *  current value
   */
  value: PropTypes.bool,
  /**
   * text of toggle
   */
  text: PropTypes.string,
  /**
   * on toggle callback
   */
  onToggle: PropTypes.func,
};

export default Toggle;
