import { PointStatus, TYPE_NIRI, TYPE_COLOR, TYPE_XRAY } from './enums';

export function calculateDistance(point1, point2) {
  const xDiff = point2.x - point1.x;
  const yDiff = point2.y - point1.y;
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

function isPointClose(point, otherPoints, threshold, trainingMode) {
  for (let i = 0; i < otherPoints.length; i++) {
    const distance = calculateDistance(point, otherPoints[i]);
    if (
      distance <= threshold &&
      (!trainingMode ||
        (point.findings === otherPoints[i].findings &&
          point.details === otherPoints[i].details))
    ) {
      return true;
    }
  }
  return false;
}

function hasUnmatchedPoints(array1, array2, threshold, trainingMode) {
  for (let i = 0; i < array1.length; i++) {
    if (!isPointClose(array1[i], array2, threshold, trainingMode)) {
      return array1[i];
    }
  }
  return false;
}

export const distanceThresholdPixels = 40;

export const setUnCommited = (key, state, detectionPoints, trainingMode) => {
  const index = state.imagePairs.findIndex((image) => image.imageName === key);
  if (index === -1) return;

  const copyAndUpdateMarkingStatus = (state, index, type, markMistake) => {
    state.imagePairs = [...state.imagePairs];
    const imagePair = { ...state.imagePairs[index] };
    state.imagePairs[index] = imagePair;
    imagePair[type] = { ...imagePair[type] };
    imagePair[type].markings = [...(imagePair[type].markings || [])];
    imagePair[type].markings[markMistake.index] = {
      ...imagePair[type].markings[markMistake.index],
      pointStatus: PointStatus.MISTAKE,
    };
  };

  const types = [TYPE_NIRI, TYPE_COLOR, TYPE_XRAY];
  let markArray = [];
  types.forEach((type) => {
    const markers = [...(state.imagePairs[index]?.[type]?.markings || [])];
    const typeMark =
      markers.map((point, index) => ({
        x: point?.contouring?.[0].data.position.x,
        y: point?.contouring?.[0].data.position.y,
        details: point?.details,
        findings: point?.findings,
        index: index,
        type: type,
      })) || [];

    markArray = [...markArray, ...typeMark];
  });

  const points =
    detectionPoints?.map((point) => ({
      x: point?.contouring?.[0].data.position.x,
      y: point?.contouring?.[0].data.position.y,
      details: point?.details,
      findings: point?.findings,
    })) || [];

  const markMistake =
    trainingMode &&
    hasUnmatchedPoints(
      markArray,
      points,
      distanceThresholdPixels,
      trainingMode
    );

  if (
    markMistake ||
    hasUnmatchedPoints(points, markArray, distanceThresholdPixels)
  ) {
    if (markMistake) {
      copyAndUpdateMarkingStatus(state, index, markMistake.type, markMistake);
    }

    state.imagePairs[index].commited = false;
  }
};
