import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentTaskSelector,
  taskShortNickNameSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { setSupervisorNotesList } from '../../../redux/taskStateImages/outputSlice';
import { setLowerNotes } from '../../../redux/taskStateImages/stateImagesLowerSlice';
import { setUpperNotes } from '../../../redux/taskStateImages/stateImagesUpperSlice';
import { setXRayNotes } from '../../../redux/taskStateImages/stateImagesXRaySlice';
import { Tasks } from '../../../shared-logic/enums';
import labelingToolLogic from '../LabelingToolPage.logic';

const useGetNotesAfterSave = () => {
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const currentTask = useSelector(currentTaskSelector);
  const dispatch = useDispatch();

  const getNotesLogic = async () => {
    const promises = [];
    Object.values(Tasks).forEach((task) => {
      promises.push(
        labelingToolLogic.getSupervisorNotes(taskShortNickName, task)
      );
    });
    const promisesList = await Promise.allSettled(promises);
    switch (currentTask) {
      case Tasks.LOWER:
        dispatch(setSupervisorNotesList(promisesList[1].value || []));
        break;
      case Tasks.UPPER:
        dispatch(setSupervisorNotesList(promisesList[0].value || []));
        break;
      case Tasks.XRAY:
        dispatch(setSupervisorNotesList(promisesList[2].value || []));
        break;
      default:
        break;
    }
    dispatch(setLowerNotes(promisesList[1].value));
    dispatch(setUpperNotes(promisesList[0].value));
    dispatch(setXRayNotes(promisesList[2].value));
  };

  const getNotes = async () => {
    try {
      await getNotesLogic();
    } catch (err) {
      NotificationManager.error(
        'an Error Occured when getting notes',
        'Warning'
      );
    }
  };

  return getNotes;
};
export default useGetNotesAfterSave;
