import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ArrowButton.module.css';
import back from './assets/back.png';
import forward from './assets/forward.png';
import doubleBack from './assets/double-back.png';
import doubleForward from './assets/double-forward.png';
import Directions from './directionsEnum';
import userActionLogs from '../../shared-logic/userActionLogs';

const ArrowButton = ({
  onClick,
  height,
  direction,
  className: classNameProp,
  style,
}) => {
  const className = classNames([classNameProp, styles.buttonContainer]);

  const getDirectionSrc = () => {
    switch (direction) {
      case Directions.BACK:
        return back;
      case Directions.FORWARD:
        return forward;
      case Directions.DOUBLE_BACK:
        return doubleBack;
      case Directions.DOUBLE_FORWARD:
        return doubleForward;
      default:
        return null;
    }
  };

  const src = getDirectionSrc(direction);

  const handleClick = () => {
    userActionLogs.addActionLog(`${direction} clicked`);
    onClick();
  };

  return (
    <button className={className} style={style} onClick={handleClick}>
      <img src={src} alt="" height={height - 2} />
    </button>
  );
};

ArrowButton.propTypes = {
  /**
   * onClick callback
   */
  onClick: PropTypes.func,
  /**
   * height of arrow button
   */
  height: PropTypes.number,
  /**
   * direction of arrow
   */
  direction: PropTypes.oneOf([
    Directions.BACK,
    Directions.FORWARD,
    Directions.DOUBLE_BACK,
    Directions.DOUBLE_FORWARD,
  ]),
  /**
   * className for styling arrow button
   */
  className: PropTypes.string,
  /**
   * style for styling arrow button
   */
  style: PropTypes.object,
};

export default ArrowButton;
