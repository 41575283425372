import { useEffect } from 'react';
import { useHandleNewOutput } from '../customHooks';

const useOnMarkDetailsChange = () => {
  const handleNewOutput = useHandleNewOutput();

  useEffect(() => {
    handleNewOutput();
  }, [handleNewOutput]);
};

export default useOnMarkDetailsChange;
