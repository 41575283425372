import {
  setDisableShortcuts,
  setLabelingDisabled,
  setMarkEdited,
  setRowSelected,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  setCurrentContouring,
  setCurrentMarker,
  setCurrentType,
  setDetails,
  setFindings,
  setProbability,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setCurrentColorACsrc,
  setCurrentNiriACsrc,
  setCurrentXRayACsrc,
  setlabelDetailsTier2,
} from '../../../redux/taskState/taskDetailsSlice';
import { PointStatus } from '../../../shared-logic/enums';
import {
  TYPE_COLOR,
  TYPE_NIRI,
  TYPE_XRAY,
} from '../../../shared-logic/enums/markingStateEnum';
import { NOT_SELECTED } from '../../../shared-logic/params';
import { inProgressDialog } from '../Dialogs';
import { Details } from '../MarkLabeling/Labeling/Details';
import { Findings } from '../MarkLabeling/Labeling/Findings';
import { setSavedX, setSavedY } from './pointCoordinates';
import { getPositionObject } from './shapesLogic';

export const getCurrentImageOrPair = (output, currentPhotoIndex) => {
  const currentImagePair =
    output.imagePairs?.filter(({ id }) => id === currentPhotoIndex) || [];
  return currentImagePair.length ? currentImagePair[0] : null;
};

export const findImageIndex = (prev, currentPairId) => {
  if (!prev) return -1;
  return prev?.imagePairs
    ? prev.imagePairs.findIndex((item) => item.id === currentPairId)
    : prev.findIndex((item) => item.id === currentPairId);
};

export const getCurrentMarkers = (currentPair, type) => {
  switch (type) {
    case TYPE_XRAY:
      return currentPair && currentPair.markings;
    case TYPE_NIRI:
    case TYPE_COLOR:
      return currentPair && currentPair[type]?.markings;
    default:
      return [];
  }
};

export const getMarkers = (type, currentPair) => {
  const currentMarkers = getCurrentMarkers(currentPair, type);
  return (
    (currentMarkers &&
      currentMarkers.map((item) => {
        const mappedObject = {
          x: getPositionObject(item).x,
          y: getPositionObject(item).y,
          tier: 2,
          findings: item.findings,
          details: item.details,
          id: item.id,
          pointStatus: item.pointStatus || PointStatus.REGULAR,
        };
        if (type !== TYPE_XRAY) mappedObject.type = type;
        return mappedObject;
      })) ||
    []
  );
};

export const getPrevMarkers = (type, currentPair, prevTier) => {
  const prevPerType =
    currentPair &&
    prevTier &&
    prevTier.find(
      (markGroup) =>
        markGroup.id === currentPair.id && markGroup[type].length > 0
    );

  const points =
    (prevPerType &&
      prevPerType[type].map((item) => {
        const mappedObject = {
          cluster: item.cluster,
          id: item.id,
          findings: Findings[item.findings] || item.findings,
          details: Details[item.details] || item.details,
          probability: item.probability,
          x: item.contouring[0].data.position.x,
          y: item.contouring[0].data.position.y,
          color: item.color,
          consistent: item.consistent,
          labeler: item.labeler,
        };
        switch (type) {
          case TYPE_XRAY: {
            mappedObject.type = TYPE_XRAY;
            mappedObject.tier = 1;
            break;
          }
          case TYPE_NIRI:
          case TYPE_COLOR:
            mappedObject.type = type;
            mappedObject.tier = item.tier;
            break;
          default:
            break;
        }
        return mappedObject;
      })) ||
    [];
  return points;
};

export const initNewMarker = (
  marker,
  filteredDetails,
  type,
  recentIndication,
  imageSize,
  dispatch
) => {
  const { naturalWidth, width, naturalHeight, height } = imageSize;
  dispatch(setDisableShortcuts(true));
  dispatch(setLabelingDisabled(false));
  dispatch(setlabelDetailsTier2(null));
  if (marker.copy && recentIndication) {
    dispatch(setFindings(recentIndication.findings));
    dispatch(setDetails(recentIndication.details));
  } else {
    dispatch(setFindings(NOT_SELECTED));
    dispatch(setDetails(NOT_SELECTED));
    if (filteredDetails && filteredDetails.length > 0) {
      dispatch(setlabelDetailsTier2(filteredDetails));
    }
  }
  dispatch(setProbability('100%'));
  const x = setSavedX(marker.x, naturalWidth, width);
  const y = setSavedY(marker.y, naturalHeight, height);
  dispatch(
    setCurrentContouring([
      {
        type: 'point',
        data: {
          position: {
            x: x,
            y: y,
          },
        },
      },
    ])
  );
  marker.type = type;
  marker.x = x;
  marker.y = y;
  dispatch(setCurrentMarker(marker));
  if (type !== TYPE_XRAY) dispatch(setCurrentType(type));
  dispatch(setRowSelected(false));
  dispatch(setMarkEdited(false));
  dispatch(setSelectedTableRowId(-1));
};

export const onMarkerCreated = (
  input,
  filteredDetails,
  type,
  findings,
  details,
  currentType,
  currentContouring,
  rowSelected,
  recentIndication,
  imageSize,
  dispatch
) => {
  if (
    (findings === NOT_SELECTED || details === NOT_SELECTED) &&
    currentContouring !== null
  ) {
    if (type === TYPE_XRAY && currentType) return;
    if ([TYPE_COLOR, TYPE_NIRI].includes(type) && !currentType) return;

    inProgressDialog(
      rowSelected,
      () =>
        initNewMarker(
          input,
          filteredDetails,
          type,
          recentIndication,
          imageSize,
          dispatch
        ),
      (v) => dispatch(setSelectedTableRowId(v))
    );
  } else {
    initNewMarker(
      input,
      filteredDetails,
      type,
      recentIndication,
      imageSize,
      dispatch
    );
  }
};

export const resetImagesACsrc = (dispatch) => {
  dispatch(setCurrentNiriACsrc(''));
  dispatch(setCurrentColorACsrc(''));
  dispatch(setCurrentXRayACsrc(''));
};
