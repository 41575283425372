import { shapeInProgressSelector } from '../../../redux/marks/currentMarkingSlice';
import { useSelector } from 'react-redux';
import { isWatcher } from '../../TasksList/rolesUtil';
import { selectRole } from '../../../redux/tasks/tasksSlice';

const useShouldDisableMarking = () => {
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const role = useSelector(selectRole);

  const disableMarking = !!shapeInProgress || isWatcher(role);
  return disableMarking;
};

export default useShouldDisableMarking;
