const variant_id = 'variant_id';
const findings = 'findings';
const default_labelers_number = 'default_labelers_number';
const params = 'params';
const marking_area = 'marking_area';
const tier_2_navigation_mode = 'tier_2_navigation_mode';
const detection_points = 'detection_points';
const ai_algo_misses = 'ai_algo_misses';
const training_mode = 'training_mode';
const geometric_misses = 'geometric_misses';
const auto_correction_disable = 'auto_correction_disable';
const add_detection_to_aggreagation = 'add_detection_to_aggreagation';
const number_of_tier1_labelers = 'number_of_tier1_labelers';

export const findingsList = (config, variant) => {
  return config.variants.find((v) => v[variant_id] === variant)?.[findings];
};

export const getVariant = (config, variantId) => {
  return config.variants.find((x) => x[variant_id] === variantId);
};

export const getAllVariantListActive = (config, user) => {
  return config.variants
    .filter((obj) => obj.users.hasOwnProperty(user) && !obj.hidden)
    .map((obj) => obj.variant_id);
};

export const getAllVariantListHidden = (config, user) => {
  return config.variants
    .filter((obj) => obj.users.hasOwnProperty(user) && obj.hidden)
    .map((obj) => obj.variant_id);
};

export const getTier1LabelersNumber = (config, variantId) => {
  return (
    config?.variants?.find((x) => x[variant_id] === variantId)?.[params]?.[
      number_of_tier1_labelers
    ] || config[default_labelers_number]
  );
};

export const getUserVariants = (config, user, variantsRoles) => {
  if (variantsRoles.size === 0) {
    config.variants.forEach((element) => {
      if (element.users.hasOwnProperty(user) && !element.hidden) {
        variantsRoles.set(element.variant_id, element.users[user]);
      }
    });
  }
  return variantsRoles;
};

export const getUserVariantsIncludeHidden = (config, user) => {
  const variantsRoles = new Map();

  config.variants.forEach((element) => {
    if (element.users.hasOwnProperty(user)) {
      variantsRoles.set(element.variant_id, element.users[user]);
    }
  });
  return variantsRoles;
};

export const getVariantList = (config, variantId) => {
  return config.variants.filter((x) => x[variant_id] === variantId)[0];
};

export const isMarkingAreaEnabled = (config, variant) => {
  return config.variants.find((x) => x[variant_id] === variant)?.[params]?.[
    marking_area
  ];
};

export const isAddDetectionToAggreagation = (config, variant) => {
  return config.variants.find((x) => x[variant_id] === variant)?.[params]?.[
    add_detection_to_aggreagation
  ];
};

const getDetectionPoints = (config, variant) => {
  return config.variants.find((x) => x[variant_id] === variant)?.[params]?.[
    detection_points
  ];
};

export const isAiAlgoMisses = (config, variant) => {
  return getDetectionPoints(config, variant)?.[ai_algo_misses];
};

export const isTrainingMode = (config, variant) => {
  return getDetectionPoints(config, variant)?.[training_mode];
};

export const isGeometricMisses = (config, variant) => {
  return getDetectionPoints(config, variant)?.[geometric_misses];
};

export const getTier2NavigationMode = (config, variant) =>
  config.variants.find((x) => x.variant_id === variant)?.[params]?.[
    tier_2_navigation_mode
  ];

export const getBatchesToVariant = (config) => {
  const extractedData = [];

  config.variants.forEach((variant) => {
    const extractedItem = {
      variantId: variant.variant_id,
      batches: variant.params.batches,
    };
    extractedData.push(extractedItem);
  });
  return extractedData;
};

export const getAutoCorrectionDisable = (config, variant) =>
  config.variants.find((x) => x.variant_id === variant)?.[
    auto_correction_disable
  ];
