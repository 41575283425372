import labelingToolLogic from '../LabelingToolPage.logic';
import {
  TYPE_NIRI,
  TYPE_COLOR,
  PointStatus,
} from '../../../shared-logic/enums';
import { Findings } from '../../LabelingToolPage/MarkLabeling/Labeling/Findings';
import { Details } from '../../LabelingToolPage/MarkLabeling/Labeling/Details';

const useImageMarksData = ({ currentPair }) => {
  const getMarkerObjects = (type) =>
    (currentPair &&
      currentPair[type] &&
      currentPair[type].markings.map((item) => ({
        id: item.id,
        findings: Findings[item.findings],
        details: Details[item.details],
        probability: item.probability,
        x: item.contouring[0].data.position.x,
        y: item.contouring[0].data.position.y,
        type,
        pointStatus: item.pointStatus,
      }))) ||
    [];

  const getMarkerObjectsXRay = () => {
    return (
      (currentPair &&
        currentPair.markings &&
        currentPair.markings.map((item) => ({
          id: item.id,
          findings: Findings[item.findings],
          details: Details[item.details],
          probability: item.probability,
          x: item.contouring[0].data.position.x,
          y: item.contouring[0].data.position.y,
        }))) ||
      []
    );
  };

  const imageMarkersData =
    [...getMarkerObjects(TYPE_NIRI), ...getMarkerObjects(TYPE_COLOR)].sort(
      labelingToolLogic.compareMarkers
    ) || [];

  const imagesXRayMarkersData = getMarkerObjectsXRay();

  return { imageMarkersData, imagesXRayMarkersData };
};

export default useImageMarksData;
