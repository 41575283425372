import React from 'react';
import Toggle from './Toggle';
import styles from './LabelingHeader.module.css';
import {
  isImage,
  isTier2ImageLevel,
} from '../../../shared-logic/taskLevelsTypesHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPhotoIndexSelector,
  shapeInProgressSelector,
  currentMarkerSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  picturesSelector,
  setCommit,
} from '../../../redux/taskStateImages/outputSlice';
import {
  potentiallyDiscardedSelector,
  taskLevelSelector,
  taskShortNickNameSelector,
  tierSelector,
  currentTaskSelector,
  completedSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  approvedPrevTierSelector,
  commitSelector,
} from '../../../redux/taskStateImages/outputSlice';
import classNames from 'classnames';
import userActionLogs from '../../../shared-logic/userActionLogs';
import SupervisorNotes from './SupervisorNotes';
import { setApprovedPrevTier } from '../../../redux/taskStateImages/outputSlice';
import { findingsSelector } from '../../../redux/marks/currentMarkingSlice';
import { setLabelingDisabled } from '../../../redux/labelingTool/labelingToolSlice';
import { NOT_SELECTED } from '../../../shared-logic/params';
import { useResetLabelingSelection } from '../customHooks';
import CompleteTaskContainer from '../CompleteTask/CompleteTaskContainer';
import Save from '../Save';
import { isTooth, isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import JawNavigationContainer from '../JawNavigation/JawNavigationContainer';
import { isTier2 } from '../../../shared-logic/tiersHelpers';
import { isSupervisor, isWatcher } from '../../TasksList/rolesUtil';
import { selectRole } from '../../../redux/tasks/tasksSlice';
import {
  isOutputModifiedSelector,
  setIsOutputModified,
} from '../../../redux/taskStateImages/outputSlice';
import { useSaveState } from '../customHooks';
import MoreButtons from './MoreButtons';

const True = 'True';
const LabelingHeader = () => {
  const dispatch = useDispatch();
  const resetLabelingSelection = useResetLabelingSelection();
  const tier = useSelector(tierSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const commit = useSelector(commitSelector);
  const approvedPrevTier = useSelector(approvedPrevTierSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const isImageLevel = isImage(taskLevel);
  const isTier2Image = isTier2ImageLevel(tier, taskLevel);
  const potentiallyDiscarded = useSelector(potentiallyDiscardedSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const findings = useSelector(findingsSelector);
  const currentMarker = useSelector(currentMarkerSelector);
  const pictures = useSelector(picturesSelector);
  const completed = useSelector(completedSelector);
  const role = useSelector(selectRole);
  const isOutputModified = useSelector(isOutputModifiedSelector);

  const buttonsDisabled = !!shapeInProgress;
  const numPictures = pictures.length;
  const isToothLevel = isTooth(taskLevel);
  const currentTask = useSelector(currentTaskSelector);

  const toggleApprovedPrevTier = () => {
    document.activeElement.blur();
    const approved = !approvedPrevTier;
    dispatch(setApprovedPrevTier(approved));
    userActionLogs.addActionLog(
      `commit set to ${approved} via toggle ApprovedPrevTier`
    );
    dispatch(setCommit(approved));
    if (approved || findings.toLowerCase() === NOT_SELECTED.toLowerCase()) {
      dispatch(setLabelingDisabled(true));
    } else {
      dispatch(setLabelingDisabled(false));
    }
    if (!currentMarker.id) {
      resetLabelingSelection();
    }
  };
  const saveState = useSaveState();

  const onCommitToggle = async () => {
    if (!shapeInProgress && !isWatcher(role)) {
      document.activeElement.blur();
      userActionLogs.addActionLog(
        `commit set to ${!commit} via toggle on pair ${currentPhotoIndex}`
      );
      dispatch(setCommit(!commit));

      if (!commit && isOutputModified) {
        await saveState(true);
        dispatch(setIsOutputModified(false));
      }
    }
  };

  const TIER_2 = isTier2(tier);

  const imageClass = classNames(styles.currentImage, {
    [styles.currentImageTier2]: TIER_2 && isImage(taskLevel),
  });

  const saveButtonClass = classNames({
    [styles.saveBtnTooth]: isToothLevel,
    [styles.saveBtn]: !isToothLevel,
  });
  return (
    <>
      <div className={styles.labelingHeader}>
        {isImageLevel && (
          <Toggle
            approvedPrevTier={approvedPrevTier}
            onToggle={onCommitToggle}
            text={'Commit'}
            tier={tier}
            value={commit}
            disabledToggle={isWatcher(role)}
          />
        )}
        <div className={imageClass}>
          Image: {currentPhotoIndex + 1}/{numPictures} (Task:{' '}
          {taskShortNickName})
        </div>

        {(isTier2Image || isWatcher(role) || isSupervisor(role)) &&
          isImageLevel && <SupervisorNotes />}
      </div>
      {isImageLevel && TIER_2 && (
        <Toggle
          approvedPrevTier={approvedPrevTier}
          onToggle={toggleApprovedPrevTier}
          text={'Approved Previous Tier'}
          tier={tier}
          value={approvedPrevTier}
          disabledToggle={false}
        />
      )}
      <div className={`${styles.flexRow} ${styles.saveContainer}`}>
        <span className={saveButtonClass}>
          <MoreButtons />
          {!potentiallyDiscarded && (
            <>
              <CompleteTaskContainer
                isToothLevel={isToothLevel}
                disabled={
                  buttonsDisabled ||
                  isWatcher(role) ||
                  (isSupervisor(role) && completed === True)
                }
              />
              <Save disabled={buttonsDisabled} />
            </>
          )}
        </span>
        {isToothLevel && !isXRay(currentTask) && <JawNavigationContainer />}
      </div>
    </>
  );
};

export default LabelingHeader;
