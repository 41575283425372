import { LINE, POLYGON } from '../../../../../../../shared-logic/enums';

// adapted from https://stackoverflow.com/questions/849211/shortest-distance-between-a-point-and-a-line-segment
const calcPointDistance = (p, segStartPoint, segEndPoint) => {
  const xPpointDiff = p[0] - segStartPoint[0];
  const yPointDiff = p[1] - segStartPoint[1];
  const xSegDiff = segEndPoint[0] - segStartPoint[0];
  const ySegDiff = segEndPoint[1] - segStartPoint[1];

  const point = xPpointDiff * xSegDiff + yPointDiff * ySegDiff;
  const segLenSq = xSegDiff * xSegDiff + ySegDiff * ySegDiff;
  let param = -1;
  if (segLenSq !== 0) param = point / segLenSq;

  let x, y;

  if (param < 0) {
    x = segStartPoint[0];
    y = segStartPoint[1];
  } else if (param > 1) {
    x = segEndPoint[0];
    y = segEndPoint[1];
  } else {
    x = segStartPoint[0] + param * xSegDiff;
    y = segStartPoint[1] + param * ySegDiff;
  }

  var dx = p[0] - x;
  var dy = p[1] - y;
  return Math.sqrt(dx * dx + dy * dy);
};

// Adds point p to nearest line segment
export const addPointToShape = (points, point, isClosed) => {
  let shortestDistanceLine = points.reduce(
    function (min, nextDist, i) {
      if (!isClosed && i === points.length - 1) return min;
      const nextIndex = i === points.length - 1 ? 0 : i + 1;
      const distance = calcPointDistance(point, points[i], points[nextIndex]);
      if (distance < min.distance) {
        min.distance = distance;
        min.index = i;
      }
      return min;
    },
    { distance: Infinity, index: -1 }
  ).index;
  points.splice(shortestDistanceLine + 1, 0, point);

  return points;
};

export const deletePointFromShape = (points, pointIndex, shape) => {
  if (
    (shape === POLYGON && points.length <= 3) ||
    (shape === LINE && points.length <= 2)
  )
    return points;
  points.splice(pointIndex, 1);
  return points;
};

export const flatPoints = (points, isFinished, curMousePos) =>
  points.length > 0
    ? points
        ?.concat(isFinished ? [] : curMousePos)
        .reduce((a, b) => a.concat(b), [])
    : [];
