export const BORDER = 'border';
export const LABEL = 'label';
export const BORDER_PADDING = 22;
export const PADDING_POSITIONING = 10;
export const COLOR_RED = 'red';
export const COLOR_GREEN = 'green';
export const COLOR_LIGHT_GREEN = '#66FF99';
export const COLOR_YELLOW = '#ddb113';
export const COLOR_BLUE = '#00d3f9';
export const COLOR_LIGHT_BLUE = '#6ae7fe';
