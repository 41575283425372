import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from '../ExportType.module.css';
import SelectedFields from './SelectedFields';
import { ReportSelectedType, ReportType } from '../reportsEnum';
import tasksListLogic from '../../TasksList.logic';
import { NotificationManager } from 'react-notifications';
import {
  getVariantList,
  getAllVariantListActive,
  getAllVariantListHidden,
} from '../../../../config/configUtil';
import { useSelector } from 'react-redux';
import { selectConfig } from '../../../../redux/tasks/tasksSlice';

const DATE = 'Date';
const NONE = 'None';
const params = 'params';
const batch = 'batches';
const Active = 'Active';
const Hidden = 'Hidden';
export const MonitoringReport = ({
  variantsToRolesMap,
  variantsToRolesMapIncludeHidden,
}) => {
  const [optionsForExport, setOptionsForExport] = useState('');
  const [showVariants, setShowVariants] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState(false);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const [dateFrom, setDateFrom] = useState(sevenDaysAgo);
  const [dateTo, setDateTo] = useState(new Date());
  const userName = tasksListLogic.getUserName();
  const config = useSelector(selectConfig);
  const variantListActive = getAllVariantListActive(config, userName);
  const variantListHidden = getAllVariantListHidden(config, userName);

  const convertStringToObject = (stringValue) => ({
    label: stringValue,
    value: stringValue.replace(/\s+/g, '-').toLowerCase(),
  });

  const variantForCurrentUser =
    optionsForExport === ReportType.WORK_PER_VARIANT ||
    optionsForExport === ReportType.LABELING_REPORT
      ? variantsToRolesMapIncludeHidden
      : variantsToRolesMap;

  const fillData = (label, variantList) => {
    return {
      label: label,
      value: label,
      children: variantList
        ?.filter((item) => [...variantForCurrentUser.keys()].includes(item))
        .map(convertStringToObject),
    };
  };

  const data =
    optionsForExport === ReportType.WORK_PER_VARIANT ||
    optionsForExport === ReportType.LABELING_REPORT
      ? [
          fillData(Active, variantListActive),
          fillData(Hidden, variantListHidden),
        ]
      : [fillData(Active, [...variantForCurrentUser.keys()])];

  const onReportChange = (value) => {
    setShowVariants(true);
    setShowExport(false);
    setOptionsForExport(value);
  };

  const onReportClick = () => {
    const valuesToExport =
      ReportSelectedType[optionsForExport] === DATE ||
      ReportSelectedType[optionsForExport] === NONE
        ? null
        : selectedNodes
            .map((node) =>
              node._parent
                ? node.label
                : node._children
                ? data
                    .find((d) => d.value === node.value)
                    ?.children.map((x) => x.label)
                : node.label
            )
            .flat();
    reports(optionsForExport, valuesToExport, dateFrom, dateTo);
  };

  const reports = async (
    optionsForExport,
    selectedNodesArray,
    dateFrom,
    dateTo
  ) => {
    try {
      const filteredMap = [...variantsToRolesMap.keys()].filter(
        (key) =>
          variantsToRolesMap.get(key).includes('supervisor') ||
          variantsToRolesMap.get(key).includes('watcher')
      );

      const varaintsList = selectedNodesArray ?? filteredMap;

      const batchToVariant = await varaintsList.map((variant) => {
        const variants = getVariantList(config, variant);
        const batches = variants?.[params]?.[batch];
        return { variant: variant, batches: batches };
      });

      const batchToVariantJson = JSON.stringify(batchToVariant);

      NotificationManager.success(
        'Request received. Data will be downloaded soon',
        'Success!'
      );
      const response = await tasksListLogic.getMonitoringReport(
        optionsForExport,
        batchToVariantJson,
        dateFrom,
        dateTo,
        userName
      );

      const executionArn = response.executionArn;
      await tasksListLogic.checkExecutionAndRetrieveOutput(executionArn);

      const res = await tasksListLogic.getOutputReport(
        `output-${userName}-${optionsForExport}.txt`
      );
      const outputFile = res.configFiles.file;

      const element = document.createElement('a');
      const file = new Blob([outputFile], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `output-${optionsForExport}.txt`;
      document.body.appendChild(element);
      await element.click();
    } catch (err) {
      NotificationManager.error(err, 'Warning');
    }
  };

  const onTreeChange = (currentNode, selectedNodes) => {
    setShowExport(true);
    setSelectedNodes(selectedNodes);
  };

  return (
    <div className={styles.formcontainer}>
      <SelectedFields
        onTreeChange={onTreeChange}
        onReportChange={onReportChange}
        optionsForExport={optionsForExport}
        showVariants={showVariants}
        data={data}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        dateFrom={dateFrom}
        dateTo={dateTo}
        dataList={ReportSelectedType}
      />
      <ButtonReport
        onReportClick={onReportClick}
        showExport={
          showExport ||
          ReportSelectedType[optionsForExport] === DATE ||
          ReportSelectedType[optionsForExport] === NONE
        }
      />
    </div>
  );
};

const ButtonReport = ({ onReportClick, showExport }) => {
  return (
    <button
      // className={styles.button}
      onClick={onReportClick}
      disabled={!showExport}
    >
      Report
    </button>
  );
};

MonitoringReport.propTypes = {
  exportLabelsForAi: PropTypes.func.isRequired,
  variantsToRolesMap: PropTypes.object,
};

export default MonitoringReport;
