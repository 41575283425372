import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  TYPE_NIRI,
  TYPE_COLOR,
  TYPE_XRAY,
  Tasks,
} from '../../shared-logic/enums';
import LabelingToolPage from '../LabelingToolPage';
import styles from '../../App.module.css';
import { HourglassProgress } from '../../components';
import {
  setAssignee,
  setBatch,
  setPath,
  setTaskShortNickName,
  setImagesType,
  setTaskLevel,
  setTier,
  setCurrentTask,
  setPotentiallyDiscarded,
  setPotentialMissPoint,
} from '../../redux/taskState/taskDetailsSlice';
import {
  setCurrentContouring,
  setCurrentMarker,
  setCurrentPhotoIndex,
  setCurrentToothIndex,
  setCurrentType,
  setDetails,
  setFindings,
  setMaterial,
  setSubType,
  setType,
  setPrevPhotoIndex,
  setShapeInProgress,
} from '../../redux/marks/currentMarkingSlice';
import { isFDI } from '../LabelingToolPage/JawNavigation/teeth/teeth';
import {
  setLabelingDisabled,
  setMarkEdited,
  setSelectedTableRowId,
} from '../../redux/labelingTool/labelingToolSlice';
import {
  setLowerImages,
  setLowerNotes,
  setLowerPrevTierState,
  setLowerState,
} from '../../redux/taskStateImages/stateImagesLowerSlice';
import {
  outputSelector,
  setApprovedPrevTier,
  setIsOutputModified,
  setOutput,
  setPictures,
  setPrevTierState,
  setSupervisorNotesList,
} from '../../redux/taskStateImages/outputSlice';
import {
  setUpperImages,
  setUpperNotes,
  setUpperPrevTierState,
  setUpperState,
} from '../../redux/taskStateImages/stateImagesUpperSlice';
import {
  setXRayImages,
  setXRayState,
  setXRayPrevState,
  setXRayNotes,
} from '../../redux/taskStateImages/stateImagesXRaySlice';
import { NOT_SELECTED } from '../../shared-logic/params';
import {
  setColorBrightness,
  setColorContrast,
  setNiriBrightness,
  setNiriContrast,
} from '../../redux/taskState/contrastBrightnessSlice';
import { resetRotation } from '../../redux/taskState/rotationSlice';
import {
  isRejected,
  getUrlParams,
  getUrlParam,
  retrieveSuperTaskData,
  setCommitAndApproved,
  addImageNumberToNewState,
  addToothPointsToTier2,
  sendLoadingDuration,
} from './LabelingToolData.logic';
import userActionLogs from '../../shared-logic/userActionLogs';
import { isImage, isTooth } from '../../shared-logic/taskLevelsTypesHelper';
import { isTierWithPrevMarks, isTier1 } from '../../shared-logic/tiersHelpers';
import { isEqual } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { isTrainingMode } from '../../config/configUtil';
import { selectConfig, selectVariant } from '../../redux/tasks/tasksSlice';
import * as LTDC from './LabelingToolDataConstants';
import { ipData } from '../../shared-logic/fetchApi';

const LabelingToolData = () => {
  const loadingTaskStartTimeStamp = useRef(0);
  const [isActivated, setIsActivated] = useState(false);

  useEffect(() => {
    if (isActivated) {
      updateLoadingDuration();
    } else {
      loadingTaskStartTimeStamp.current = Date.now();
    }

    async function updateLoadingDuration() {
      const ip = await ipData();
      const taskId = getUrlParam('taskId');
      const batch = getUrlParam('batch');
      const path = getUrlParam('path');
      const startingTime = loadingTaskStartTimeStamp.current;
      await sendLoadingDuration(path, batch, taskId, startingTime, ip, 'task');
    }
  }, [isActivated, loadingTaskStartTimeStamp]);

  const location = useLocation();
  const isAbort = useRef(false);
  const abortController = useRef(null);
  const signal = useRef(null);
  const [error, setError] = useState('');
  const output = useSelector(outputSelector, isEqual);
  const history = useHistory();
  const level = getUrlParam('level');
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const variant = useSelector(selectVariant);
  const lowerKeys = useMemo(() => {
    return {
      imageResponseKey: LTDC.LOWER_IMAGE_RESPONSE,
      stateResponseKey: LTDC.LOWER_STATE_RESPONSE,
      supervisorNotesKey: LTDC.LOWER_SUPERVISOR_NOTES,
      previousAggregationKey: LTDC.LOWER_PREVIOUS_AGGREGATION,
    };
  }, []);
  const upperKeys = useMemo(() => {
    return {
      imageResponseKey: LTDC.UPPER_IMAGE_RESPONSE,
      stateResponseKey: LTDC.UPPER_STATE_RESPONSE,
      supervisorNotesKey: LTDC.UPPER_SUPERVISOR_NOTES,
      previousAggregationKey: LTDC.UPPER_PREVIOUS_AGGREGATION,
    };
  }, []);
  const xRayKeys = useMemo(() => {
    return {
      imageResponseKey: LTDC.XRAY_IMAGE_RESPONSE,
      stateResponseKey: LTDC.XRAY_STATE_RESPONSE,
      supervisorNotesKey: LTDC.XRAY_SUPERVISOR_NOTES,
      previousAggregationKey: LTDC.XRAY_PREVIOUS_AGGREGATION,
    };
  }, []);
  const lowerSetters = useMemo(() => {
    return {
      setJawImages: setLowerImages,
      setJawState: setLowerState,
      setJawNotes: setLowerNotes,
      setJawPrevTierState: setLowerPrevTierState,
    };
  }, []);
  const upperSetters = useMemo(() => {
    return {
      setJawImages: setUpperImages,
      setJawState: setUpperState,
      setJawNotes: setUpperNotes,
      setJawPrevTierState: setUpperPrevTierState,
    };
  }, []);
  const xRaySetters = useMemo(() => {
    return {
      setJawImages: setXRayImages,
      setJawState: setXRayState,
      setJawNotes: setXRayNotes,
      setJawPrevTierState: setXRayPrevState,
    };
  }, []);

  const resetPrevStateImages = useCallback(() => {
    userActionLogs.addActionLog('reset images and state for entire case');
    dispatch(setLowerState({}));
    dispatch(setLowerImages([]));
    dispatch(setUpperState({}));
    dispatch(setUpperImages([]));
    dispatch(setXRayState({}));
    dispatch(setXRayImages([]));
    dispatch(setOutput({}));
    dispatch(setPictures([]));
  }, [dispatch]);

  const resetPrevState = useCallback(() => {
    dispatch(setType(NOT_SELECTED));
    dispatch(setSubType(NOT_SELECTED));
    dispatch(setMaterial(NOT_SELECTED));
    dispatch(setFindings(NOT_SELECTED));
    dispatch(setIsOutputModified(false));
    dispatch(setLabelingDisabled(true));
    dispatch(setMarkEdited(false));
    dispatch(setSelectedTableRowId(-1));
    dispatch(setCurrentMarker({}));
    dispatch(setCurrentTask(Tasks.LOWER));
    dispatch(setCurrentContouring(null));
    dispatch(setCurrentType(null));
    dispatch(setNiriBrightness(100));
    dispatch(setColorBrightness(100));
    dispatch(setNiriContrast(100));
    dispatch(setColorContrast(100));
    dispatch(setApprovedPrevTier(false));
    dispatch(setShapeInProgress(null));
    dispatch(resetRotation());
    resetPrevStateImages();
    dispatch(setPrevPhotoIndex(null));
    dispatch(setPrevTierState([]));
  }, [dispatch, resetPrevStateImages]);

  const initStore = useCallback(
    ({
      taskId,
      assignee,
      tier,
      imagesType,
      taskLevel,
      batch,
      path,
      potentiallyDiscarded,
    }) => {
      userActionLogs.addActionLog('init store called');
      dispatch(setTaskShortNickName(taskId));
      dispatch(setAssignee(assignee));
      dispatch(setTier(tier));
      dispatch(setImagesType(imagesType));
      dispatch(setTaskLevel(taskLevel));
      dispatch(setBatch(batch));
      dispatch(setPath(path));
      dispatch(setPotentiallyDiscarded(potentiallyDiscarded));
      const toothId =
        taskId && taskId.includes(Tasks.UPPER)
          ? isFDI
            ? 18
            : 1
          : isFDI
          ? 38
          : 17;
      userActionLogs.addActionLog(
        `tooth index for task ${Tasks.LOWER} was set to ${toothId} on init`
      );
      toothId && dispatch(setCurrentToothIndex(toothId));
      dispatch(setDetails('Not Selected'));
      userActionLogs.addActionLog(
        `photo index for task ${Tasks.LOWER} was set to 0 on init`
      );
      dispatch(setCurrentPhotoIndex({ task: Tasks.LOWER, index: 0 }));
      resetPrevState();
    },
    [dispatch, resetPrevState]
  );

  const saveSuperTaskData = useCallback(
    (superTaskData) => {
      let isDispatched = false;

      const getData = (key, def) => {
        const obj = superTaskData.get(key);
        const isDataExist = obj && !isRejected(obj);
        const data = isDataExist ? obj.value : def;
        return [data, isDataExist];
      };

      const saveJawData = (jaw, keys, setters) => {
        const [jawImages, isJawImagesExist] = getData(
          keys.imageResponseKey,
          []
        );
        const [jawState, isJawStateExist] = getData(keys.stateResponseKey, []);
        const isJawExist =
          isJawImagesExist && isJawStateExist && jawImages.length !== 0;
        if (isJawExist) {
          const [jawNotes, isJawNotesExist] = getData(
            keys.supervisorNotesKey,
            []
          );
          const [jawPreviousAggregation, isJawPreviousAggregationExist] =
            getData(keys.previousAggregationKey, null);

          userActionLogs.addActionLog(
            `${jaw} images length ${jawImages.length}`
          );

          dispatch(setters.setJawImages(jawImages));
          dispatch(setters.setJawState(jawState));
          dispatch(setters.setJawNotes(jawNotes));
          dispatch(setters.setJawPrevTierState(jawPreviousAggregation));

          if (!isDispatched) {
            dispatch(setPictures(jawImages));
            dispatch(setOutput(jawState));
            isJawNotesExist && dispatch(setSupervisorNotesList(jawNotes));
            isJawPreviousAggregationExist &&
              dispatch(setPrevTierState(jawPreviousAggregation));
            dispatch(setCurrentTask(jaw));
            userActionLogs.addActionLog(`current task set to ${jaw} on init`);
            isDispatched = true;
          }
        }
      };

      const handleTrainingMode = () => {
        if (variant && isTrainingMode(config, variant)) {
          const [detectionPoints, isDetectionPointsExist] = getData(
            LTDC.DETECTION_POINTS,
            null
          );
          if (isDetectionPointsExist) {
            dispatch(
              setPotentialMissPoint(
                detectionPoints ? Object.values(detectionPoints)[2] : null
              )
            );
          } else {
            console.error(`Error fetching training detection points`);
          }
        }
      };

      saveJawData(Tasks.LOWER, lowerKeys, lowerSetters);
      saveJawData(Tasks.UPPER, upperKeys, upperSetters);
      saveJawData(Tasks.XRAY, xRayKeys, xRaySetters);

      dispatch(setPotentialMissPoint(null));
      handleTrainingMode();
    },
    [
      config,
      dispatch,
      lowerKeys,
      lowerSetters,
      upperKeys,
      upperSetters,
      variant,
      xRayKeys,
      xRaySetters,
    ]
  );

  const isFetchAborted = (res) =>
    res.find((r) => isRejected(r) && r.reason?.name === 'AbortError') ||
    isAbort.current;

  const fetchData = useCallback(async () => {
    const {
      taskId,
      assignee,
      tier,
      imagesType,
      taskLevel,
      batch,
      path,
      potentiallyDiscarded,
    } = getUrlParams();

    initStore({
      taskId,
      assignee,
      tier,
      imagesType,
      taskLevel,
      batch,
      path,
      potentiallyDiscarded,
    });
    const isTraining = variant && isTrainingMode(config, variant);
    userActionLogs.addActionLog(`fetch data of task ${taskId} called`);
    try {
      setIsActivated(false);
      const res = await retrieveSuperTaskData(
        path,
        assignee,
        tier,
        taskLevel,
        taskId,
        signal.current,
        isTraining,
        batch
      );
      if (isFetchAborted(Array.from(res.values()))) {
        userActionLogs.addActionLog(`fetch data of task ${taskId} aborted`);
        resetPrevState();
        return;
      }
      userActionLogs.addActionLog(`fetch data of task ${taskId} finished`);

      const hasPrevTierMarkings = isTierWithPrevMarks(tier);

      const prepareData = (keys, typesArray) => {
        const jawImagesResponse = res.get(keys.imageResponseKey);
        const jawStateResponse = res.get(keys.stateResponseKey);
        if (jawImagesResponse.value.length !== 0) {
          const jawPrevTierStateResponse =
            res.has(keys.previousAggregationKey) &&
            !isRejected(res.get(keys.previousAggregationKey)) &&
            res.get(keys.previousAggregationKey).value
              ? res.get(keys.previousAggregationKey)
              : null;

          if (
            isTraining &&
            isTier1(tier) &&
            res.has(keys.supervisorNotesKey) &&
            isRejected(res.get(keys.supervisorNotesKey))
          ) {
            NotificationManager.error('Not valid task', 'Warning');
            throw Error('Cannot retrieve data of training mode');
          }

          const rejectedTierTwo =
            hasPrevTierMarkings && isRejected(jawPrevTierStateResponse);

          if (
            isRejected(jawStateResponse) ||
            (hasPrevTierMarkings && rejectedTierTwo)
          ) {
            throw Error('Cannot retrieve data');
          }

          if (hasPrevTierMarkings) {
            if (isImage(taskLevel)) {
              jawStateResponse.value = setCommitAndApproved(
                jawStateResponse.value,
                jawPrevTierStateResponse.value,
                jawImagesResponse.value.length,
                typesArray
              );
            } else {
              jawStateResponse.value = addToothPointsToTier2(
                jawStateResponse.value,
                jawPrevTierStateResponse.value
              );
            }
          }

          jawStateResponse.value = addImageNumberToNewState(
            jawStateResponse.value,
            jawImagesResponse.value
          );
        }
      };

      prepareData(lowerKeys, [TYPE_NIRI, TYPE_COLOR]);
      prepareData(upperKeys, [TYPE_NIRI, TYPE_COLOR]);
      prepareData(xRayKeys, [TYPE_XRAY]);

      saveSuperTaskData(res, taskId);

      if (error) setError('');
      setIsActivated(true);
    } catch (err) {
      setError('Failed to fetch task data');
      history.push('/');
    }
  }, [
    initStore,
    variant,
    config,
    lowerKeys,
    upperKeys,
    xRayKeys,
    saveSuperTaskData,
    error,
    resetPrevState,
    history,
  ]);

  useEffect(() => {
    abortController.current = new AbortController();
    signal.current = abortController.current.signal;
    userActionLogs.addActionLog('route has changed');
    fetchData();

    return () => {
      abortController.current.abort();
      isAbort.current = true;
    };
  }, [fetchData]);

  const isValidState = () => {
    return (
      (isTooth(level) && output.teeth) || (isImage(level) && output.imagePairs)
    );
  };

  if (!isActivated && location.pathname === '/task') {
    return (
      <div className={styles.center}>
        <HourglassProgress />
      </div>
    );
  }

  return (
    <div className={styles.root}>{isValidState() && <LabelingToolPage />}</div>
  );
};

export default LabelingToolData;
