import { useDispatch, useSelector } from 'react-redux';
import {
  currentContouringSelector,
  currentTypeSelector,
  findingsSelector,
  detailsSelector,
  setShapeInProgress,
  shapeInProgressSelector,
  setLastShape,
} from '../../../redux/marks/currentMarkingSlice';
import submitEditingDialog from '../Dialogs/submitEditingDialog';
import {
  useSelectMarking,
  usePrevMarks,
  useSaveShapeData,
} from '../customHooks';
import { isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import { currentTaskSelector } from '../../../redux/taskState/taskDetailsSlice';
import {
  setSelectedTableRowId,
  rowSelectedSelector,
} from '../../../redux/labelingTool/labelingToolSlice';
import { NOT_SELECTED } from '../../../shared-logic/params';
import { inProgressDialog } from '../Dialogs';
import useShouldDisableMarking from './useShouldDisableMarking';
import useMissPoint from './useMissPoint';
const useOnMarkerSelected = () => {
  const dispatch = useDispatch();
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const currentTask = useSelector(currentTaskSelector);
  const findings = useSelector(findingsSelector);
  const details = useSelector(detailsSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const { saveShapeData } = useSaveShapeData();

  const { prevMarks, markersData } = usePrevMarks();
  const missPointsRes = useMissPoint();
  const missPoints = missPointsRes?.missPoints;

  const disableMarking = useShouldDisableMarking();

  const { selectMarking } = useSelectMarking({
    missPoints,
    prevMarks,
    markersData,
    disableMarking,
  });

  const { selectXRayMarking } = useSelectMarking({
    prevMarks,
    markersData,
    disableMarking,
  });

  const onMarkerSelected = (input) => {
    dispatch(setLastShape(null));
    if (shapeInProgress) {
      submitEditingDialog(
        () => saveShapeData(shapeInProgress),
        () => dispatch(setShapeInProgress(null)),
        () => selectMarking(input)
      );
      return;
    }
    if (
      (findings === NOT_SELECTED || details === NOT_SELECTED) &&
      (isXRay(currentTask) || currentType !== null) &&
      currentContouring !== null
    ) {
      inProgressDialog(
        rowSelected,
        () => selectMarking(input),
        (v) => dispatch(setSelectedTableRowId(v))
      );
      return;
    }
    isXRay(currentTask) ? selectXRayMarking(input) : selectMarking(input);
  };

  return onMarkerSelected;
};

export default useOnMarkerSelected;
