import PropTypes from 'prop-types';
import React, { memo } from 'react';
import DatePicker from 'react-date-picker';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-dropdown-tree-select/dist/styles.css';
import '../LabelsAndVariants.css';
const VARIANT = 'variant';
const DATE = 'Date';

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.optionsForExport === nextProps.optionsForExport &&
    prevProps.dateFrom === nextProps.dateFrom &&
    prevProps.dateTo === nextProps.dateTo
  );
};

const SelectedFields = memo(
  ({
    onTreeChange,
    onReportChange,
    optionsForExport,
    showVariants,
    data,
    setDateFrom,
    setDateTo,
    dateFrom,
    dateTo,
    dataList,
  }) => {
    return (
      <div className="flex">
        <div className="labelsTree">
          <label className="reportLabel">Report Type:</label>
          {Object.keys(dataList).map((item, index) => (
            <div key={'Report' + index}>
              <input
                type="radio"
                id={`radioReport-${index}`}
                name="itemSelectionReport"
                value={item}
                checked={optionsForExport === item}
                onChange={(e) => onReportChange(e.target.value)}
              />
              <label htmlFor={`radioReport-${index}`} className="reportLabel">
                {item}
              </label>
              <br />
            </div>
          ))}
        </div>
        {dataList[optionsForExport] === DATE && (
          <div className="dateSelection">
            <label className="dateLabel">Dates:</label>
            <div className="dateLabel">
              <label>From:</label>
              <br />

              <DatePicker
                className="dateFrom"
                onChange={setDateFrom}
                value={dateFrom}
                dayLabelClassName="customLabel"
                monthLabelClassName="customLabel"
                yearLabelClassName="customLabel"
              />
            </div>
            <div className="dateLabel">
              <label>To:</label>
              <br />
              <DatePicker
                className="dateTo"
                onChange={setDateTo}
                value={dateTo}
              />
            </div>
          </div>
        )}
        {dataList[optionsForExport] === VARIANT && showVariants && (
          <div className="variantsTree">
            <label className="reportLabel">From Varaints:</label>
            <div className="tree-outer-wrap">
              <div className="tree-inner-wrap">
                <DropdownTreeSelect
                  data={data}
                  showDropdown="always"
                  texts={{ placeholder: 'Search' }}
                  style={{ width: '300px' }}
                  onChange={onTreeChange}
                  showPartiallySelected="true"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
  areEqual
);

SelectedFields.propTypes = {
  onTreeChange: PropTypes.func,
  onReportChange: PropTypes.func,
  optionsForExport: PropTypes.element,
  showVariants: PropTypes.bool,
  data: PropTypes.array,
  onChangeDateFrom: PropTypes.func,
  onChangeDateTo: PropTypes.func,
  dateFrom: PropTypes.DATE,
  dateTo: PropTypes.DATE,
};

export default SelectedFields;
