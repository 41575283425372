import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';

const prevToothMarks = (prevState, currentToothIndex) => {
  if (prevState && prevState.length > 0) {
    const currentTooth = prevState.find(({ id }) => id === currentToothIndex);
    const toothMarks = currentTooth && currentTooth.markings;
    if (toothMarks)
      return Object.keys({
        ...toothMarks.inconsistent,
        ...toothMarks.consistent,
      });
  }
};

const prevImageMarks = (prevState) =>
  prevState && prevState.length > 0 && prevState.map((mark) => mark.labeler);

export const createLabelersList = (
  prevState,
  currentLevel,
  currentToothIndex
) => {
  let labelers = isTooth(currentLevel)
    ? prevToothMarks(prevState, currentToothIndex)
    : prevImageMarks(prevState);
  if (labelers && labelers.length > 0) {
    labelers = [...new Set(labelers)];
    labelers = labelers.map((labeler) => ({
      shortName: labeler.slice(0, 2),
      name: labeler.split('@')[0],
    }));
    return labelers;
  }
};
