import React from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan-with-rotation';
import PropTypes from 'prop-types';

const PinchZoomContainer = ({ pinchProps, disable, children }) => {
  return (
    <>
      {disable ? (
        <div>{children}</div>
      ) : (
        <PinchZoomPan {...pinchProps}>{children}</PinchZoomPan>
      )}
    </>
  );
};

PinchZoomContainer.propTypes = {
  /**
   * Left placement location
   */
  pinchProps: PropTypes.object,
  /**
   * Top placement location
   */
  disable: PropTypes.bool,
  /**
   * Function to set mark findings
   */
  children: PropTypes.node.isRequired,
};

export default PinchZoomContainer;
