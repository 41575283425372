import React, { useCallback, useEffect, useState } from 'react';
import { Stage, Layer, Line, Circle } from 'react-konva';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PolygonLine.module.css';
import {
  addPointToShape,
  deletePointFromShape,
  flatPoints,
} from './PolygonLine.logic';
import Done from '../../../../../Done/Done';
import {
  BLACK,
  ENTER,
  ESCAPE,
  KEY_DOWN,
  RED,
  WHITE,
  shouldRenderShape,
  YELLOW,
} from '../../shared-logic';
import { POLYGON, BOUNDING_BOX } from '../../../../../../../shared-logic/enums';

const PolygonLine = ({
  pointsArray,
  imageSize,
  shapeInProgress,
  saveShapeData,
  setShapeInProgress,
  setCurrentShapeData,
  isSelectedShape,
  shapeName,
  isRenderTypeMatchMarkerType,
  hideAreas,
  hideMarks,
}) => {
  const isPolygon = (shapeName) => shapeName === POLYGON;
  const [points, setPoints] = useState([]);
  const [isFinished, setIsFinished] = useState(
    pointsArray.length >= 3 && isPolygon(shapeName) ? true : false
  );
  const [curMousePos, setCurMousePos] = useState(null);
  const [isMouseOverStartPoint, setIsMouseOverStartPoint] = useState(false);
  const isShapeInProgress =
    shapeInProgress === shapeName && isRenderTypeMatchMarkerType;
  const stageClassName = classNames(styles.stage, {
    [styles.upLayer]: isShapeInProgress && !isSelectedShape,
    [styles.selected]: isSelectedShape,
  });

  useEffect(() => {
    if (!shapeInProgress) {
      setPoints(pointsArray);
    }
  }, [points, pointsArray, setCurrentShapeData, shapeInProgress]);

  const isStartPoint = (index) => index === 0;
  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  const savePoints = (points) => {
    setPoints([...points]);
    setCurrentShapeData([...points]);
  };

  const handleClick = (event) => {
    const isNewShape = pointsArray.length === 0;
    if (isShapeInProgress) {
      const stage = event.target.getStage();
      const mousePos = getMousePos(stage);
      const index = event.target.index - 1;
      if (isSelectedShape && !isNewShape) {
        if (index === -1) {
          const sortedPoints = addPointToShape(
            points,
            mousePos,
            isPolygon(shapeName)
          );
          savePoints(sortedPoints);
        }
      } else if (isMouseOverStartPoint && points.length >= 3) {
        setIsFinished(true);
        saveShapeData(shapeName);
      } else {
        if (index === -1) {
          savePoints([...points, mousePos]);
        }
      }
    }
  };

  const handleMouseMove = (event) => {
    if (isPolygon(shapeInProgress) && isPolygon(shapeName)) {
      const stage = event.target.getStage();
      const mousePos = getMousePos(stage);
      setCurMousePos(mousePos);
    }
  };

  const handleMouseOverPoint = (event, index) => {
    if (isShapeInProgress) {
      event.target.scale({ x: 2, y: 2 });
      if (isStartPoint(index) && !isFinished && points.length > 2) {
        setIsMouseOverStartPoint(true);
      }
    }
  };

  const handleMouseOutPoint = (event, index) => {
    if (isShapeInProgress) {
      event.target.scale({ x: 1, y: 1 });
      if (isStartPoint(index)) {
        setIsMouseOverStartPoint(false);
      }
    }
  };

  const handleDragMovePoint = (event) => {
    if (isShapeInProgress) {
      const index = event.target.index - 1;
      const pos = [event.target.attrs.x, event.target.attrs.y];
      savePoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
    }
  };

  const handlePointClick = (event) => {
    if (isShapeInProgress && event.evt.shiftKey) {
      const index = event.target.index - 1;
      const pointsArray = deletePointFromShape(points, index, shapeName);
      savePoints(pointsArray);
    }
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (shapeInProgress !== BOUNDING_BOX) {
        const isNewShape = pointsArray.length === 0;
        if (isSelectedShape || isNewShape) {
          if (e.key === ENTER) {
            saveShapeData(shapeName);
            if (isPolygon(shapeName)) {
              setIsFinished(true);
            }
          } else if (e.key === ESCAPE) {
            setShapeInProgress(null);
          }
        }
      }
    },
    [
      isSelectedShape,
      pointsArray.length,
      saveShapeData,
      setShapeInProgress,
      shapeName,
      shapeInProgress,
    ]
  );

  useEffect(() => {
    document.addEventListener(KEY_DOWN, handleKeyPress);
    return () => {
      document.removeEventListener(KEY_DOWN, handleKeyPress);
    };
  }, [handleKeyPress]);

  const isRenderDoneComponent = () => {
    if (isShapeInProgress) {
      if (isPolygon(shapeName)) {
        return isSelectedShape;
      }
      return true;
    }
    return false;
  };

  return (
    <>
      {shouldRenderShape(hideAreas, hideMarks, isSelectedShape) && (
        <Stage
          width={imageSize.width}
          height={imageSize.height}
          onMouseDown={handleClick}
          onMouseMove={handleMouseMove}
          className={stageClassName}
          id={shapeName}
        >
          <Layer>
            <Line
              points={flatPoints(points, isFinished, curMousePos)}
              stroke={isSelectedShape ? RED : YELLOW}
              strokeWidth={2}
              closed={isFinished}
            />
            {points.map((point, index) => {
              const width = 6;
              const x = point[0];
              const y = point[1];
              return (
                <Circle
                  key={index}
                  x={x}
                  y={y}
                  width={width}
                  height={width}
                  fill={WHITE}
                  stroke={BLACK}
                  strokeWidth={1}
                  onDragMove={handleDragMovePoint}
                  onMouseOver={(event) => handleMouseOverPoint(event, index)}
                  onMouseOut={(event) => handleMouseOutPoint(event, index)}
                  onClick={handlePointClick}
                  draggable={isShapeInProgress}
                />
              );
            })}
          </Layer>
        </Stage>
      )}
      {isRenderDoneComponent() && (
        <Done handleDoneClick={() => saveShapeData(shapeName)} />
      )}
    </>
  );
};
PolygonLine.propTypes = {
  /**
   * points position array
   */
  pointsArray: PropTypes.array,
  /**
   * image size object
   */
  imageSize: PropTypes.object,
  /**
   * the shape string that is in progress
   */
  shapeInProgress: PropTypes.string,
  /**
   * function to save polygon data
   */
  saveShapeData: PropTypes.func,
  /**
   * function to set shape in progress
   */
  setShapeInProgress: PropTypes.func,
  /**
   * function to set current shape data
   */
  setCurrentShapeData: PropTypes.func,
  /**
   * When `true`, polygon render as selected
   */
  isSelectedShape: PropTypes.bool,
  /**
   * shape name to render
   */
  shapeName: PropTypes.string,
  /**
   * was a render type match marker type or not
   */
  isRenderTypeMatchMarkerType: PropTypes.bool,
  /**
   * Should hide areas
   */
  hideAreas: PropTypes.bool,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
};
PolygonLine.defaultProps = {
  pointsArray: [],
  isRenderTypeMatchMarkerType: false,
};

export default PolygonLine;
