import { Findings } from './Findings';
import { Details } from './Details';

export const findingsDetails = {
  [Findings['Not Selected']]: [Details['Not Selected']],
  [Findings['NOTHING']]: [Details['Not Selected'], Details['NOTHING']],
  [Findings['Filling / FS']]: [
    Details['Not Selected'],
    Details['Amalgam'],
    Details['Composite'],
    Details['Faulty Filling/FS/Core'],
    Details['Fissure Sealant'],
    Details['Glass Ionomer'],
    Details['Temporary Filling'],
  ],
  [Findings['Caries']]: [
    Details['Not Selected'],
    Details['Cervical Surface Caries'],
    Details['E1 (Did not reach DEJ)'],
    Details['E2 (Reached the dentin)'],
    Details['Secondary Caries (E1)'],
    Details['Secondary Caries (E2)'],
    Details['Secondary Caries (Surface)'],
    Details['Surface Caries: Regular'],
  ],
  [Findings['Add-On']]: [
    Details['Not Selected'],
    Details['Aligner Attachment'],
    Details['Braces'],
    Details['Clear Aligners'],
    Details['Jewlery'],
    Details['Lingual Retainer'],
    Details['Ortho Button'],
    Details['Ortho Ring'],
    Details['Rest for Denture'],
  ],
  [Findings['Tooth Anatomy']]: [
    Details['Not Selected'],
    Details['Black Triangle'],
    Details['Broken Cusp'],
    Details['Central Diastema'],
    Details['Chipping'],
    Details['Crack'],
    Details['Craze Line'],
    Details['Exposed Roots Surface'],
    Details['Tooth Gap'],
    Details['Tooth Wear'],
  ],
  [Findings['Color Change']]: [
    Details['Not Selected'],
    Details['Amalgam Tattoo'],
    Details['Decalcification / Demineralization'],
    Details['Discoloration'],
    Details['Exposed Metal on PFM'],
    Details['Pigmented Pit'],
    Details['Stains'],
    Details['Thread Uncovering'],
  ],
  [Findings['Oral Hygiene']]: [
    Details['Not Selected'],
    Details['Calculus'],
    Details['Gum Inflamation'],
    Details['Plaque'],
  ],
  [Findings['Soft Tissue']]: [
    Details['Not Selected'],
    Details['Aphtha'],
    Details['Fistula'],
    Details['Gum Recession'],
    Details['Operculum'],
    Details['Leukoplakia'],
  ],
  [Findings['General']]: [Details['Not Selected'], Details['Dirt on Sleeve']],
  [Findings['Caries+Conf']]: [
    Details['Not Selected'],
    Details['E1 (high conf)'],
    Details['E1 (med conf)'],
    Details['E1 (low conf)'],
    Details['E2 (high conf)'],
    Details['E2 (med conf)'],
    Details['E2 (low conf)'],
  ],
};
