import React, { useEffect, useState } from 'react';
import styles from './SupervisorNotesNavigation.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { supervisorNotesListSelector } from '../../../../redux/taskStateImages/outputSlice';
import { currentTaskSelector } from '../../../../redux/taskState/taskDetailsSlice';
import {
  currentPhotoIndexSelector,
  setCurrentPhotoIndex,
} from '../../../../redux/marks/currentMarkingSlice';
import { picturesSelector } from '../../../../redux/taskStateImages/outputSlice';
import { ArrowButton } from '../../../../components';
import Directions from '../../../../components/ArrowButton/directionsEnum';
import { useHandleNewNavigation } from '../../customHooks';

export const SupervisorNotesNavigation = () => {
  const supervisorNotesList = useSelector(supervisorNotesListSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const numPictures = useSelector(picturesSelector).length;
  const currentTask = useSelector(currentTaskSelector);
  const [buttonsColor, setButtonsColor] = useState();
  const next = 1;
  const prev = numPictures - 1;

  const dispatch = useDispatch();

  const findNote = (index) =>
    supervisorNotesList?.find((item) => parseInt(item.id) === parseInt(index));

  const NoteHasContent = (index) => {
    const note = findNote(index);
    return note && note.notesContent !== '';
  };

  const calculateIndex = (currentIndex, direction) =>
    (currentIndex + direction) % numPictures;

  const findExistNote = (direction) => {
    let index = calculateIndex(currentPhotoIndex, direction);
    while (index !== currentPhotoIndex && !NoteHasContent(index)) {
      index = calculateIndex(index, direction);
    }
    dispatch(setCurrentPhotoIndex({ task: currentTask, index: index }));
  };

  const handleNavigateToNote = useHandleNewNavigation(findExistNote);

  const getButtonsStyle = (list, index) => {
    return list?.length === 0 || (list?.length === 1 && findNote(index))
      ? styles.emptyNotesList
      : null;
  };

  useEffect(() => {
    const style = getButtonsStyle(supervisorNotesList, currentPhotoIndex);
    setButtonsColor(style);
  }, [supervisorNotesList, currentPhotoIndex]);

  return (
    <>
      <ArrowButton
        direction={Directions.BACK}
        onClick={() => handleNavigateToNote(prev)}
        className={[
          styles.supervisorNotesNavigationsArrowButtons,
          buttonsColor,
        ]}
        height={20}
      />
      <ArrowButton
        direction={Directions.FORWARD}
        onClick={() => handleNavigateToNote(next)}
        className={[
          styles.supervisorNotesNavigationsArrowButtons,
          buttonsColor,
        ]}
        height={20}
      />
    </>
  );
};

export default SupervisorNotesNavigation;
