export const Details = {
  'Not Selected': 'Not Selected',
  Amalgam: 'Amalgam',
  Composite: 'Composite',
  'Faulty Filling/FS/Core': 'Faulty Filling/FS/Core',
  'Fissure Sealant': 'Fissure Sealant',
  'Dirt on Sleeve': 'Dirt on Sleeve',
  'Glass Ionomer': 'Glass Ionomer',
  'Temporary Filling': 'Temporary Filling',
  'Cervical Surface Caries': 'Cervical Surface Caries',
  'E1 (Did not reach DEJ)': 'E1 Caries (Did not reach DEJ)',
  'E2 (Reached the dentin)': 'E2 Caries (Reached the dentin)',
  'Secondary Caries (E1)': 'Secondary Caries (E1)',
  'Secondary Caries (E2)': 'Secondary Caries (E2)',
  'Secondary Caries (Surface)': 'Secondary Caries (Surface)',
  'Surface Caries: Regular': 'Surface Caries: Regular',
  'Aligner Attachment': 'Aligner Attachment',
  Braces: 'Braces',
  'Clear Aligners': 'Clear Aligners',
  Jewlery: 'Jewlery',
  'Lingual Retainer': 'Lingual Retainer',
  'Ortho Button': 'Ortho Button',
  'Ortho Ring': 'Ortho Ring',
  'Rest for Denture': 'Rest for Denture',
  'Black Triangle': 'Black Triangle',
  'Broken Cusp': 'Broken Cusp',
  'Central Diastema': 'Central Diastema',
  Chipping: 'Chipping',
  Crack: 'Crack',
  'Craze Line': 'Craze Line',
  'Exposed Roots Surface': 'Exposed Roots Surface',
  'Tooth Gap': 'Tooth Gap',
  'Tooth Wear': 'Tooth Wear',
  'Amalgam Tattoo': 'Amalgam Tattoo',
  'Decalcification / Demineralization': 'Decalcification / Demineralization',
  Discoloration: 'Discoloration',
  'Exposed Metal on PFM': 'Exposed Metal on PFM',
  'Pigmented Pit': 'Pigmented Pit',
  Stains: 'Stains',
  'Thread Uncovering': 'Thread Uncovering',
  Aphtha: 'Aphtha',
  Fistula: 'Fistula',
  'Gum Recession': 'Gum Recession',
  Operculum: 'Operculum',
  Leukoplakia: 'Leukoplakia',
  Calculus: 'Calculus',
  'Gum Inflamation': 'Gum Inflamation',
  Plaque: 'Plaque',
  NOTHING: 'NOTHING',
  'E1 (high conf)': 'E1 (high conf)',
  'E1 (med conf)': 'E1 (med conf)',
  'E1 (low conf)': 'E1 (low conf)',
  'E2 (high conf)': 'E2 (high conf)',
  'E2 (med conf)': 'E2 (med conf)',
  'E2 (low conf)': 'E2 (low conf)',
};
