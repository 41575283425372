import PropTypes from 'prop-types';
import React, { memo } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import '../LabelsAndVariants.css';

const areEqual = (prevProps, nextProps) => {
  return prevProps.optionsForExport === nextProps.optionsForExport;
};

export const LabelsAndVariants = memo(
  ({ optionsForExport, onDataChange, showVariants, data, onTreeChange }) => {
    const dataList = ['Tier3 results', 'Tier2 results', 'Everything'];

    return (
      <div className="flex">
        <div className="labelsTree">
          <label>Which Labels:</label>
          {dataList.map((item, index) => (
            <div key={index}>
              <input
                type="radio"
                id={`radio-${index}`}
                name="itemSelection"
                value={item}
                checked={optionsForExport === item}
                onChange={(e) => onDataChange(e.target.value)}
              />
              <label htmlFor={`radio-${index}`}>{item}</label>
              <br />
            </div>
          ))}
        </div>
        {showVariants && (
          <div className="variantsTree">
            <label>From Varaints:</label>
            <div className="tree-outer-wrap">
              <div className="tree-inner-wrap">
                <DropdownTreeSelect
                  data={data}
                  showDropdown="always"
                  style={{ width: '300px' }}
                  onChange={onTreeChange}
                  showPartiallySelected="true"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
  areEqual
);

LabelsAndVariants.propTypes = {
  optionsForExport: PropTypes.string,
  onDataChange: PropTypes.func,
  showVariants: PropTypes.func,
  data: PropTypes.object,
  onTreeChange: PropTypes.func,
};

export default LabelsAndVariants;
