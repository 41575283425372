import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHandleContrastBrightnessChange } from '../customHooks';
import {
  colorBrightnessSelector,
  colorContrastSelector,
  niriBrightnessSelector,
  niriContrastSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';
import {
  brightnessSelector,
  contrastSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';

const useContrastBrightnessChange = () => {
  const handleContrastOrBrigtnessChange = useHandleContrastBrightnessChange();
  const colorBrightness = useSelector(colorBrightnessSelector);
  const colorContrast = useSelector(colorContrastSelector);
  const niriBrightness = useSelector(niriBrightnessSelector);
  const niriContrast = useSelector(niriContrastSelector);
  const contrast = useSelector(contrastSelector);
  const brightness = useSelector(brightnessSelector);

  useEffect(handleContrastOrBrigtnessChange, [
    colorBrightness,
    colorContrast,
    niriBrightness,
    niriContrast,
    contrast,
    brightness,
  ]);
};

export default useContrastBrightnessChange;
