import { cloneDeep } from 'lodash';

//colors
export const BLACK = 'black';
export const WHITE = 'white';
export const RED = 'rgb(253, 35,46)';
export const YELLOW = 'rgb(243, 239, 8)';
export const TOMATO = '#ff6347';
//keys
export const ENTER = 'Enter';
export const ESCAPE = 'Escape';
export const KEY_DOWN = 'keydown';
//directions
export const TOP_LEFT = 'top-left';
export const TOP_RIGHT = 'top-right';
export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM_RIGHT = 'bottom-right';

export const buildContouring = (currentContouring, data, shape) => {
  if (currentContouring) {
    let contouring = cloneDeep(currentContouring);
    const currentDataIndex = currentContouring.findIndex(
      (data) => data.type === shape
    );
    const dataObject = { type: shape, data: data };
    if (currentDataIndex !== -1) {
      contouring[currentDataIndex] = dataObject;
    } else {
      contouring = [...currentContouring, dataObject];
    }
    return contouring;
  }
};

export const shouldRenderShape = (hideAreas, hideMarks, isSelectedShape) => {
  if (hideMarks) {
    return false;
  }
  if (hideAreas) {
    if (isSelectedShape) {
      return true;
    }
    return false;
  }
  return true;
};
