import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../LabelingToolPage.module.css';
import { TaskLevels } from '../../../../shared-logic/enums';
import { isTooth } from '../../../../shared-logic/taskLevelsTypesHelper';
import classNames from 'classnames';

const MarkingStatus = ({ numMarked, numTotal, taskLevel }) => {
  const committedText = isTooth(taskLevel) ? 'Identified Teeth:' : 'Committed:';
  return (
    <div className={styles.statusWrapper}>
      <span className={styles.statusTitle}>{committedText}</span>
      <span className={classNames([styles.statusDetail, 'committedStatus'])}>
        {numMarked}/{numTotal} ({Math.floor((numMarked / numTotal) * 100)}%)
      </span>
    </div>
  );
};

MarkingStatus.propTypes = {
  /**
   * Number of images/teeth marked
   */
  numMarked: PropTypes.number,
  /**
   * Number of total images/teeth
   */
  numTotal: PropTypes.number,
  /**
   * Task level (image, tooth, etc.)
   */
  taskLevel: PropTypes.oneOf([TaskLevels.TOOTH, TaskLevels.IMAGE]),
};

export default MarkingStatus;
