import { isEmpty, isArray } from 'lodash';

import logic from './logger.logic';
const { loggerLogic, getConfig } = logic;

const timers = {};
const logger = (
  severity = 'info',
  message = '',
  to = { console: true, parentWindow: true },
  data = {}
) => {
  return {
    info: (message) => {
      const severity = 'info';
      return logger(severity, message, to, data);
    },
    log: (message) => {
      const severity = 'info';
      return logger(severity, message, to, data);
    },
    debug: (message) => {
      const severity = 'debug';
      return logger(severity, message, to, data);
    },
    error: (message) => {
      const severity = 'error';
      return logger(severity, message, to, data);
    },
    warn: (message) => {
      const severity = 'warn';
      return logger(severity, message, to, data);
    },
    to: (_to = 'console') => {
      let to = { [_to]: true };
      if (isArray(_to)) {
        to = _to.reduce((acc, key) => ({ ...acc, [key]: true }), {});
      }
      return logger(severity, message, to, data);
    },
    data: (data = {}) => {
      return logger(severity, message, to, data);
    },
    end: () => {
      if (!isEmpty(message)) {
        loggerLogic(getConfig(), { to, data, message, severity });
      }
    },
    time: (key) => {
      timers[key] = window.performance.now();
    },
    timeEnd: function (key, data = {}) {
      if (!timers[key]) return;
      const timeMs = window.performance.now() - timers[key];
      delete timers[key];
      this.debug(`${key}: ${timeMs}`).to('console').data(data).end();
      return timeMs;
    },
  };
};

export default logger();
