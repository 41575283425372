import React from 'react';
import styles from '../ImageFrameManipulation.module.css';
import PropTypes from 'prop-types';

const ToothTierTwoConflictMenuList = ({ teethArray, isConflictMenu }) => {
  return (
    <>
      {teethArray.length > 0 ? (
        <div
          className={
            isConflictMenu
              ? styles.conflictedTeethWrapper
              : styles.nonConflictedTeethWrapper
          }
        >
          {teethArray}
        </div>
      ) : null}
    </>
  );
};

ToothTierTwoConflictMenuList.propTypes = {
  /**
   * teethArray - component rendered jsx
   */
  teethArray: PropTypes.array,
  /**
   * weather menu is a conflict menu or not (used for styling)
   */
  isConflictMenu: PropTypes.bool,
};

export default ToothTierTwoConflictMenuList;
