import React from 'react';
import { isLabeler, isTier2Reviewer } from '../rolesUtil';
import styles from './TasksFilterControls.module.css';
import CheckBox from '../../../components/CheckBox';
import PropTypes from 'prop-types';
import classNames from 'classnames';
const searchPlaceholder = 'search';
const TasksFilterControls = ({
  tasks,
  role,
  showCompleted,
  setShowCompleted,
  showInProgress,
  setShowInProgress,
  searchText,
  setSearchText,
}) => {
  // const inProgressLabel = isLabeler(role) ? 'Hide 100%' : 'In Progress';

  const filterControls = classNames(styles.checkbox, styles.controls, {
    [styles.rightCheckbox]: isLabeler(role),
  });
  const completedCheckboxStyle = classNames(
    styles.checkbox,
    styles.rightCheckbox
  );

  // const handleShowCompleted = () => {
  //   if (showInProgress) {
  //     setShowInProgress(!showInProgress);
  //   }
  //   setShowCompleted(!showCompleted);
  // };

  const handleShowInProgress = () => {
    if (showCompleted) {
      setShowCompleted(!showCompleted);
    }
    setShowInProgress(!showInProgress);
  };

  return (
    tasks.length > 0 && (
      <div className={filterControls}>
        {!isTier2Reviewer(role) && (
          <CheckBox
            isChecked={showInProgress}
            onChange={handleShowInProgress}
            label={'Hide 100%'}
            classNameProp={completedCheckboxStyle}
          />
        )}
        {/* use this once we find an optimal solution  */}
        {/* {(isSupervisor(role) || isWatcher(role)) && (
          <CheckBox
            isChecked={showCompleted}
            onChange={handleShowCompleted}
            label={"Completed"}
            classNameProp={completedCheckboxStyle}
          />
        )} */}

        <input
          placeholder={searchPlaceholder}
          value={searchText}
          type="text"
          onChange={(e) => setSearchText(e.target.value)}
          className={styles.searchText}
        />
      </div>
    )
  );
};

TasksFilterControls.propTypes = {
  /**
   * tasks array
   */
  tasks: PropTypes.array,
  /**
   * current role
   */
  role: PropTypes.string,
  /**
   * If 'true' 'copmleted' checkbox will render as checked
   */
  showCompleted: PropTypes.bool,
  /**
   * function to set showCompleted flag
   */
  setShowCompleted: PropTypes.func,
  /**
   * If 'true' 'in progress' checkbox will render as checked
   */
  showInProgress: PropTypes.bool,
  /**
   * function to set showInProgress flag
   */
  setShowInProgress: PropTypes.func,
  /**
   * string of search
   */
  searchText: PropTypes.string,
  /**
   * function to set searchText string
   */
  setSearchText: PropTypes.func,
};

export default TasksFilterControls;
