import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  currentMarkerSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
  setCurrentShapeData,
  setShapeInProgress,
  shapeInProgressSelector,
} from '../../../../../../../redux/marks/currentMarkingSlice';
import Rectangle from './Rectangle';
import { TYPE_COLOR, TYPE_NIRI } from '../../../../../../../shared-logic/enums';
import { useSaveShapeData } from '../../../../../customHooks';

const RectangleContainer = ({
  rectangle,
  hideMarks,
  onMarkerCreated,
  editMode,
  isNiri,
  selectedRec,
}) => {
  const dispatch = useDispatch();
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);
  const imageSize = isNiri ? imageSizeNiri : imageSizeColor;

  const currentMarker = useSelector(currentMarkerSelector);
  const { saveShapeData } = useSaveShapeData();
  const shapeInProgress = useSelector(shapeInProgressSelector);

  const isRenderTypeMatchMarkerType =
    (isNiri && currentMarker.type === TYPE_NIRI) ||
    (!isNiri && currentMarker.type === TYPE_COLOR);

  return (
    <div>
      {(isRenderTypeMatchMarkerType || rectangle) && (
        <Rectangle
          isRenderTypeMatchMarkerType={isRenderTypeMatchMarkerType}
          rectangle={rectangle}
          saveShapeData={saveShapeData}
          setShapeInProgress={(v) => dispatch(setShapeInProgress(v))}
          setCurrentShapeData={(v) => {
            dispatch(setCurrentShapeData(v));
          }}
          width={imageSize.width}
          height={imageSize.height}
          hideMarks={hideMarks}
          onMarkerCreated={onMarkerCreated}
          editMode={editMode}
          shapeInProgress={shapeInProgress}
          selectedRect={selectedRec}
        />
      )}
    </div>
  );
};

export default RectangleContainer;

RectangleContainer.propTypes = {
  /**
   * rectangle to render
   */
  rectangle: PropTypes.object,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
  /**
   *when a new marker is created
   */
  onMarkerCreated: PropTypes.func,
  /**
   * was a niri or color type
   */
  isNiri: PropTypes.bool,
  /**
   * Should select rectangle
   */
  selectedRect: PropTypes.bool,
};
