import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './ExportType.module.css';
import UsersConsole from './UsersConsole';
import BatchConsole from './BatchConsole';
import CampaignConsole from './CampaignConsole';
import FindingsConsole from './FindingsConsole';
import ExportLabelsforAI from './ExportLabelsforAI';
import MonitoringReport from './MonitoringReport';
import { ExportAndImportToTier2 } from './ExportAndImportToTier2';

const openAdd = 'openAdd';
const openEdit = 'openEdit';
const openAddFindings = 'openAddFindings';
const openEditFindings = 'openEditFindings';
const batchConsoleOpen = 'batchConsoleOpen';
const campaignConsoleOpen = 'campaignConsoleOpen';
const thirdPartyLabeling = 'thirdPartyLabeling';
const monitoringReport = 'monitoringReport';
const exportLabelsforAI = 'exportLabelsforAI';

export const ManagementConsole = ({
  variantList,
  isAdminRole,
  variantsToRolesMap,
  variantsToRolesMapIncludeHidden,
}) => {
  const [openType, setOpenType] = useState(null);
  return (
    <>
      <h3
        className={styles.paragraph}
        onClick={() => setOpenType(thirdPartyLabeling)}
      >
        {thirdPartyLabeling === openType ? '▲' : '▼'} Third Party Labeling
      </h3>
      {openType === thirdPartyLabeling && <ExportAndImportToTier2 />}

      <h3
        className={styles.paragraph}
        onClick={() => setOpenType(monitoringReport)}
      >
        {monitoringReport === openType ? '▲' : '▼'} Monitoring Reports
      </h3>
      {openType === monitoringReport && (
        <MonitoringReport
          variantsToRolesMap={variantsToRolesMap}
          variantsToRolesMapIncludeHidden={variantsToRolesMapIncludeHidden}
        />
      )}
      <h3
        className={styles.paragraph}
        onClick={() => setOpenType(exportLabelsforAI)}
      >
        {exportLabelsforAI === openType ? '▲' : '▼'} Export Labels
      </h3>
      {openType === exportLabelsforAI && <ExportLabelsforAI />}

      {isAdminRole && (
        <>
          {[false, true].map((isEdit, index) => (
            <div key={index}>
              <h3
                className={styles.paragraph}
                onClick={() => setOpenType(isEdit ? openEdit : openAdd)}
              >
                {(isEdit ? openType === openEdit : openType === openAdd)
                  ? '▲'
                  : '▼'}{' '}
                {isEdit ? 'Edit' : 'Add'} User
              </h3>
              {(isEdit ? openType === openEdit : openType === openAdd) && (
                <UsersConsole variantList={variantList} isEdit={isEdit} />
              )}
            </div>
          ))}{' '}
          {[false, true].map((isEdit, index) => (
            <div key={index}>
              <h3
                className={styles.paragraph}
                onClick={() =>
                  setOpenType(isEdit ? openEditFindings : openAddFindings)
                }
              >
                {(
                  isEdit
                    ? openType === openEditFindings
                    : openType === openAddFindings
                )
                  ? '▲'
                  : '▼'}{' '}
                {isEdit ? 'Edit' : 'Add'} Findings
              </h3>
              {(isEdit
                ? openType === openEditFindings
                : openType === openAddFindings) && (
                <FindingsConsole isEdit={isEdit} />
              )}
            </div>
          ))}
          <h3
            className={styles.paragraph}
            onClick={() => {
              setOpenType(batchConsoleOpen);
            }}
          >
            {openType === batchConsoleOpen ? '▲' : '▼'} Add Batch
          </h3>
          {openType === batchConsoleOpen && (
            <BatchConsole campaignList={variantList} />
          )}
          <h3
            className={styles.paragraph}
            onClick={() => {
              setOpenType(campaignConsoleOpen);
            }}
          >
            {openType === campaignConsoleOpen ? '▲' : '▼'} Add Campaign
          </h3>
          {openType === campaignConsoleOpen && <CampaignConsole />}
        </>
      )}
    </>
  );
};

ManagementConsole.propTypes = {
  variantList: PropTypes.object,
};

export default ManagementConsole;
