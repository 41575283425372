import { cloneDeep } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentMarkerSelector,
  currentPhotoIndexSelector,
  currentToothIndexSelector,
  materialSelector,
  subTypeSelector,
  typeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  outputSelector,
  setIsOutputModified,
} from '../../../redux/taskStateImages/outputSlice';
import {
  handleDeleteImageLevel,
  handleDeleteImageXRayLevel,
  handleDeleteToothLevel,
} from '../markingStateUtil/state.util';
import { isValidToothIndication } from '../shared-logic';
import styles from './Delete.module.css';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  labelingDisabledSelector,
  setDisableShortcuts,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  currentTaskSelector,
  taskLevelSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { isTooth, isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import userActionLogs from '../../../shared-logic/userActionLogs';
import {
  useDeletePrevTierMark,
  useHandleNewOutputPerType,
  useResetLabelingSelection,
  useResetToothControls,
} from '../customHooks';
import { isEqual } from 'lodash';

const Delete = ({ disableMarking }) => {
  const dispatch = useDispatch();
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const currentMarker = useSelector(currentMarkerSelector);
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const output = useSelector(outputSelector, isEqual);
  const taskLevel = useSelector(taskLevelSelector);
  const labelingDisabled = useSelector(labelingDisabledSelector);
  const currentTask = useSelector(currentTaskSelector);
  const resetLabelingSelection = useResetLabelingSelection();
  const resetToothControls = useResetToothControls();
  const deletePrevMark = useDeletePrevTierMark();

  useHotkeys(
    'del',
    () => {
      handleDelete();
    },
    [currentMarker, disableMarking]
  );

  const setDisableShortcutsHelper = (v) => dispatch(setDisableShortcuts(v));
  const setIsOtputModifiedHelper = (v) => dispatch(setIsOutputModified(v));

  const setOutputHelper = useHandleNewOutputPerType();

  const isActionDisabled =
    !isValidToothIndication(type, subType, material) &&
    (disableMarking || (labelingDisabled && !currentMarker.id));

  const handleDelete = () => {
    if (isActionDisabled) {
      return;
    }
    if (isTooth(taskLevel)) {
      userActionLogs.addActionLog(
        `deleting mark of tooth ${currentToothIndex} in task level ${taskLevel}`
      );
      handleDeleteToothLevel(
        setDisableShortcutsHelper,
        currentMarker,
        disableMarking,
        currentToothIndex,
        resetToothControls,
        setOutputHelper,
        setIsOtputModifiedHelper,
        cloneDeep(output)
      );
    } else if (isXRay(currentTask)) {
      userActionLogs.addActionLog(
        `deleting x-ray mark in task level ${taskLevel}`
      );
      handleDeleteImageXRayLevel(
        setDisableShortcutsHelper,
        currentMarker,
        disableMarking,
        currentPhotoIndex,
        resetLabelingSelection,
        setOutputHelper,
        setIsOtputModifiedHelper,
        cloneDeep(output)
      );
    } else {
      userActionLogs.addActionLog(
        `deleting image mark in task level ${taskLevel}`
      );
      handleDeleteImageLevel(
        setDisableShortcutsHelper,
        currentMarker,
        disableMarking,
        currentPhotoIndex,
        resetLabelingSelection,
        setOutputHelper,
        setIsOtputModifiedHelper,
        cloneDeep(output),
        deletePrevMark
      );
    }
  };
  return (
    <button
      disabled={isActionDisabled}
      className={styles.buttonDel}
      onClick={handleDelete}
    >
      Delete
    </button>
  );
};

Delete.propTypes = {
  /**
   * Is marking disabled
   */
  disableMarking: PropTypes.bool,
  /**
   * Reset labeling selection boxes
   */
  resetLabelingSelection: PropTypes.func,
};

export default Delete;
