import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { GroupedDropDownList } from '../../../../components';
import styles from './Labeling.module.css';
import { findingsDetails } from './FindingsDetails.js';

const Labeling = (props) => {
  const {
    disabled,
    onChangedOutput,
    findings,
    details,
    detailsRef,
    findingsList,
  } = props;
  const inputDetailsRef = useRef();

  useImperativeHandle(detailsRef, () => ({
    focus: () => {
      inputDetailsRef.current.focus();
    },
  }));

  const filterFindingsList = (findingsDetails, findingsList) => {
    const allFindings = Object.keys(findingsDetails);
    const filteredFindingsList = !findingsList
      ? allFindings
      : findingsList.filter((f) => allFindings.includes(f));
    return filteredFindingsList;
  };

  const findingsContent = filterFindingsList(findingsDetails, findingsList);

  const findingValue = findingsContent.includes(findings)
    ? findings
    : findingsContent[0];

  return (
    <div className={styles.container}>
      <div className={styles.groupedDropDownListContainer}>
        <GroupedDropDownList
          id="findingsDropDownList"
          width={350}
          selectedValue={findingValue}
          label="Findings"
          items={[
            {
              label: 'Findings',
              content: findingsContent,
            },
          ]}
          onChange={(selection) =>
            onChangedOutput(
              'findings',
              selection,
              findingsDetails[selection][0]
            )
          }
          disabled={disabled}
          className={'findings'}
        />
      </div>
      <div className={styles.groupedDropDownListContainer}>
        <GroupedDropDownList
          innerRef={inputDetailsRef}
          id="detailsDropDownList"
          width={350}
          selectedValue={details}
          label="Details"
          items={[
            {
              label: 'Details',
              content: findingsDetails[findingValue],
            },
          ]}
          onChange={(selection) => {
            onChangedOutput('details', selection);
          }}
          disabled={disabled}
          className={'details'}
        />
      </div>
    </div>
  );
};

Labeling.propTypes = {
  disabled: PropTypes.bool,
  /**
   * Values of findings
   */
  findings: PropTypes.string,
  /**
   * Values of details
   */
  details: PropTypes.string,
  /**
   * Values of probability
   */

  probability: PropTypes.string,
  /**
   * Submit Event
   */
  onChangedOutput: PropTypes.func.isRequired,
  /**
   * a reference for details component
   */
  detailsRef: PropTypes.object,
  /**
   * a reference for findings component
   */
  findingsRef: PropTypes.object,
};

Labeling.defaultProps = {
  disabled: false,
  findings: 'Oral Hygiene',
  details: 'Plaque',
  probability: '100%',
};

export default Labeling;
