import { Details } from '../MarkLabeling/Labeling/Details';

export const dictionaryImage = {
  DictionaryData: [
    { details: Details['Amalgam'], explanation: 'Amalgam filling' },
    { details: Details['Composite'], explanation: 'Composite filling' },
    {
      details: Details['Faulty Filling/FS/Core'],
      explanation: 'Open margin or broken',
    },
    { details: Details['Fissure Sealant'], explanation: 'Fissure sealant' },
    {
      details: Details['Glass Ionomer'],
      explanation: 'Any white non-composite filling',
    },
    {
      details: Details['Temporary Filling'],
      explanation: 'Filling from IRM, Coltasol, etc',
    },
    {
      details: Details['Cervical Surface Caries'],
      explanation:
        'Any cervical lesion that looks carious in nature (brown-black)',
    },
    {
      details: Details['E1 (Did not reach DEJ)'],
      explanation: 'A carious lesion only in the enamel',
    },
    {
      details: Details['E2 (Reached the dentin)'],
      explanation: 'A carious lesion reached the dentin ',
    },
    {
      details: Details['Secondary Caries (E1)'],
      explanation:
        'Same as E1 when there is a direct proximity to an existing filling',
    },
    {
      details: Details['Secondary Caries (E2)'],
      explanation:
        'Same as E2 when there is a direct proximity to an existing filling',
    },
    {
      details: Details['Secondary Caries (Surface)'],
      explanation:
        'Same as surface caries when there is a direct proximity to an existing filling',
    },
    {
      details: Details['Surface Caries: Regular'],
      explanation:
        'A shallow carious lesion on the occlusal/incisal surface or the buccal/lingual surface',
    },
    {
      details: Details['Aligner Attachment'],
      explanation:
        'Bonded composite on the teeth surface which provides extra grip for the clear aligner',
    },
    {
      details: Details['Braces'],
      explanation: 'Orthodontics bracket bonded to the tooth surface',
    },
    {
      details: Details['Clear Aligners'],
      explanation: 'Transparent orthodontics device (which are not brackets)',
    },
    {
      details: Details['Jewlery'],
      explanation: 'Diamond (or other jewelry) bonded to tooth surface',
    },
    {
      details: Details['Lingual Retainer'],
      explanation:
        'Bonded wire on the lingual surface of the teeth. Note: mark in the middle of any tooth included in the lingual retainer',
    },
    {
      details: Details['Ortho Button'],
      explanation:
        'Small metal bumps that are used to attach or anchor rubber bands',
    },
    {
      details: Details['Ortho Ring'],
      explanation: 'Metal bands that are fitted around the molars',
    },
    {
      details: Details['Rest for Denture'],
      explanation:
        'Prepared area on the teeth, providing support to a partial denture',
    },
    {
      details: Details['Black Triangle'],
      explanation:
        'The papilla is not fulfilling the gap underneath the contact point',
    },
    {
      details: Details['Broken Cusp'],
      explanation: 'Tooth cusp that was broken, in a molar or pre-molar',
    },
    {
      details: Details['Central Diastema'],
      explanation: 'Tooth gap, in the space between the central incisors',
    },
    {
      details: Details['Chipping'],
      explanation: 'Minor damage to the tooth enamel, usually on incisors',
    },
    {
      details: Details['Crack'],
      explanation: 'Crack that requires a thorough clinical examination',
    },
    {
      details: Details['Craze Line'],
      explanation: 'Internal enamel irregular lines',
    },
    {
      details: Details['Exposed Roots Surface'],
      explanation:
        'The root surface, apical to the CEJ, is visible - to be marked on the tooth',
    },
    {
      details: Details['Tooth Gap'],
      explanation: 'Gap between neighboring teeth',
    },
    {
      details: Details['Tooth Wear'],
      explanation:
        'Any tooth wear, including abfraction, abrasion, erosion, attrition, erosion',
    },
    {
      details: Details['Amalgam Tattoo'],
      explanation: 'Amalgam remnants on the soft tissue (usually gray)',
    },
    {
      details: Details['Decalcification / Demineralization'],
      explanation: 'White stain on a tooth surface',
    },
    {
      details: Details['Discoloration'],
      explanation:
        'Internal color change in the tooth (post endodontic treatment for example)',
    },
    {
      details: Details['Exposed Metal on PFM'],
      explanation: 'Metal substructure of a crown is exposed',
    },
    {
      details: Details['Pigmented Pit'],
      explanation: 'Non-carious pigmentation of any pit or fissure ',
    },
    {
      details: Details['Stains'],
      explanation:
        'External color change in the tooth (from beverages, smoking, etc.)',
    },
    {
      details: Details['Thread Uncovering'],
      explanation: 'The implant thread is exposed ',
    },
    {
      details: Details['Calculus'],
      explanation:
        'Calculus on tooth surface. Note: mark once for any tooth surface covered in calculus',
    },
    {
      details: Details['Gum Inflamation'],
      explanation: 'Red, swollen or bloody appearance of the gums',
    },
    {
      details: Details['Plaque'],
      explanation:
        'Plaque on tooth surface. Note: mark once for any tooth surface covered in plaque',
    },
    { details: Details['Aphtha'], explanation: 'An aphta is visible' },
    { details: Details['Fistula'], explanation: 'A fistula or pus is visible' },
    {
      details: Details['Gum Recession'],
      explanation: 'The root surface is visible - to be marked on the gums',
    },
    {
      details: Details['Operculum'],
      explanation: 'An inflamed soft tissue on top of a a tooth',
    },
    {
      details: Details['Dirt on Sleeve'],
      explanation: 'Temporary dirt on the images',
    },
    {
      details: Details['Leukoplakia'],
      explanation: 'Thick, white patches on the inside surfaces of the mouth',
    },
  ],
};
