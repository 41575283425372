import { generateMarkId, getCurrentImagePair } from '../sharedLogic';
import { TYPE_NIRI, PointStatus } from '../../../../shared-logic/enums';
import userActionLogs from '../../../../shared-logic/userActionLogs';
import { findImageIndex, checkIsMarkNew } from '../../shared-logic';
import { isTier1, isTier2 } from '../../../../shared-logic/tiersHelpers';

export const onDeleteImageMark = (prev, currentPairId, currentMarker) => {
  prev.imagePairs.sort((a, b) => a.id - b.id);
  const currentPair = prev.imagePairs.find((pair) => pair.id === currentPairId);
  const currentPairNewMarkings = currentPair[
    currentMarker.type
  ].markings.filter((item) => item.id !== currentMarker.id);
  const index = findImageIndex(prev, currentPairId);
  prev.imagePairs[index][currentMarker.type].markings = currentPairNewMarkings;
  return { ...prev };
};
export const onDeleteImageXRayMark = (prev, currentPairId, currentMarker) => {
  const currentPair = prev.imagePairs.find((pair) => pair.id === currentPairId);
  const currentPairNewMarkings = currentPair.markings.filter(
    (item) => item.id !== currentMarker.id
  );
  const index = findImageIndex(prev, currentPairId);
  if (index !== -1) {
    prev.imagePairs[index].markings = currentPairNewMarkings;
  }
  return { ...prev };
};

const getDefaultImagesData = (
  currentPhotoIndex,
  tier,
  niriBrightness,
  niriContrast,
  colorBrightness,
  colorContrast,
  picture
) => {
  const approved = {
    approvedPrevTier: false,
  };
  return {
    id: currentPhotoIndex,
    imageName: picture.dirName,
    commited: false,
    ...(isTier2(tier) && approved),
    niri: {
      brightness: niriBrightness,
      contrast: niriContrast,
      markings: [],
    },
    color: {
      brightness: colorBrightness,
      contrast: colorContrast,
      markings: [],
    },
  };
};

export const onCommitOrApprovalChange = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState,
  picture
) => {
  const {
    niriBrightness,
    niriContrast,
    colorBrightness,
    colorContrast,
    commit,
    approvedPrevTier,
  } = imagesState;
  const currentPair = getCurrentImagePair(prev, currentPhotoIndex);
  if (!currentPair) {
    const newPair = getDefaultImagesData(
      currentPhotoIndex,
      tier,
      niriBrightness,
      niriContrast,
      colorBrightness,
      colorContrast,
      picture
    );
    return { ...prev, imagePairs: [...prev.imagePairs, newPair] };
  } else {
    currentPair.commited = commit;
    if (isTier2(tier)) {
      currentPair.approvedPrevTier = approvedPrevTier;
    }
    return { ...prev };
  }
};

export const onContrastOrBrightnessChange = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState
) => {
  const { niriBrightness, niriContrast, colorBrightness, colorContrast } =
    imagesState;

  const currentPair = getCurrentImagePair(prev, currentPhotoIndex);
  if (!currentPair) {
    const newPair = getDefaultImagesData(
      currentPhotoIndex,
      tier,
      niriBrightness,
      niriContrast,
      colorBrightness,
      colorContrast
    );
    return { ...prev, imagePairs: [...prev.imagePairs, newPair] };
  } else {
    currentPair.color.brightness = colorBrightness;
    currentPair.color.contrast = colorContrast;
    currentPair.niri.brightness = niriBrightness;
    currentPair.niri.contrast = niriContrast;
    return { ...prev };
  }
};

const findCurrentMarkIndex = (pair, currentType, currentContouring) => {
  return pair[currentType].markings
    ? pair[currentType].markings.findIndex(
        (m) =>
          m.contouring[0].data.position.x ===
            currentContouring[0].data.position.x &&
          m.contouring[0].data.position.y ===
            currentContouring[0].data.position.y
      )
    : -1;
};

export const onNewImageOutput = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState,
  markState,
  rowSelected,
  setCurrentMarker,
  prevMarks,
  missPoints,
  showMissPoints
) => {
  const {
    niriBrightness,
    niriContrast,
    colorBrightness,
    colorContrast,
    approvedPrevTier,
  } = imagesState;
  const {
    currentContouring,
    findings,
    details,
    probability,
    currentType,
    markEdited,
  } = markState;
  userActionLogs.addActionLog(`new mark current index ${currentPhotoIndex}`);

  const newPair = getCurrentImagePair(prev, currentPhotoIndex);
  const currentMarkingIndex = findCurrentMarkIndex(
    newPair,
    currentType,
    currentContouring
  );

  const isMarkNew = checkIsMarkNew(
    currentContouring,
    currentPhotoIndex,
    currentType,
    [
      {
        id: newPair.id,
        color: newPair.color?.markings ?? [],
        niri: newPair.niri?.markings ?? [],
      },
    ]
  );

  if (rowSelected && markEdited && currentMarkingIndex !== -1) {
    const currentMarking = newPair[currentType].markings[currentMarkingIndex];
    const updatedMarking = {
      id: currentMarking.id,
      findings,
      details,
      probability,
      contouring: currentContouring,
      pointStatus: PointStatus.REGULAR,
    };
    newPair[currentType].markings.splice(
      currentMarkingIndex,
      1,
      updatedMarking
    );
  } else {
    if (!isMarkNew) {
      //check to prevent addition of two points instead of one
      return prev;
    }
    const currentPair = prev.imagePairs[currentPhotoIndex];

    const markersData = [];
    if (currentPair?.color.markings) {
      markersData.push(...currentPair.color.markings);
    }
    if (currentPair?.niri.markings) {
      markersData.push(...currentPair.niri.markings);
    }
    showMissPoints &&
      missPoints &&
      missPoints.length &&
      markersData.push(...missPoints);
    const prevMarksMapped = [];
    if (!isTier1(tier)) {
      if (prevMarks[currentPair.id]?.color) {
        prevMarksMapped.push(...prevMarks[currentPair.id].color);
      }
      if (prevMarks[currentPair.id]?.niri) {
        prevMarksMapped.push(...prevMarks[currentPair.id].niri);
      }
    }

    const id = generateMarkId(markersData, prevMarksMapped);
    const newMark = {
      id,
      findings,
      details,
      probability,
      contouring: currentContouring,
      type: currentType,
      //x and y need to be added to follow the model of current marker object
      x: currentContouring[0].data.position.x,
      y: currentContouring[0].data.position.y,
      pointStatus:
        missPoints && missPoints.length ? PointStatus.FIX : PointStatus.REGULAR,
    };
    setCurrentMarker(newMark);

    newPair[currentType].markings = [
      ...newPair[currentType].markings,
      {
        id,
        findings,
        details,
        probability,
        contouring: currentContouring,
        pointStatus:
          showMissPoints && missPoints && missPoints.length
            ? PointStatus.FIX
            : PointStatus.REGULAR,
      },
    ];
  }

  newPair[currentType].contrast =
    currentType === TYPE_NIRI ? niriContrast : colorContrast;
  newPair[currentType].brightness =
    currentType === TYPE_NIRI ? niriBrightness : colorBrightness;
  isTier2(tier) && (newPair.approvedPrevTier = approvedPrevTier);

  return { ...prev };
};
