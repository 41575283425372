import { createSlice } from '@reduxjs/toolkit';
import { TaskLevels, Tasks, TaskTypes } from '../../shared-logic/enums';

export const taskDetailsSlice = createSlice({
  name: 'taskDetails',
  initialState: {
    assignee: '',
    batch: '',
    taskShortNickName: '',
    path: '',
    tier: 1,
    taskLevel: TaskLevels.IMAGE,
    imagesType: TaskTypes._5D,
    completed: 'False',
    recentIndication: null,
    currentTask: Tasks.LOWER,
    labelDetailsTier2: null,
    isDeletedTask: false,
    inTier1: undefined,
    potentiallyDiscarded: undefined,
    reason: undefined,
    currentNiriACsrc: '',
    currentColorACsrc: '',
    currentXRayACsrc: '',
    potentialMissPoint: {},
  },
  reducers: {
    setAssignee: (state, action) => {
      state.assignee = action.payload;
    },
    setBatch: (state, action) => {
      state.batch = action.payload;
    },
    setTaskShortNickName: (state, action) => {
      state.taskShortNickName = action.payload;
    },
    setPath: (state, action) => {
      state.path = action.payload;
    },
    setTier: (state, action) => {
      state.tier = action.payload;
    },
    setTaskLevel: (state, action) => {
      state.taskLevel = action.payload;
    },
    setImagesType: (state, action) => {
      state.imagesType = action.payload;
    },
    setCompleted: (state, action) => {
      switch (action.payload) {
        case true:
          state.completed = 'True';
          break;
        default:
          state.completed = 'False';
      }
    },
    setRecentIndication: (state, action) => {
      state.recentIndication = action.payload;
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload;
    },
    setlabelDetailsTier2: (state, action) => {
      state.labelDetailsTier2 = action.payload;
    },
    setIsDeletedTask: (state, action) => {
      state.isDeletedTask = action.payload;
    },
    setInTier1: (state, action) => {
      state.inTier1 = action.payload;
    },
    setPotentiallyDiscarded: (state, action) => {
      state.potentiallyDiscarded = action.payload;
    },
    setReason: (state, action) => {
      state.reason = action.payload;
    },
    setCurrentNiriACsrc: (state, action) => {
      state.currentNiriACsrc = action.payload;
    },
    setCurrentColorACsrc: (state, action) => {
      state.currentColorACsrc = action.payload;
    },
    setCurrentXRayACsrc: (state, action) => {
      state.currentXRayACsrc = action.payload;
    },
    setPotentialMissPoint: (state, action) => {
      state.potentialMissPoint = action.payload;
    },
  },
});

export const {
  setAssignee,
  setBatch,
  setTaskShortNickName,
  setPath,
  setTier,
  setTaskLevel,
  setImagesType,
  setCompleted,
  setRecentIndication,
  setCurrentTask,
  setlabelDetailsTier2,
  setIsDeletedTask,
  setInTier1,
  setPotentiallyDiscarded,
  setReason,
  setCurrentColorACsrc,
  setCurrentNiriACsrc,
  setCurrentXRayACsrc,
  setPotentialMissPoint,
} = taskDetailsSlice.actions;

export const assigneeSelector = (state) => state.taskDetails.assignee;
export const batchSelector = (state) => state.taskDetails.batch;
export const taskShortNickNameSelector = (state) =>
  state.taskDetails.taskShortNickName;
export const pathSelector = (state) => state.taskDetails.path;
export const taskDetailsSelector = (state) => state.taskDetails;
export const tierSelector = (state) => state.taskDetails.tier;
export const taskLevelSelector = (state) => state.taskDetails.taskLevel;
export const imagesTypeSelector = (state) => state.taskDetails.imagesType;
export const completedSelector = (state) => state.taskDetails.completed;
export const recentIndicationSelector = (state) =>
  state.taskDetails.recentIndication;
export const currentTaskSelector = (state) => state.taskDetails.currentTask;
export const labelDetailsTier2Selector = (state) =>
  state.taskDetails.labelDetailsTier2;
export const isDeletedTaskSelector = (state) => state.taskDetails.isDeletedTask;
export const inTier1Selector = (state) => state.taskDetails.inTier1;
export const potentiallyDiscardedSelector = (state) =>
  state.taskDetails.potentiallyDiscarded;
export const reasonSelector = (state) => state.taskDetails.reason;
export const currentNiriACsrcSelector = (state) =>
  state.taskDetails.currentNiriACsrc;
export const currentColorACsrcSelector = (state) =>
  state.taskDetails.currentColorACsrc;
export const currentXRayACsrcSelector = (state) =>
  state.taskDetails.currentXRayACsrc;
export const potentialMissPointSelector = (state) =>
  state.taskDetails.potentialMissPoint;
export default taskDetailsSlice.reducer;
