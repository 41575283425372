import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _, { cloneDeep } from 'lodash';
import {
  currentMarkerSelector,
  subTypeSelector,
  materialSelector,
  currentToothIndexSelector,
  typeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setMarkEdited,
  toothRightClickSelector,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  useResetToothControls,
  useSetTooth,
  useGetToothMarkers,
  usePreviousValue,
} from '../customHooks';

const useOnToothNavigation = () => {
  const dispatch = useDispatch();
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const getToothMarkers = useGetToothMarkers();
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const prevCurrentToothIndex = usePreviousValue(currentToothIndex);
  const setTooth = useSetTooth();
  const currentMarker = useSelector(currentMarkerSelector);
  const toothRightClick = useSelector(toothRightClickSelector);
  const resetToothControls = useResetToothControls();

  const currentToothMark = (() => {
    const toothMarkers = getToothMarkers();
    return toothMarkers.find(
      (m) => m.type === type && m.subType === subType && m.material === material
    ) || toothMarkers.length
      ? toothMarkers[0]
      : null;
  })();

  const prevCurrentToothMark = usePreviousValue(cloneDeep(currentToothMark));

  useEffect(() => {
    if (
      !_.isEqual(currentToothMark, prevCurrentToothMark) ||
      currentToothIndex !== prevCurrentToothIndex
    ) {
      if (currentToothMark) {
        setTooth(currentToothMark);
      } else {
        if (Object.keys(currentMarker).length > 0 && toothRightClick) {
          dispatch(setMarkEdited(true));
        } else {
          resetToothControls();
        }
      }
    }
  }, [
    currentMarker,
    currentToothIndex,
    currentToothMark,
    dispatch,
    prevCurrentToothIndex,
    prevCurrentToothMark,
    resetToothControls,
    setTooth,
    toothRightClick,
  ]);
};

export default useOnToothNavigation;
