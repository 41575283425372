const Roles = {
  SUPERVISOR: 'supervisor',
  WATCHER: 'watcher',
  TIER2_REVIEWER: 'tier2-reviewer',
  TIER1: 'tier1',
  TIER2: 'tier2',
  TIER3: 'tier3',
};

export default Roles;
