import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import styles from './showMissPointsDialog.module.css';
import modalStyles from '../cancelTaskDialog/cancelTaskDialog.module.css';

const { customUi, dialogBtns, dialogBtn } = modalStyles;

const showMissPointsDialog = (isTraining) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={customUi}>
          <section>
            {!isTraining && (
              <>
                <h3>Please Re-Examine</h3>
                <p>
                  Please go over images that were un-committed (navigate using
                  &gt;&gt; or &lt;&lt;).
                  <br /> In each image examine the areas under the X marks. If
                  you see findings add a mark for it.
                  <br /> Commit the image once you are done with it. Once all
                  images committed “complete case” again.
                </p>
              </>
            )}
            {isTraining && (
              <>
                <h3>System detected labeling errors</h3>
                <p>
                  Images with errors were un-committed. Please go over these
                  images (using &gt;&gt; or &lt;&lt;) and review the red marks.
                  <ul className={styles.ul}>
                    <li>Red X – you missed a mark</li>
                    <li>Red O – your mark was wrong</li>
                    <li>
                      Table of findings will show the missed marks with blue
                      background
                    </li>
                  </ul>
                  If you disagree with any of the found errors, mark those with
                  “NOTHING” so you can review with trainer later.
                  <br />
                  Otherwise commit image as is.
                  <br />
                  Complete case when done with all erroneous images.
                </p>
              </>
            )}
          </section>
          <footer className={dialogBtns}>
            <button
              className={dialogBtn}
              onClick={() => {
                onClose();
              }}
            >
              Ok
            </button>
          </footer>
        </div>
      );
    },
  });
};

export default showMissPointsDialog;
