import React from 'react';
import Modal from 'react-modal';
import styles from './FindingsDetailsDictionary.module.css';
import PropTypes from 'prop-types';
import { Button } from '../../../components';
//images
import dictionaryImage from '../assets/dictionary.jpg';
import XIcon from './assets/x-icon.png';
import SearchIcon from './assets/search-icon.png';

const {
  dictionaryOverlayClass,
  dictionaryModalClass,
  modalHeader,
  modalSubHeader,
  modalHeaderTitle,
  modalBody,
  dictionaryDataSection,
  dictionaryDataSectionItem,
  modalFooter,
  modalCloseButton,
  modalSearchInput,
  modalInputContainer,
  inputIcon,
  pointer,
} = styles;

const FindingsDetailsDictionaryModal = ({
  visible,
  isActive,
  searchValue,
  setSearchValue,
  dictionaryDataDisplay,
  setDictionaryModalState,
  inputRef,
}) => {
  return (
    <>
      {visible ? (
        <Button
          className={styles.dictionaryBtn}
          onClick={() => setDictionaryModalState(true)}
        >
          <img
            src={dictionaryImage}
            alt="dictionary"
            className={styles.dictionaryBtnImage}
          />
        </Button>
      ) : null}

      <Modal
        isOpen={isActive}
        className={dictionaryModalClass}
        overlayClassName={dictionaryOverlayClass}
        contentLabel="Dictionary"
        appElement={document.getElementById('root')}
      >
        <div className={modalHeader}>
          <h2 className={modalHeaderTitle}>Dictionary</h2>
        </div>
        <div className={`${modalHeader} ${modalSubHeader}`}>
          <div className={modalInputContainer}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Search here..."
              className={modalSearchInput}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchValue && searchValue.length > 0 ? (
              <img
                src={XIcon}
                alt="cancel-search"
                className={`${inputIcon} ${pointer}`}
                onClick={() => setSearchValue('')}
              />
            ) : (
              <img src={SearchIcon} alt="search" className={inputIcon} />
            )}
          </div>
        </div>
        <div className={modalBody}>
          {dictionaryDataDisplay &&
            dictionaryDataDisplay.map((item, index) => (
              <section
                key={item.details + index}
                className={dictionaryDataSection}
              >
                <h3 className={dictionaryDataSectionItem}>{item.details}</h3>
                <p className={dictionaryDataSectionItem}>{item.explanation}.</p>
              </section>
            ))}
        </div>
        <div className={modalFooter}>
          <button
            className={modalCloseButton}
            onClick={() => setDictionaryModalState(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

FindingsDetailsDictionaryModal.propTypes = {
  /**
   * function that closes/opens modal
   */
  setDictionaryModalState: PropTypes.func,
  /**
   * boolean determine weather modal is visible or not
   */
  isActive: PropTypes.bool,
  /**
   * modal input search value
   */
  searchValue: PropTypes.string,
  /**
   * modal input search value setter
   */
  setSearchValue: PropTypes.func,
  /**
   * filtered data to display
   */
  dictionaryDataDisplay: PropTypes.array,
  /**
   * input ref
   */
  inputRef: PropTypes.object,
};
export default FindingsDetailsDictionaryModal;
