import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { picturesSelector } from '../../../redux/taskStateImages/outputSlice';
import { selectConfig, selectRole } from '../../../redux/tasks/tasksSlice';
import { isSupervisor } from '../../TasksList/rolesUtil';
import { tierSelector } from '../../../redux/taskState/taskDetailsSlice';
import { useRef } from 'react';
import { useCallback } from 'react';

export const useActivityMonitor = () => {
  const history = useHistory();
  const config = useSelector(selectConfig);
  const role = useSelector(selectRole);
  const tier = useSelector(tierSelector);

  //get number of milliseconds to timeout per user level
  const getMilliseconds = () => {
    const timeoutThresholds = config?.timeout_thresholds;

    if (isSupervisor(role)) {
      return timeoutThresholds.supervisor;
    }
    if (tier === 2) {
      return timeoutThresholds.tier_2_assignee;
    }
    return timeoutThresholds.tier_1_assignee;
  };

  const millisecondsForRedirect = getMilliseconds();

  const redirect = useCallback(() => history.push('/'), [history]);

  const pictures = useSelector(picturesSelector);

  let timeout = useRef();

  const handleAction = useCallback(
    (e) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => redirect(), millisecondsForRedirect);
    },
    [millisecondsForRedirect, redirect]
  );

  useEffect(() => {
    if (pictures.length > 0) {
      document.addEventListener('click', handleAction);
      document.addEventListener('keydown', handleAction);
      document.addEventListener('touchstart', handleAction);
      timeout.current = setTimeout(() => redirect(), millisecondsForRedirect);
      return () => {
        document.removeEventListener('click', handleAction);
        document.removeEventListener('keydown', handleAction);
        document.removeEventListener('touchstart', handleAction);
        clearTimeout(timeout.current);
      };
    }
  }, [handleAction, millisecondsForRedirect, pictures.length, redirect]);
};
