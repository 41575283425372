import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TasksList from './pages/TasksList/TasksList';
import styles from './App.module.css';
import {
  setConfig,
  setVariantGuidance,
  setUserSystem,
} from './redux/tasks/tasksSlice';
import LabelingToolData from './pages/LabelingToolData';
import { API_ENDPOINT } from './config/awsLabelingConfig';
import { get, ipData } from './shared-logic/fetchApi';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

function App() {
  const [error] = useState('');
  const dispatch = useDispatch();

  const getConfiguration = useCallback(async () => {
    try {
      async function fetchAndDispatch(fileName, dispatchAction) {
        try {
          const res = await get(
            `${API_ENDPOINT}/fetchConfig/?fileName=${fileName}`
          );
          if (!res) return;
          const data = res.configFiles.file;
          dispatch(dispatchAction(data));
        } catch (error) {
          console.error(`Error fetching ${fileName}:`, error);
          throw new Error(`Error fetching ${fileName}:`, error);
        }
      }
      await fetchAndDispatch('config.json', setConfig);
      await fetchAndDispatch('VariantGuidance.json', setVariantGuidance);
      await fetchAndDispatch('userSystem.json', setUserSystem);

      // config.debugMode && userActionLogs.setIsEnabled(config.debugMode);
    } catch (error) {
      const ip = await ipData();
      ip &&
        NotificationManager.error(
          `Could not fetch config, please verify you are properly connected to VPN, IP address: ${ip}`,
          'Warning',
          100000
        );
    }
  }, [dispatch]);

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration]);

  return (
    <div className={styles.root}>
      <Switch>
        <Route path="/task">
          <LabelingToolData />
        </Route>
        <Route exact path="/">
          <TasksList error={error} />
        </Route>
      </Switch>
      <NotificationContainer />
    </div>
  );
}

export default App;
