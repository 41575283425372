import React from 'react';
import PolygonsLines from './PolygonsLines/PolygonsLines';
import Rectangles from './Rectangles';
import {
  BOUNDING_BOX,
  LINE,
  POLYGON,
  TYPE_NIRI,
  TYPE_COLOR,
} from '../../../../../shared-logic/enums';
import { getCurrentShapes } from '../../../shared-logic/shapesLogic';

const Shapes = ({
  isNiri,
  hideAreas,
  hideMarks,
  onMarkerCreated,
  currentPair,
}) => {
  const niriRectangles = getCurrentShapes(currentPair, TYPE_NIRI, BOUNDING_BOX);
  const colorRectangles = getCurrentShapes(
    currentPair,
    TYPE_COLOR,
    BOUNDING_BOX
  );

  const niriPolygons = getCurrentShapes(currentPair, TYPE_NIRI, POLYGON);
  const colorPolygons = getCurrentShapes(currentPair, TYPE_COLOR, POLYGON);

  const niriLines = getCurrentShapes(currentPair, TYPE_NIRI, LINE);
  const colorLines = getCurrentShapes(currentPair, TYPE_COLOR, LINE);

  const getShapesData = (shape) => {
    if (shape === POLYGON) {
      return isNiri ? niriPolygons : colorPolygons;
    } else if (shape === LINE) {
      return isNiri ? niriLines : colorLines;
    } else if (shape === BOUNDING_BOX) {
      return isNiri ? niriRectangles : colorRectangles;
    }
  };

  return (
    <>
      {[POLYGON, LINE].map((shape, i) => {
        const shapeData = getShapesData(shape);
        return (
          <PolygonsLines
            shapesData={shapeData}
            isNiri={isNiri}
            shapeName={shape}
            hideAreas={hideAreas}
            hideMarks={hideMarks}
            key={i}
          />
        );
      })}
      {(() => {
        const rectangles = getShapesData(BOUNDING_BOX);
        return (
          rectangles && (
            <Rectangles
              rectangles={rectangles}
              isNiri={isNiri}
              hideAreas={hideAreas}
              hideMarks={hideMarks}
              onMarkerCreated={onMarkerCreated}
            />
          )
        );
      })()}
    </>
  );
};

export default Shapes;
