import { confirmAlert } from 'react-confirm-alert';

const saveDialog = (saveAction) => {
  return confirmAlert({
    title: 'Confirm to submit',
    message: 'cancel current marking and save?',
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          await saveAction();
        },
      },
      {
        label: 'No',
      },
    ],
  });
};

export default saveDialog;
