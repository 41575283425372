import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import modalStyles from '../cancelTaskDialog/cancelTaskDialog.module.css';

const { customUi, dialogBtns, dialogBtn } = modalStyles;

const importTasksDialog = (
  countToVariantList,
  batchesWithoutVariant,
  failItems
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={customUi}>
          <section>
            {Object.keys(countToVariantList).map(
              (variantID) =>
                !isNaN(countToVariantList[variantID].count) && (
                  <p key={variantID}>
                    {countToVariantList[variantID].count.toString()} cases added
                    to {countToVariantList[variantID].variantId}
                  </p>
                )
            )}
            {Object.keys(batchesWithoutVariant).map(
              (variantID) =>
                !isNaN(batchesWithoutVariant[variantID].count) && (
                  <p key={variantID}>
                    {batchesWithoutVariant[variantID].count.toString()} cases of
                    batch {batchesWithoutVariant[variantID].batch}, were not
                    added – please connect:
                    {batchesWithoutVariant[variantID].batch} to the variant
                  </p>
                )
            )}
            {parseInt(failItems) !== 0 && <p>Fail Items:{failItems}</p>}
          </section>
          <footer className={dialogBtns}>
            <button
              className={dialogBtn}
              onClick={() => {
                onClose();
              }}
            >
              Ok
            </button>
          </footer>
        </div>
      );
    },
  });
};

export default importTasksDialog;
