import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
};

export const rotationSlice = createSlice({
  name: 'rotation',
  initialState,
  reducers: {
    setRotation: (state, action) => {
      const calculatedRotation =
        state[action.payload.key] + action.payload.value;
      state[action.payload.key] =
        Math.abs(calculatedRotation) !== 360 ? calculatedRotation : 0;
    },
    setRotationFull: (state, action) => {
      const { rotateX, rotateY, rotateZ } = action.payload;
      state.rotateX = rotateX;
      state.rotateY = rotateY;
      state.rotateZ = rotateZ;
    },
    resetRotation: (state) => {
      state.rotateX = 0;
      state.rotateY = 0;
      state.rotateZ = 0;
    },
  },
});

export const { setRotation } = rotationSlice.actions;
export const { resetRotation } = rotationSlice.actions;
export const { setRotationFull } = rotationSlice.actions;

export const rotationSelector = (state) => {
  const { rotateX, rotateY, rotateZ } = state.rotation;
  return { rotateX, rotateY, rotateZ };
};

export default rotationSlice.reducer;
