const colors = {
  info: 'color:blue',
  debug: 'color:green',
  warn: 'color:yellow',
  error: 'color:red',
};

const _console = console;

const logic = {
  loggerLogic: (
    config,
    { to = {}, data = {}, message = '', severity = 'console' }
  ) => {
    const { module = 'unknown', ...additionalData } = data;
    const { analytics = false, parentWindow = false, console = true } = to;
    const { appId, appVersion } = config;

    analytics &&
      logic.handleAnalytics({
        appId,
        appVersion,
        module,
        message,
        data: additionalData,
      });
    parentWindow &&
      logic.handleParentWindow({
        appId,
        appVersion,
        module,
        message,
        data: additionalData,
      });
    console && logic.handleConsole({ severity, module, message, data });
    !analytics &&
      !parentWindow &&
      !console &&
      logic.handleDefault({ severity, module, message, data });
  },
  handleAnalytics: ({ appId, appVersion, module, message, data }) => {
    if (process.env.NODE_ENV === 'production') {
      if (!Object.entries(data).length) {
        window.gtag('event', message, { event_category: module });
      } else {
        for (const key in data) {
          window.gtag('event', `${message}, [${key}]: ${data[key]}`, {
            event_category: module,
          });
        }
      }
    }
  },
  handleParentWindow: ({ appVersion, module, message, data }) => {
    const msg = `[${appVersion}] (${module}) - ${message} / ${JSON.stringify(
      data
    )}`;
    window.parent.postMessage(msg, '*');
  },
  handleConsole: ({ severity, module, message, data }) => {
    if (
      ['warn', 'error'].includes(severity) ||
      process.env.NODE_ENV !== 'production'
    ) {
      _console.log(
        `%c[${severity}]%c (${module})%c ${message}`,
        colors[severity],
        'color:#444; font-weight:bold',
        'color:#444',
        data
      );
    }
  },
  handleDefault: ({ severity, module, message, data }) => {
    _console.warn(
      `[logger] ~Unknown destination~ [${severity}] (${module}) ${message}`,
      data
    );
  },
  getConfig: () => ({
    appVersion: process.env.APP_VERSION,
    appId: 'web-3d-tool',
  }),
};

export default logic;
