import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupedDropDownList } from '../../../components';
import styles from './Header.module.css';
import classNames from 'classnames';
import { isWatcher, isSupervisor } from '../rolesUtil';

import { ManagementConsole } from '../ManagementConsole';
import { selectAdminRole } from '../../../redux/tasks/tasksSlice';
const roleLabel = 'Role';
const roleWidth = 125;
export const Header = (props) => {
  const [isDataManagmentScreen, setIsDataManagmentScreen] = useState(false);
  const isAdminRole = useSelector(selectAdminRole);

  const onClick = () => {
    setIsDataManagmentScreen(false);
  };
  const linkUrl =
    'https://wiki.aligntech.com/display/RDITGITERO/Data+management+screen';

  return (
    <div className={styles.header}>
      <p className={styles.paragraph}>Welcome: {props.name}</p>
      <GroupedDropDownList
        className={classNames([styles.roleStyle, 'roles'])}
        width={roleWidth}
        items={[
          {
            label: roleLabel,
            content: props.listRoles,
          },
        ]}
        onChange={async (item) => await props.onRoleChange(item)}
        selectedValue={props.role}
      />
      {(isWatcher(props.role) || isSupervisor(props.role)) && (
        <button
          className={classNames([styles.roleStyle, 'roles'])}
          onClick={() => setIsDataManagmentScreen(true)}
        >
          Data Management Screen
        </button>
      )}
      {isDataManagmentScreen && (
        <div className={styles.popup}>
          <div className={styles.popupInner}>
            <button className={styles.closeButton} onClick={onClick}>
              X
            </button>
            <a
              className={styles.linkButton}
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              ?
            </a>
            <div className={styles.popupContent}>
              <ManagementConsole
                variantList={props.variantList}
                isAdminRole={isAdminRole}
                variantsToRolesMapIncludeHidden={
                  props.variantsToRolesMapIncludeHidden
                }
                variantsToRolesMap={props.variantsToRolesMap}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default Header;
