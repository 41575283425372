import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BordersAndLabels from '../BordersAndLabels/BordersAndLabels';
import PinchZoomContainer from '../ImageMarking/PinchZoomContainer/PinchZoomContainer';
import {
  currentColorACsrcSelector,
  currentNiriACsrcSelector,
  currentXRayACsrcSelector,
  tierSelector,
} from '../../../../redux/taskState/taskDetailsSlice';
import styles from './ToothMarking.module.css';
import { currentTaskSelector } from '../../../../redux/taskState/taskDetailsSlice';
import {
  currentPhotoIndexSelector,
  currentToothIndexSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
  setCurrentContouring,
} from '../../../../redux/marks/currentMarkingSlice';
import { setSavedX, setSavedY } from '../../shared-logic';
import { setMarkEdited } from '../../../../redux/labelingTool/labelingToolSlice';
import Markers from '../ImageMarking/Markers';
import {
  outputSelector,
  prevTierStateSelector,
} from '../../../../redux/taskStateImages/outputSlice';
import { TYPE_COLOR, TYPE_NIRI, Tasks } from '../../../../shared-logic/enums';
import {
  calcImageSize,
  calculateSize,
  getRelevantMarkers,
  mouseUpHandler,
} from './ToothMarking.logic';
import {
  BORDER,
  BORDER_PADDING,
  COLOR_GREEN,
  COLOR_BLUE,
  LABEL,
  PADDING_POSITIONING,
} from '../BordersAndLabels/BordersAndLabelsEnum';
import { getDimensionsOfBordersAndTags } from '../ImageFrameManipulation.logic';
import { useOnLoadImage } from '../../customHooks';
import { isXRay } from '../../../../shared-logic/taskLevelsTypesHelper';
import { autoCorrectionSelector } from '../../../../redux/taskState/contrastBrightnessSlice';
import { isEqual } from 'lodash';
const { XRAY } = Tasks;

const numOfTeeth = 49;
const ToothMarking = ({
  width,
  height,
  src,
  brightness,
  contrast,
  rotation,
  retrieveImageHeight,
  calculateImageSize,
  disableMarking,
  isNiri,
  hideMarks,
}) => {
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);
  const { naturalWidth, naturalHeight } = isNiri
    ? imageSizeNiri
    : imageSizeColor;

  const output = useSelector(outputSelector, isEqual);
  const currentTask = useSelector(currentTaskSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const tier = useSelector(tierSelector);
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const prevTierState = useSelector(prevTierStateSelector);
  const onLoadImg = useOnLoadImage({ imageRef, calculateImageSize });

  const runOnce = true;
  const inlineStyle = { width, height };
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageOriginalSize, setImageOriginalSize] = useState({
    width: 0,
    height: 0,
  });
  const [mouseDownPosition, setMouseDownPosition] = useState({
    mouseDownX: null,
    mouseDownY: null,
  });
  const niriOrColor = isNiri ? TYPE_NIRI : TYPE_COLOR;

  const bordersAndTags = () => {
    const data = {};
    data.nodes = [];
    data.links = [];

    const marks = getRelevantMarkers(
      output,
      currentPhotoIndex,
      niriOrColor,
      tier
    );
    const prevMarks = getRelevantMarkers(
      { teeth: prevTierState },
      currentPhotoIndex,
      niriOrColor,
      tier
    );

    const allMarks = prevMarks ? prevMarks.concat(marks) : marks;
    allMarks &&
      allMarks.forEach((mark, i) => {
        const { x, y } = mark;
        const linkID = i + numOfTeeth; //unique ID
        const { borderWidth, borderHeight, positionX, positionY } =
          getDimensionsOfBordersAndTags(
            width,
            height,
            naturalWidth,
            naturalHeight,
            BORDER_PADDING,
            PADDING_POSITIONING,
            x,
            y
          );
        data.nodes.push(
          {
            id: i,
            width: borderWidth,
            height: borderHeight,
            positionX: positionX,
            positionY: positionY,
            group: BORDER,
            color: COLOR_GREEN,
            cluster: i,
          },
          {
            id: linkID,
            x: positionX,
            y: positionY,
            group: LABEL,
            color: COLOR_GREEN,
            text: mark.id?.toString(),
            cluster: i,
          }
        );
        data.links.push({
          source: i,
          target: linkID,
          color: COLOR_GREEN,
          cluster: i,
        });
      });

    return data;
  };
  const initNewMark = ({ offsetX, offsetY }) => {
    const x = setSavedX(offsetX, naturalWidth, width);
    const y = setSavedY(offsetY, naturalHeight, height);
    dispatch(
      setCurrentContouring([
        {
          type: 'point',
          data: {
            position: {
              x: x,
              y: y,
            },
            type: niriOrColor,
          },
        },
      ])
    );
    dispatch(setMarkEdited(true));
  };

  const getMarkersComponent = (
    markers,
    onMarkClick,
    color,
    isSelected = false,
    blink = false
  ) => {
    return (
      <Markers
        markers={markers}
        tier={1}
        onMarkClick={onMarkClick}
        isSelected={isSelected}
        color={color}
        blink={blink}
        showOpenSelectMenu={false}
        imageSize={{ ...inlineStyle, naturalWidth, naturalHeight }}
        hoveredClusterNumber={0}
        labelingImageSize={inlineStyle}
        touchEvent={currentTask === XRAY}
      />
    );
  };

  useEffect(() => {
    let mounted = true;
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;
      if (mounted) {
        setImageOriginalSize({ width, height });
        setLoadingComplete(true);
      }
    };
    img.src = src;
    return () => {
      mounted = false;
    };
  }, [runOnce, src]);

  useEffect(() => {
    calculateSize(height, retrieveImageHeight, imageRef, calculateImageSize);
  }, [calculateImageSize, height, retrieveImageHeight]);

  useEffect(() => {
    if (loadingComplete) {
      const calculatedSize = calcImageSize(
        width,
        height,
        imageOriginalSize.width,
        imageOriginalSize.height
      );
      setImageSize(calculatedSize);
    }
  }, [
    width,
    height,
    loadingComplete,
    imageOriginalSize.width,
    imageOriginalSize.height,
  ]);

  useEffect(() => {
    imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  }, [brightness, contrast]);

  const PrevMarkers = () => {
    return getMarkersComponent(
      getRelevantMarkers(
        { teeth: prevTierState },
        currentPhotoIndex,
        niriOrColor,
        tier
      ),
      null,
      COLOR_BLUE
    );
  };

  const autoCorrectionMode = useSelector(autoCorrectionSelector);
  const currentNiriACsrc = useSelector(currentNiriACsrcSelector);
  const currentColorACsrc = useSelector(currentColorACsrcSelector);
  const currentXRayACsrc = useSelector(currentXRayACsrcSelector);

  return (
    <div
      style={inlineStyle}
      className={styles.container}
      onMouseDown={(e) => {
        setMouseDownPosition({
          mouseDownX: e.clientX,
          mouseDownY: e.clientY,
        });
      }}
      onMouseUp={(e) => {
        mouseUpHandler(
          e,
          mouseDownPosition,
          output,
          disableMarking,
          currentToothIndex,
          initNewMark
        );
      }}
    >
      <PinchZoomContainer
        pinchProps={{
          doubleTapBehavior: 'zoom',
          position: 'center',
          minScale: 1,
          initialScale: 1,
          maxScale: 10,
          zoomButtons: false,
          debug: false,
          rotation: rotation,
          frameWidth: width,
          frameHeight: height,
        }}
        disable={!(currentTask === XRAY)}
      >
        <div>
          <div>
            {!hideMarks &&
              getMarkersComponent(
                getRelevantMarkers(
                  output,
                  currentPhotoIndex,
                  niriOrColor,
                  tier,
                  currentToothIndex
                ),
                null,
                'rgb(253,35,46)',
                true,
                true
              )}
            {!hideMarks &&
              getMarkersComponent(
                getRelevantMarkers(output, currentPhotoIndex, niriOrColor, tier)
              )}
            {!hideMarks && <PrevMarkers />}
            {!hideMarks && (
              <BordersAndLabels
                data={bordersAndTags()}
                width={width}
                height={height}
                className={isNiri ? TYPE_NIRI : TYPE_COLOR}
              />
            )}

            <img
              alt="tooth-labeling-img"
              ref={imageRef}
              src={
                autoCorrectionMode
                  ? isXRay(currentTask)
                    ? currentXRayACsrc || src
                    : isNiri
                    ? currentNiriACsrc || src
                    : currentColorACsrc || src
                  : src
              }
              width={imageSize.width}
              onLoad={onLoadImg}
              height={imageSize.height}
              data-test-id="niriModeImage"
              className={classNames([
                'niriModeImage',
                isNiri ? TYPE_NIRI : TYPE_COLOR,
              ])}
            />
          </div>
        </div>
      </PinchZoomContainer>
    </div>
  );
};

ToothMarking.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100,
  rotation: 0,
};

ToothMarking.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image width
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * The rotation angle of the image
   */
  rotation: PropTypes.number,
  /**
   * Retrieve the actual marking Image Height
   */
  retrieveImageHeight: PropTypes.func,
  /**
   * calculate xray image size
   */
  calculateImageSize: PropTypes.func,
  /**
   * Is marking disabled
   */
  disableMarking: PropTypes.bool,
  /**
   * Is a niri type
   */
  isNiri: PropTypes.bool,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
};

export default React.memo(ToothMarking);
