import { confirmAlert } from 'react-confirm-alert';

const submitEditingDialog = (applyHandler, cancelHandler, nextAction) => {
  return confirmAlert({
    title: 'Submit Editing',
    message: 'Do you want to apply changes to shape or cancel them?',
    buttons: [
      {
        label: 'Apply',
        onClick: () => {
          applyHandler();
          nextAction();
        },
      },
      {
        label: 'Cancel',
        onClick: () => {
          cancelHandler();
          nextAction();
        },
      },
    ],
  });
};

export default submitEditingDialog;
