import { Tier2NavgiationModes } from '../../shared-logic/enums';
import { isTooth } from '../../shared-logic/taskLevelsTypesHelper';
import { isTier2, isTierWithPrevMarks } from '../../shared-logic/tiersHelpers';

const keyboardShortcuts = (tier, taskLevel, shapeInProgress, tier2NavMode) => {
  let shortcuts;
  if (isTooth(taskLevel)) {
    shortcuts = new Map([
      ['Save:', 'Ctrl+S'],
      ['First/Last:', 'Home/End'],
      ['Next/Previous 20:', 'Ctrl+Right/Left'],
      ['NIRI Brightness:', 'Ctrl+🠕/🠗'],
      ['NIRI Contrast:', 'Shift+🠕/🠗'],
      ['Delete Selected Marker:', 'Delete'],
      ['Show Tags:', 'T'],
    ]);
  } else {
    if (shapeInProgress) {
      shortcuts = new Map([
        ['Finish Editing/Adding:', 'Enter'],
        ['Cancel Last Changes:', 'Esc'],
        ['Delete Point of Area:', 'Shift+Click'],
        ['Hide Marks:', 'H'],
        ['Hide Areas:', 'F'],
      ]);
    } else {
      shortcuts = new Map([
        ['Save:', 'Ctrl+S'],
        ['Commit:', 'Enter'],
        ['First/Last:', 'Home/End'],
        [
          isTier2(tier) && tier2NavMode === Tier2NavgiationModes.ALL
            ? 'With Markings:'
            : 'Uncommitted:',
          'Ctrl+Right/Left',
        ],
        ['Next/Previous 20:', 'Alt+Right/Left'],
        ['NIRI Brightness:', 'Ctrl+🠕/🠗'],
        ['NIRI Contrast:', 'Shift+🠕/🠗'],
        ['Delete Selected Marker:', 'Delete'],
        ['Hide Marks:', 'H'],
        ['Hide Areas:', 'F'],
        ['Edit Shape:', 'E'],
      ]);
    }
    isTierWithPrevMarks(tier) && shortcuts.set('Show Tags:', 'T');
  }
  return shortcuts;
};

export default keyboardShortcuts;
