import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { useSelector } from 'react-redux';
import {
  currentTaskSelector,
  taskLevelSelector,
  potentialMissPointSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { outputSelector } from '../../../redux/taskStateImages/outputSlice';
import { currentPhotoIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import { isEqual } from 'lodash';
import { getCurrentImageOrPair, getMarkers } from '../shared-logic';
import {
  TYPE_COLOR,
  TYPE_NIRI,
  PointStatus,
} from '../../../shared-logic/enums';
import { picturesSelector } from '../../../redux/taskStateImages/outputSlice';
import { selectVariant, selectConfig } from '../../../redux/tasks/tasksSlice';
import {
  calculateDistance,
  distanceThresholdPixels,
} from '../../../shared-logic/potentialMissPointLogic';
import { isTrainingMode } from '../../../config/configUtil';

const useMissPoint = () => {
  const taskLevel = useSelector(taskLevelSelector);
  const currentTask = useSelector(currentTaskSelector);
  const isToothLevel = isTooth(taskLevel);
  const output = useSelector(outputSelector, isEqual);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const pictures = useSelector(picturesSelector);
  const variant = useSelector(selectVariant);
  const potentialMissPoint = useSelector(potentialMissPointSelector);
  const config = useSelector(selectConfig);
  if (!potentialMissPoint) return null;
  const currentPair = !isToothLevel
    ? getCurrentImageOrPair(output, currentPhotoIndex)
    : null;

  const niriMarkers = getMarkers(TYPE_NIRI, currentPair);
  const colorMarkers = getMarkers(TYPE_COLOR, currentPair);
  const markers = niriMarkers.concat(colorMarkers);

  function isPointWithinDistance(point1, point2, distance) {
    const calculatedDistance = calculateDistance(point1, point2);

    return (
      calculatedDistance <= distance &&
      (!isTrainingMode(config, variant) ||
        (point1.findings === point2.findings &&
          point1.details === point2.details))
    );
  }

  const oldMarkers = markers.filter((point) => {
    return (
      point.pointStatus === PointStatus.REGULAR ||
      point.pointStatus === PointStatus.MISTAKE
    );
  });
  let id = Math.max(
    oldMarkers?.[oldMarkers?.length - 1]?.id + 1 || 0,
    oldMarkers?.length + 1 || 0
  );
  const index = potentialMissPoint?.[currentTask]?.imagePairs?.findIndex(
    (images) => images.imageName === pictures[currentPhotoIndex].dirName
  );
  if (index === -1) return;
  const niriMarkings =
    potentialMissPoint?.[currentTask]?.imagePairs?.[index]?.[TYPE_NIRI]
      ?.markings || [];
  const colorMarkings =
    potentialMissPoint?.[currentTask]?.imagePairs?.[index]?.[TYPE_COLOR]
      ?.markings || [];

  const missPoints = [];
  const markersWrongUser = [];

  const potentialMissMarkers = [...niriMarkings, ...colorMarkings].map(
    (point) => {
      const mark = {
        x: point?.contouring?.[0]?.data.position.x,
        y: point?.contouring?.[0]?.data.position.y,
        tier: 1,
        details: isTrainingMode(config, variant) ? point?.details : '?',
        findings: isTrainingMode(config, variant) ? point?.findings : '?',
        id: id++,
        missPoint: true,
        pointStatus: PointStatus.POTENTIAL_MISS,
      };
      let pointType = 'niri'; // Default type is 'niri'

      if (colorMarkings.includes(point)) {
        pointType = TYPE_COLOR;
      } else if (niriMarkings.includes(point)) {
        pointType = TYPE_NIRI;
      }

      mark.type = pointType;
      return mark;
    }
  );

  potentialMissMarkers.forEach((point) => {
    if (
      !markers.find((p) =>
        isPointWithinDistance(point, p, distanceThresholdPixels)
      )
    )
      missPoints.push(point);
  });

  markers.forEach((mark) => {
    if (
      !potentialMissMarkers.find((p) =>
        isPointWithinDistance(mark, p, distanceThresholdPixels)
      )
    )
      markersWrongUser.push(mark);
  });

  return {
    missPoints: missPoints?.filter((value) => value !== null),
    markersWrongUser: markersWrongUser,
  };
};
export default useMissPoint;
