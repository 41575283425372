import { post } from '../../../../src/shared-logic/fetchApi';
import { NotificationManager } from 'react-notifications';

const SAVE_DATA = 'saveData';

const useSaveStateSessionStorage = () => {
  const saveAction = async () => {
    //Save data after login in the middle of the post data
    const saveData = JSON.parse(sessionStorage.getItem(SAVE_DATA));
    if (saveData) {
      const { data, url } = saveData ?? {};
      if (data && url) {
        try {
          await post(url, JSON.stringify(data));
          NotificationManager.success('State Successfully saved', 'Success!');
          sessionStorage.removeItem(SAVE_DATA);
        } catch (err) {
          NotificationManager.error(
            'an Error Occured when saving State',
            'Warning'
          );
        }
      }
    }
  };
  return saveAction;
};

export default useSaveStateSessionStorage;
