import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ShapeActionsButtons.module.css';
import { Button } from '../../../components';
import {
  currentContouringSelector,
  detailsSelector,
  setShapeInProgress,
  shapeInProgressSelector,
} from '../../../redux/marks/currentMarkingSlice';
import { NOT_SELECTED } from '../../../shared-logic/params';
import { BOUNDING_BOX, LINE, POLYGON } from '../../../shared-logic/enums';
import {
  ADD,
  BOUNDING_BOX_ACTS,
  DELETE,
  EDIT,
  LINE_ACTS,
  POLYGON_ACTS,
} from './ButtonsText';
import {
  useDeletePrevTierMark,
  useShouldDisableMarking,
  useActionsCurrentShapeData,
} from '../customHooks';

const ShapeActionsButtons = () => {
  const dispatch = useDispatch();
  const currentContouring = useSelector(currentContouringSelector);
  const details = useSelector(detailsSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const disableMarking = useShouldDisableMarking();

  const [showButtons, setShowButtons] = useState(false);
  const [buttons, setButtons] = useState([]);
  const className = styles.button;
  const deletePrevMark = useDeletePrevTierMark();

  const { getCurrentShapeData, removeCurrentShapeData } =
    useActionsCurrentShapeData();

  const shapeActionText = (shape, action) => {
    switch (shape) {
      case BOUNDING_BOX:
        return BOUNDING_BOX_ACTS[action];
      case POLYGON:
        return POLYGON_ACTS[action];
      case LINE:
        return LINE_ACTS[action];
      default:
        return;
    }
  };

  const handleAddOrEdit = useCallback(
    (shape) => {
      dispatch(setShapeInProgress(shape));
    },
    [dispatch]
  );

  const addOrEditButton = useCallback(
    (shape, action) => {
      return (
        <Button
          className={className}
          key={shape + action}
          onClick={() => {
            handleAddOrEdit(shape);
          }}
        >
          {shapeActionText(shape, action)}
        </Button>
      );
    },
    [className, handleAddOrEdit]
  );

  const deleteButton = useCallback(
    (shape) => {
      return (
        <Button
          className={className}
          key={shape + DELETE}
          onClick={() => {
            removeCurrentShapeData(shape);
          }}
        >
          {shapeActionText(shape, DELETE)}
        </Button>
      );
    },
    [className, removeCurrentShapeData]
  );

  useEffect(() => {
    if (details && details !== NOT_SELECTED && currentContouring) {
      let buttons = [];
      [BOUNDING_BOX, POLYGON, LINE].forEach((shape) => {
        const currentShapeData = getCurrentShapeData(shape);
        if (currentShapeData) {
          buttons = [
            ...buttons,
            [addOrEditButton(shape, EDIT), deleteButton(shape)],
          ];
        } else {
          buttons = [...buttons, [addOrEditButton(shape, ADD)]];
        }
      });
      setButtons(buttons);
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [
    addOrEditButton,
    currentContouring,
    deleteButton,
    details,
    getCurrentShapeData,
    shapeInProgress,
  ]);

  useEffect(() => {
    if (shapeInProgress) {
      deletePrevMark();
      setButtons([]);
    }
  }, [shapeInProgress, deletePrevMark]);

  return (
    <div>
      {!disableMarking && showButtons && (
        <div className={styles.buttons}>
          {buttons.map((shapeComponents, index) => (
            <ul key={index} className={styles.list}>
              {shapeComponents.map((component, index) => (
                <li key={index}> {component} </li>
              ))}
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShapeActionsButtons;
