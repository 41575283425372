import React from 'react';
import ToothTierTwoConflictMenuList from './ToothTierTwoConflictMenuList';
import PropTypes from 'prop-types';

const ToothTierTwoConflictMenu = ({
  conflictedTeeth,
  nonConflictedTeeth,
  menuClassName,
}) => {
  return (
    <span className={menuClassName}>
      <ToothTierTwoConflictMenuList
        teethArray={conflictedTeeth}
        isConflictMenu={true}
      />
      <ToothTierTwoConflictMenuList
        teethArray={nonConflictedTeeth}
        isConflictMenu={false}
      />
    </span>
  );
};

ToothTierTwoConflictMenu.propTypes = {
  /**
   * conflictedTeeth - array of conflicted teeth to display
   */
  conflictedTeeth: PropTypes.array,
  /**
   * nonConflictedTeeth -  array of non-conflicted teeth to display
   */
  nonConflictedTeeth: PropTypes.array,
  /**
   * menuClassName - class name of menu
   */
  menuClassName: PropTypes.string,
};

export default ToothTierTwoConflictMenu;
