import React from 'react';
import styles from './JawModel.module.css';
import { isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import { currentTaskSelector } from '../../../redux/taskState/taskDetailsSlice';
import { picturesSelector } from '../../../redux/taskStateImages/outputSlice';
import { currentPhotoIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import { useSelector } from 'react-redux';
import xImage from '../assets/x.png';

const JawModel = () => {
  const currentTask = useSelector(currentTaskSelector);
  const pictures = useSelector(picturesSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);

  const imageFrameSrc = isXRay(currentTask)
    ? xImage
    : `data:image/png;base64, ${pictures[currentPhotoIndex].navigation}`;

  return (
    <div className={styles.jawModelWrapper}>
      <img width={280} alt="" src={imageFrameSrc} />
    </div>
  );
};

export default JawModel;
