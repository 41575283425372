import { createSlice } from '@reduxjs/toolkit';

export const outputSlice = createSlice({
  name: 'output',
  initialState: {
    pictures: [],
    supervisorNotesList: null,
    output: {},
    commit: false,
    isOutputModified: false,
    approvedPrevTier: false,
    prevTierState: null,
  },
  reducers: {
    setOutput: (state, action) => {
      state.output = action.payload;
    },
    setCommit: (state, action) => {
      state.commit = action.payload;
    },
    setIsOutputModified: (state, action) => {
      state.isOutputModified = action.payload;
    },
    setApprovedPrevTier: (state, action) => {
      state.approvedPrevTier = action.payload;
    },
    setPictures: (state, action) => {
      state.pictures = action.payload;
    },
    setSupervisorNotesList: (state, action) => {
      state.supervisorNotesList = action.payload;
    },
    setPrevTierState: (state, action) => {
      state.prevTierState = action.payload;
    },
  },
});

export const {
  setOutput,
  setCommit,
  setIsOutputModified,
  setApprovedPrevTier,
  setPictures,
  setSupervisorNotesList,
  setPrevTierState,
} = outputSlice.actions;

export const outputSelector = (state) => state.output.output;
export const picturesSelector = (state) => state.output.pictures;
export const supervisorNotesListSelector = (state) =>
  state.output.supervisorNotesList;
export const commitSelector = (state) => state.output.commit;
export const isOutputModifiedSelector = (state) =>
  state.output.isOutputModified;
export const approvedPrevTierSelector = (state) =>
  state.output.approvedPrevTier;
export const prevTierStateSelector = (state) => state.output.prevTierState;

export default outputSlice.reducer;
