export const Findings = {
  'Not Selected': 'Not Selected',
  'Filling / FS': 'Filling / FS',
  Caries: 'Caries',
  'Add-On': 'Add-On',
  General: 'General',
  'Tooth Anatomy': 'Tooth Anatomy',
  'Color Change': 'Color Change',
  'Oral Hygiene': 'Oral Hygiene',
  'Soft Tissue': 'Soft Tissue',
  NOTHING: 'NOTHING',
  'Caries+Conf': 'Caries+Conf',
};
