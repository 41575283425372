import React, { useState, useEffect } from 'react';
import styles from './CampaignConsole.module.css';
import { API_ENDPOINT } from '../../../config/awsLabelingConfig';
import { postData } from '../../LabelingToolPage/LabelingToolPage.logic';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { selectUsersList } from '../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import tasksListLogic from '../TasksList.logic';
import {
  rolesList,
  FALSE,
  TRUE,
  tier2NavigationModeOptions,
  assistedWorkList,
} from './ManagementConsoleUtil';

const CampaignConsole = () => {
  const usersList = useSelector(selectUsersList);

  const [selectedUser, setSelectedUser] = useState(null);
  const [usersAndRoles, setUsersAndRoles] = useState({});
  const [currentRole, setCurrentRole] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [batchesList, setBatchesList] = useState([]);

  const [findingsList, setFindingsList] = useState([]);
  const [selectedFindings, setSelectedFindings] = useState([]);

  const [hidden, setHidden] = useState(FALSE);
  const [numberOfTier1Labelers, setNumberOfTier1Labelers] = useState(4);
  const [tier2NavigationMode, setTier2NavigationMode] = useState(
    tier2NavigationModeOptions[0]
  );
  const [assistedWork, setAssistedWork] = useState(assistedWorkList[0]);
  const [aiAsAdditionalTier1, setAiAsAdditionalTier1] = useState(FALSE);
  const [disableAutoCorrection, setDisableAutoCorrection] = useState(FALSE);

  const fetchData = async () => {
    try {
      setBatchesList(await tasksListLogic.getListOfBatches());
      setFindingsList(await tasksListLogic.getListOfFindings());
    } catch (error) {
      setBatchesList([]);
      setFindingsList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedUsers = Object.fromEntries(
      Object.entries(usersAndRoles).filter(([key, value]) => value.length > 0)
    );

    if (!selectedFindings.length) {
      NotificationManager.error(
        `Can't add campaign without findings.`,
        'Warning'
      );
      return;
    }
    const newCampaign = {
      campaignName,
      usersAndRoles: selectedUsers,
      batches: selectedBatches,
      hidden,
      numberOfTier1Labelers,
      tier2NavigationMode,
      assistedWork,
      aiAsAdditionalTier1,
      disableAutoCorrection,
      findings: selectedFindings,
    };

    try {
      await postData(`${API_ENDPOINT}/addCampaign`, newCampaign);
      setCampaignName('');
      setCurrentRole(null);
      setUsersAndRoles({});
      setSelectedUser(null);
      setHidden(FALSE);
      setNumberOfTier1Labelers(4);
      setTier2NavigationMode(tier2NavigationModeOptions[0]);
      setAssistedWork(assistedWorkList[0]);
      setAiAsAdditionalTier1(FALSE);
      setDisableAutoCorrection(FALSE);
      setFindingsList([]);
      fetchData();
      NotificationManager.success(
        'The campaign was successfully added!',
        'Success'
      );
    } catch (error) {
      NotificationManager.error(
        `An error occurred while adding the campaign: \n  ${error}. \n  Please try again later.`,
        'Warning'
      );
    }
  };

  const handleUserSelection = (userId) => {
    usersAndRoles[userId] = usersAndRoles[userId] || [];
    setUsersAndRoles(usersAndRoles);
    setSelectedUser(userId);
    setCurrentRole(null);
  };

  const handleRoleSelection = (role) => {
    usersAndRoles[selectedUser] = usersAndRoles[selectedUser].includes(role)
      ? usersAndRoles[selectedUser].filter((item) => item !== role)
      : [...usersAndRoles[selectedUser], role];
    setCurrentRole(usersAndRoles[selectedUser]);
    setUsersAndRoles(usersAndRoles);
  };

  const handleUserSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredUsers = usersList.filter((user) =>
    user.UserId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggleFinding = (findingName) => {
    if (selectedFindings.includes(findingName)) {
      setSelectedFindings(
        selectedFindings.filter((name) => name !== findingName)
      );
    } else {
      setSelectedFindings([...selectedFindings, findingName]);
    }
  };

  const handleToggleBatches = (batchId) => {
    if (selectedBatches.includes(batchId)) {
      setSelectedBatches(selectedBatches.filter((name) => name !== batchId));
    } else {
      setSelectedBatches([...selectedBatches, batchId]);
    }
  };
  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit}>
        <label>Campaign Name:</label>
        <input
          type="text"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          required
        />
        <br />
        <h4>Selected User:</h4>
        <input
          type="text"
          placeholder="Search users"
          onChange={(e) => handleUserSearch(e.target.value)}
        />
        <ul>
          {filteredUsers.map((user) => (
            <li key={user.UserId}>
              <input
                type="checkbox"
                checked={selectedUser === user.UserId}
                onChange={() => handleUserSelection(user.UserId)}
              />
              <label>{user.UserId}</label>
            </li>
          ))}
        </ul>
        {selectedUser && (
          <>
            <h4>Roles:</h4>
            <ul>
              {rolesList.map((role) => (
                <li key={role}>
                  <input
                    type="checkbox"
                    onChange={() => handleRoleSelection(role)}
                    checked={
                      usersAndRoles?.[selectedUser]?.includes(role) ||
                      role === currentRole
                    }
                  />
                  <label>{role}</label>
                </li>
              ))}
            </ul>
          </>
        )}
        <h4>Findings:</h4>
        <ul>
          {findingsList &&
            findingsList.map((finding) => (
              <li key={finding.FindingName}>
                <input
                  type="checkbox"
                  checked={selectedFindings.includes(finding.FindingName)}
                  onChange={() => handleToggleFinding(finding.FindingName)}
                />
                <label>{finding.FindingName}</label>
              </li>
            ))}
        </ul>
        <h4>Batches:</h4>
        <ul>
          {batchesList &&
            batchesList.map((batch) => (
              <li key={batch.BatchId}>
                <input
                  type="checkbox"
                  checked={selectedBatches.includes(batch.BatchId)}
                  onChange={() => handleToggleBatches(batch.BatchId)}
                />
                <label>{batch.BatchName}</label>
              </li>
            ))}
        </ul>

        <h4>Campaign Configurations:</h4>
        <br />

        <label htmlFor="hidden">Hidden:</label>
        <select
          id="hidden"
          value={hidden}
          onChange={(e) => setHidden(e.target.value)}
        >
          <option value={FALSE}>FALSE</option>
          <option value={TRUE}>TRUE</option>
        </select>
        <br />

        <label htmlFor="numberOfTier1Labelers">Number of Tier1 labelers:</label>
        <input
          id="numberOfTier1Labelers"
          type="number"
          value={numberOfTier1Labelers}
          onChange={(e) => setNumberOfTier1Labelers(e.target.value)}
          min={1}
          max={10}
        />
        <br />

        <label htmlFor="disableAutoCorrection">Disable Auto Correction:</label>
        <select
          id="disableAutoCorrection"
          value={disableAutoCorrection}
          onChange={(e) => setDisableAutoCorrection(e.target.value)}
        >
          <option value={FALSE}>FALSE</option>
          <option value={TRUE}>TRUE</option>
        </select>
        <br />

        <label htmlFor="tier2NavigationMode">Tier2 Navigation Mode:</label>
        <select
          id="tier2NavigationMode"
          value={tier2NavigationMode}
          onChange={(e) => setTier2NavigationMode(e.target.value)}
        >
          {tier2NavigationModeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor="assistedWork">Assisted Work:</label>
        <select
          id="assistedWork"
          value={assistedWork}
          onChange={(e) => setAssistedWork(e.target.value)}
        >
          {assistedWorkList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <br />

        <label htmlFor="aiAsAdditionalTier1">AI as additional Tier1:</label>
        <select
          id="aiAsAdditionalTier1"
          value={aiAsAdditionalTier1}
          onChange={(e) => setAiAsAdditionalTier1(e.target.value)}
        >
          <option value={FALSE}>FALSE</option>
          <option value={TRUE}>TRUE</option>
        </select>
        <br />
        <button type="submit">Add Campaign</button>
      </form>
    </div>
  );
};
CampaignConsole.propTypes = {
  campaignList: PropTypes.object,
};

export default CampaignConsole;
