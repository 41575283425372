import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PolygonLine from './PolygonLine';
import {
  currentMarkerSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
  setCurrentShapeData,
  setShapeInProgress,
  shapeInProgressSelector,
} from '../../../../../../../redux/marks/currentMarkingSlice';
import { useSaveShapeData } from '../../../../../customHooks';
import { TYPE_COLOR, TYPE_NIRI } from '../../../../../../../shared-logic/enums';

const PolygonLineContainer = ({
  points,
  isSelectedShape,
  isNiri,
  shapeName,
  hideAreas,
  hideMarks,
}) => {
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);

  const currentMarker = useSelector(currentMarkerSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const dispatch = useDispatch();

  const { saveShapeData } = useSaveShapeData();

  const isRenderTypeMatchMarkerType =
    (isNiri && currentMarker.type === TYPE_NIRI) ||
    (!isNiri && currentMarker.type === TYPE_COLOR);

  return (
    <div>
      {(isRenderTypeMatchMarkerType || points) && (
        <PolygonLine
          pointsArray={points}
          imageSize={isNiri ? imageSizeNiri : imageSizeColor}
          shapeInProgress={shapeInProgress}
          saveShapeData={saveShapeData}
          setShapeInProgress={(v) => dispatch(setShapeInProgress(v))}
          setCurrentShapeData={(v) => {
            dispatch(setCurrentShapeData(v));
          }}
          isSelectedShape={isSelectedShape}
          shapeName={shapeName}
          isRenderTypeMatchMarkerType={isRenderTypeMatchMarkerType}
          hideAreas={hideAreas}
          hideMarks={hideMarks}
        />
      )}
    </div>
  );
};

PolygonLineContainer.propTypes = {
  /**
   * points position array
   **/
  points: PropTypes.array,
  /**
   * When `true`, points render as selected
   */
  isSelectedShape: PropTypes.bool,
  /**
   * was a niri or color type
   */
  isNiri: PropTypes.bool,
  /**
   * shape name to render
   */
  shapeName: PropTypes.string,
  /**
   * Should hide areas
   */
  hideAreas: PropTypes.bool,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
};

export default PolygonLineContainer;
