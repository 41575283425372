import { cloneDeep, isEqual } from 'lodash';
import { findImageIndex } from './imageLogic';
import { TYPE_COLOR, TYPE_NIRI, TYPE_XRAY } from '../../../shared-logic/enums';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
const {
  TypesSubTypesMaterials,
} = require('../../LabelingToolPage/ToothLabeling/TypesSubTypesMaterials');

export const checkIsMarkNew = (
  currentContouring,
  currentPhotoIndex,
  currentType,
  prevTierState,
  toothMarkDetails = {}
) => {
  const prevTierMarks = cloneDeep(prevTierState);
  const index = findImageIndex(prevTierMarks, currentPhotoIndex);
  const currentMarkPosition = !isTooth(currentType)
    ? currentContouring[0].data.position
    : null;
  let allPrevMarksPositions = [];
  if (currentType === TYPE_XRAY) {
    allPrevMarksPositions = prevTierMarks[index]
      ? prevTierMarks[index].markings.map(
          (point) => point.contouring[0]?.data.position
        )
      : [];
  } else if ([TYPE_NIRI, TYPE_COLOR].includes(currentType)) {
    allPrevMarksPositions = prevTierMarks[index]
      ? prevTierMarks[index][currentType].map(
          (point) => point.contouring[0]?.data.position
        )
      : [];
  } else if (isTooth(currentType)) {
    allPrevMarksPositions = prevTierMarks[0]
      ? prevTierMarks[0]?.markings.map(
          ({ material, probability, subType, type, comments }) => ({
            material,
            probability,
            subType,
            type,
            comments,
          })
        )
      : [];

    if (
      (TypesSubTypesMaterials[toothMarkDetails.type]['Sub Type'].length > 1 &&
        toothMarkDetails.subType === 'Not Selected') ||
      (TypesSubTypesMaterials[toothMarkDetails.type]['Material'].length > 1 &&
        toothMarkDetails.material === 'Not Selected')
    )
      return false;

    const isNewMark = !!!allPrevMarksPositions.filter(
      (mark) =>
        mark.type === toothMarkDetails.type &&
        mark.subType === toothMarkDetails.subType &&
        mark.Material === toothMarkDetails.Material
    ).length;
    return isNewMark;
  }

  const isNewMark = !!!allPrevMarksPositions.filter((point) =>
    isEqual(point, currentMarkPosition)
  ).length;

  return isNewMark;
};
