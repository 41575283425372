export const TYPE_NIRI = 'niri';
export const TYPE_COLOR = 'color';
export const TYPE_XRAY = 'xray';
export const CONSISTENT = 'consistent';
export const INCONSISTENT = 'inconsistent';
export const MARKINGS = 'markings';

const IMAGE_TYPES = {
  TYPE_NIRI,
  TYPE_COLOR,
  TYPE_XRAY,
  CONSISTENT,
  INCONSISTENT,
  MARKINGS,
};

export default IMAGE_TYPES;
