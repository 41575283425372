import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  rowSelectedSelector,
  setLabelingDisabled,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  commentsSelector,
  currentContouringSelector,
  currentTypeSelector,
  detailsSelector,
  findingsSelector,
  materialSelector,
  setComments,
  setCurrentContouring,
  setCurrentMarker,
  setCurrentToothIndex,
  setCurrentType,
  setDetails,
  setFindings,
  setMaterial,
  setPrevPhotoIndex,
  setShapeInProgress,
  setSubType,
  setType,
  shapeInProgressSelector,
  subTypeSelector,
  typeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  currentTaskSelector,
  setCurrentTask,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  setCommit,
  setOutput,
  setPictures,
  setPrevTierState,
  setSupervisorNotesList,
} from '../../../redux/taskStateImages/outputSlice';
import {
  lowerImagesSelector,
  lowerNotesSelector,
  lowerPrevTierStateSelector,
  lowerStateSelector,
} from '../../../redux/taskStateImages/stateImagesLowerSlice';
import {
  upperImagesSelector,
  upperStateSelector,
  upperPrevTierStateSelector,
  upperNotesSelector,
} from '../../../redux/taskStateImages/stateImagesUpperSlice';
import {
  xRayImagesSelector,
  xRayNotesSelector,
  xRayPrevStateSelector,
  xRayStateSelector,
} from '../../../redux/taskStateImages/stateImagesXRaySlice';
import TaskButtons from './TaskButtons';
import { inProgressDialog } from '../Dialogs';
import {
  isFDI,
  LOWER_FDI_FIRST_TOOTH,
  LOWER_UNIVERSAL_FIRST_TOOTH,
  UPPER_FDI_FIRST_TOOTH,
  UPPER_UNIVERSAL_FIRST_TOOTH,
} from '../JawNavigation/teeth/teeth';
import { Tasks } from '../../../shared-logic/enums';
import { isEmptyToothMark, isValidToothIndication } from '../shared-logic';
import { NOT_SELECTED } from '../../../shared-logic/params';
import {
  setBrightness,
  setColorBrightness,
  setColorContrast,
  setContrast,
  setNiriBrightness,
  setNiriContrast,
} from '../../../redux/taskState/contrastBrightnessSlice';

import { resetRotation } from '../../../redux/taskState/rotationSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import {
  isImage,
  isTooth,
  isUpper,
  isXRay,
} from '../../../shared-logic/taskLevelsTypesHelper';
import submitEditingDialog from '../Dialogs/submitEditingDialog';
import { useSaveShapeData } from '../customHooks';
import {
  isTier1,
  isTierWithPrevMarks,
} from '../../../shared-logic/tiersHelpers';

const TaskButtonsContainer = () => {
  const dispatch = useDispatch();
  const lowerState = useSelector(lowerStateSelector);
  const lowerImages = useSelector(lowerImagesSelector);
  const upperState = useSelector(upperStateSelector);
  const upperImages = useSelector(upperImagesSelector);
  const xRayState = useSelector(xRayStateSelector);
  const xRayImages = useSelector(xRayImagesSelector);
  const currentTask = useSelector(currentTaskSelector);

  const details = useSelector(detailsSelector);
  const findings = useSelector(findingsSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const comments = useSelector(commentsSelector);
  const tier = useSelector(tierSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const [selectedTask, setSelectedTask] = useState(currentTask);

  const lowerPrevState = useSelector(lowerPrevTierStateSelector);
  const upperPrevState = useSelector(upperPrevTierStateSelector);

  const xRayPrevState = useSelector(xRayPrevStateSelector);
  const lowerPrevTierState = useSelector(lowerPrevTierStateSelector);
  const upperPrevTierState = useSelector(upperPrevTierStateSelector);

  const lowerNotes = useSelector(lowerNotesSelector);
  const upperNotes = useSelector(upperNotesSelector);
  const xRayNotes = useSelector(xRayNotesSelector);

  const { saveShapeData } = useSaveShapeData();

  useEffect(() => {
    setSelectedTask(currentTask);
  }, [currentTask]);

  const resetToothControls = (task) => {
    dispatch(setType(NOT_SELECTED));
    dispatch(setSubType(NOT_SELECTED));
    dispatch(setMaterial(NOT_SELECTED));
    dispatch(setComments(''));
    const toothIndex = isUpper(task)
      ? isFDI
        ? UPPER_FDI_FIRST_TOOTH
        : UPPER_UNIVERSAL_FIRST_TOOTH
      : isFDI
      ? LOWER_FDI_FIRST_TOOTH
      : LOWER_UNIVERSAL_FIRST_TOOTH;
    userActionLogs.addActionLog(
      `toothIndex is set to ${toothIndex} on entering new task`
    );
    dispatch(setCurrentToothIndex(toothIndex));
  };

  const resetImageControls = () => {
    dispatch(setCurrentType(null));
    dispatch(setFindings(NOT_SELECTED));
    dispatch(setDetails(NOT_SELECTED));
  };

  const resetControls = (task) => {
    dispatch(setCurrentContouring(null));
    dispatch(setCurrentMarker({}));
    if (isImage(taskLevel)) {
      resetImageControls();
    } else if (isTooth(taskLevel)) {
      resetToothControls(task);
    }
    dispatch(setLabelingDisabled(true));
    dispatch(setPrevPhotoIndex(null));
    dispatch(setSelectedTableRowId(-1));
    dispatch(setContrast(100));
    dispatch(resetRotation());
    dispatch(setBrightness(100));
    dispatch(setNiriBrightness(100));
    dispatch(setColorBrightness(100));
    dispatch(setNiriContrast(100));
    dispatch(setColorContrast(100));
  };

  const canNavigate = () => {
    var res =
      ((isImage(taskLevel) || (isXRay(currentTask) && isImage(taskLevel))) &&
        (findings === NOT_SELECTED || details === NOT_SELECTED) &&
        currentType !== null &&
        currentType !== null &&
        currentContouring !== null) ||
      (isTooth(taskLevel) &&
        !isValidToothIndication(type, subType, material) &&
        !isEmptyToothMark(type, subType, material, comments));
    return res;
  };

  const handleNewTaskSelection = (task) => {
    if (canNavigate()) {
      inProgressDialog(
        rowSelected,
        () => navigateToTask(task),
        (v) => dispatch(setSelectedTableRowId(v))
      );
    } else if (shapeInProgress) {
      submitEditingDialog(
        () => saveShapeData(shapeInProgress),
        () => dispatch(setShapeInProgress(null)),
        () => navigateToTask(task)
      );
    } else {
      navigateToTask(task);
    }
  };

  const navigateToTask = (task) => {
    setSelectedTask(task);
    let commit;
    switch (task) {
      case Tasks.LOWER:
        dispatch(setPictures(lowerImages));
        dispatch(setOutput(lowerState));
        commit =
          lowerState.imagePairs && lowerState.imagePairs[0].commited
            ? lowerState.imagePairs[0].commited
            : false;
        userActionLogs.addActionLog(
          `commit set to ${commit} because of task change to ${task}, lower images is set length ${lowerImages.length}`
        );
        dispatch(setCommit(commit));
        dispatch(setSupervisorNotesList(lowerNotes || []));

        if (isTierWithPrevMarks(tier)) {
          dispatch(setPrevTierState(lowerPrevState));
        }
        break;
      case Tasks.UPPER:
        dispatch(setPictures(upperImages));
        dispatch(setOutput(upperState));
        commit =
          upperState.imagePairs && upperState.imagePairs[0].commited
            ? upperState.imagePairs[0].commited
            : false;
        userActionLogs.addActionLog(
          `commit set to ${commit} because of task change to ${task}, upper images is set length ${upperImages.length}`
        );
        dispatch(setCommit(commit));
        dispatch(setSupervisorNotesList(upperNotes || []));

        if (isTierWithPrevMarks(tier)) {
          dispatch(setPrevTierState(upperPrevState));
        }
        break;
      case Tasks.XRAY:
        dispatch(setPictures(xRayImages));
        dispatch(setOutput(xRayState));
        commit =
          xRayState.imagePairs && xRayState.imagePairs[0].commited
            ? xRayState.imagePairs[0].commited
            : false;
        userActionLogs.addActionLog(
          `commit set to ${commit} because of task change to ${task}, x-ray images is set length ${xRayImages.length}`
        );
        dispatch(setCommit(commit));
        dispatch(setSupervisorNotesList(xRayNotes));
        break;
      default:
        return;
    }
    resetControls(task);
    userActionLogs.addActionLog(
      `current task set to ${task} because of task change via task buttons`
    );
    dispatch(setCurrentTask(task));
  };

  const shouldDisable = (state, images) => {
    return Object.keys(state).length === 0 || !images || images.length === 0;
  };

  const isConflictedSubTask = (state) => {
    if (isTier1(tier)) return true;
    if (state) {
      const data = isTooth(taskLevel)
        ? state.teeth
          ? state.teeth
          : []
        : state;
      return data.find((i) => !i.consistent) ? true : false;
    }
    return true;
  };

  const disableLower = shouldDisable(lowerState, lowerImages);
  const disableUpper = shouldDisable(upperState, upperImages);
  const disableXRay = shouldDisable(xRayState, xRayImages);

  const conflictLower = isConflictedSubTask(lowerPrevTierState);
  const conflictUpper = isConflictedSubTask(upperPrevTierState);
  const conflictXRay = isConflictedSubTask(xRayPrevState);

  return (
    <TaskButtons
      disableLower={disableLower}
      disableUpper={disableUpper}
      disableXRay={disableXRay}
      conflictLower={conflictLower}
      conflictUpper={conflictUpper}
      conflictXRay={conflictXRay}
      onTaskSelection={handleNewTaskSelection}
      selectedTask={selectedTask}
    />
  );
};

export default TaskButtonsContainer;
