import Roles from './rolesEnum';

export const isLabeler = (role) => role.includes('tier');
export const isSupervisor = (role) => role === Roles.SUPERVISOR;
export const isWatcher = (role) => role === Roles.WATCHER;
export const isTier2Reviewer = (role) => role === Roles.TIER2_REVIEWER;

export const isTier1Labeler = (role, labeler) => role === Roles.TIER_1;
export const isTier2Labeler = (role, labeler) => role === Roles.TIER_2;
export const isTier3Labeler = (role, labeler) => role === Roles.TIER_3;
