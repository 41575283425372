import React, { useState, useEffect, useRef, useCallback } from 'react';
import { dictionaryImage } from './FindingsDetailsDictionaryData.js';
import { dictionaryTooth } from './FindingsDetailsDictionaryToothData.js';
import FindingsDetailsDictionaryModal from './FindingsDetailsDictionaryModal';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { useSelector } from 'react-redux';
import { taskLevelSelector } from '../../../redux/taskState/taskDetailsSlice';

const FindingsDetailsDictionaryModalContainer = () => {
  const taskLevel = useSelector(taskLevelSelector);
  const isToothLevel = isTooth(taskLevel);
  const { DictionaryData } = isToothLevel ? dictionaryTooth : dictionaryImage;

  const [dictionaryDataDisplay, setDictionaryDataDisplay] =
    useState(DictionaryData);
  const [searchValue, setSearchValue] = useState('');
  const [dictionaryModalState, setDictionaryModalState] = useState(false);
  const inputRef = useRef();

  const checkWhetherValueIsLetterOrNumber = (expression) =>
    /[a-zA-Z0-9-_ ]/.test(expression);

  const keyDownHandler = useCallback((e) => {
    const value = String.fromCharCode(e.keyCode);
    if (checkWhetherValueIsLetterOrNumber(value)) {
      inputRef.current.focus();
    }
    if (e.keyCode === 27) {
      setSearchValue('');
    }
  }, []);

  useEffect(() => {
    if (!dictionaryModalState) {
      return;
    }
    document.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    };
  }, [dictionaryModalState, keyDownHandler]);

  useEffect(() => {
    if (searchValue) {
      const lowerCasedSearch = searchValue.toLowerCase();
      setDictionaryDataDisplay(() =>
        DictionaryData.filter((item) =>
          item.details.toLowerCase().includes(lowerCasedSearch)
        )
      );
    } else {
      setDictionaryDataDisplay(DictionaryData);
    }
  }, [DictionaryData, searchValue]);

  return (
    <FindingsDetailsDictionaryModal
      visible={dictionaryDataDisplay.length > 0}
      isActive={dictionaryModalState}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      dictionaryDataDisplay={dictionaryDataDisplay}
      setDictionaryModalState={setDictionaryModalState}
      inputRef={inputRef}
    />
  );
};

export default FindingsDetailsDictionaryModalContainer;
