import { isTierWithPrevMarks } from '../../../shared-logic/tiersHelpers';
import { isMissingTooth } from './toothLogic';

const NUM_JAW_TEETH = 16;

export const getSummary = (
  isImageLevel,
  output,
  picturesCount,
  tier,
  conflictedTeeth
) => {
  const committed = isImageLevel
    ? getNumCommitted(output)
    : getNumToothIdentified(output, tier, conflictedTeeth);
  const total = isImageLevel ? picturesCount : NUM_JAW_TEETH;
  const summary = {
    committed_images: committed,
    total_case_images: total,
  };
  return summary;
};

function getNumCommitted(output) {
  if (Object.keys(output).length === 0) return 0;
  return output.imagePairs.filter((x) => x.commited === true).length;
}
function isPointExists(tooth) {
  return !isMissingTooth(tooth) ? tooth.point?.position : true;
}

function getNumToothIdentified(output, tier, conflictedTeeth) {
  if (isTierWithPrevMarks(tier)) return NUM_JAW_TEETH - conflictedTeeth;
  return (
    output.teeth &&
    output.teeth.filter((x) => x.markings.length > 0 && isPointExists(x)).length
  );
}
