import { isNG, isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import { setX, setY } from '../shared-logic';

export const IMAGE_CONTAINER_WIDTH = 1267;
export const IMAGE_CONTAINER_HEIGHT = 800;
export const CONTRAST_MIN_VALUE = 0;
export const CONTRAST_MAX_VALUE = 200;
export const CONTRAST_DEFAULT_VALUE = 0;
export const BRIGHTNESS_DEFAULT_VALUE = 100;
export const ROTATION_MENU_HEIGHT = 50;
export const LEFT_TOP_SELECTION = 20;
export const PAGE_PADDING = 15;
export const MARK_RADIUS_IN_PIXELS = 5;
export const FLEX_OFFSET_INCLUDE_MARK_RADIUS = 40;
export const IMAGE_5D_WIDTH = 600;
export const IMAGE_5D_HEIGHT = 700;
// export const NG_WIDTH = 0;
export const NG_HEIGHT = 540;
export const XRAY_WIDTH = 1267;
// export const XRAY_HEIGHT = 0;
export const OPEN_SELECT_WIDTH = 220;

const isInRightSize = (x, width) => {
  return x <= width / 2;
};

const calcRegular = (x, y, ratio) => {
  x *= ratio;
  const top = y * ratio;
  const left = isInRightSize(x, IMAGE_5D_WIDTH)
    ? x + LEFT_TOP_SELECTION
    : x - OPEN_SELECT_WIDTH - LEFT_TOP_SELECTION;
  return { top, left };
};

export const removeLetters = (str) => str.replace(/[^\d.]/g, '');

export const extractValueFromString = (string, valueToExtract) => {
  if (!string || !valueToExtract) {
    return 1;
  }

  const scaleIndexInStyleString = string.indexOf(valueToExtract);
  let result = string.substring(scaleIndexInStyleString);
  result = result.substring(0, result.indexOf(')'));
  return Number(removeLetters(result));
};

export const calculateSelectPositionForXray = (
  labelingImageTransformValue,
  x,
  y,
  setStateCallback
) => {
  /**
   * TODO
   * Calculte the best postion for x-ray
   */
  // const scaleValue = extractValueFromString(
  //   labelingImageTransformValue,
  //   'scale'
  // );
  // const top =
  //   y -
  //   FLEX_OFFSET_INCLUDE_MARK_RADIUS -
  //   ROTATION_MENU_HEIGHT -
  //   PAGE_PADDING +
  //   (MARK_RADIUS_IN_PIXELS * scaleValue) / 2;
  // const left = x + MARK_RADIUS_IN_PIXELS * scaleValue;
  const top = 0;
  const left = XRAY_WIDTH - OPEN_SELECT_WIDTH + PAGE_PADDING;
  setStateCallback({ top, left });
};

const calcNG = (x, y, ratio) => {
  const top = x * ratio;
  y *= ratio;
  let left = NG_HEIGHT - y;
  if (isInRightSize(y, NG_HEIGHT)) {
    left -= LEFT_TOP_SELECTION + OPEN_SELECT_WIDTH;
  } else {
    left += LEFT_TOP_SELECTION;
  }
  return { top, left };
};

export const calculateSelectPositionForImagePairs = (imagesType) => {
  return isNG(imagesType) ? calcNG : calcRegular;
};

export const getWidth = (imagesType, currentTask, naturalWidth, ratio) => {
  if (isNG(imagesType)) {
    return naturalWidth * ratio;
  } else if (isXRay(currentTask)) {
    return XRAY_WIDTH;
  } else {
    return IMAGE_5D_WIDTH;
  }
};

export const getHeight = (imagesType, currentTask, xRayImageSize) => {
  if (isNG(imagesType)) {
    return NG_HEIGHT;
  } else if (isXRay(currentTask)) {
    return xRayImageSize.height;
  } else {
    return IMAGE_5D_HEIGHT;
  }
};

export const calcIfImageIsRotated = (rotationAngle) =>
  Math.abs(rotationAngle) === 90 || Math.abs(rotationAngle) === 270;

export const getOverflowImageScaleDownValue = (
  imageIsRotatedSideways,
  xRayImageSize
) =>
  imageIsRotatedSideways ? IMAGE_CONTAINER_HEIGHT / xRayImageSize.width : 1;

export const getImageSizeObject = (imageRef, currentTask) => {
  if (!imageRef || !imageRef.current) return;

  const IMAGE_MAX_WIDTH = 1267;
  const IMAGE_MAX_HEIGHT = 700;

  const { naturalHeight, naturalWidth, width, height } = imageRef.current;
  let imageSize;
  if (isXRay(currentTask)) {
    const widthPercent = IMAGE_MAX_WIDTH / naturalWidth;
    const heightPercent = IMAGE_MAX_HEIGHT / naturalHeight;
    const smallestPercent = Math.min(widthPercent, heightPercent);

    const calculatedWidth = naturalWidth * smallestPercent;
    const calculatedHeight = naturalHeight * smallestPercent;
    imageSize = { width: calculatedWidth, height: calculatedHeight };
  } else {
    imageSize = { width, height };
  }
  const naturalSize = { naturalWidth, naturalHeight };

  return {
    imageSize,
    naturalSize,
  };
};

export const getDimensionsOfBordersAndTags = (
  width,
  height,
  naturalWidth,
  naturalHeight,
  borderPadding,
  paddingPositioning,
  minX,
  minY,
  maxX,
  maxY
) => {
  const distanceX = maxX || maxX === 0 ? maxX - minX : 0;
  const distanceY = maxY || maxY === 0 ? maxY - minY : 0;
  const ratioWidth = width / naturalWidth;
  const ratioHeight = height / naturalHeight;
  const borderWidth = distanceX * ratioWidth + borderPadding;
  const borderHeight = distanceY * ratioHeight + borderPadding;
  const borderLeft = minX - paddingPositioning / ratioWidth;
  const borderTop = minY - paddingPositioning / ratioHeight;

  const positionX = setX(borderLeft, width, naturalWidth);
  const positionY = setY(borderTop, height, naturalHeight);

  return { borderWidth, borderHeight, positionX, positionY };
};
