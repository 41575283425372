import { AuthenticationContext } from 'react-adal';
import logger from '../logger';
import config from './adalConfig.json';

export const adalConfig = {
  tenant: config.tenant,
  clientId: config.clientId,
  redirectUri: config.redirectUri,
  endpoints: {
    api: `https://${config.tenant}/${config.clientId}`,
  },
  cacheLocation: 'sessionStorage',
  navigateToLoginRequestUrl: false,
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = async () => await getTokenHelper();

export const getUser = () => authContext.getCachedUser();

function getTokenHelper() {
  return new Promise((resolve) => {
    authContext.acquireToken(adalConfig.clientId, (errorDesc, token, error) => {
      if (token) {
        resolve(token);
      } else {
        logger
          .error('getTokenHelper')
          .data({ module: 'adalConfig', err: error })
          .end();
        if (getUser()) {
          authContext.acquireTokenRedirect(adalConfig.endpoints.api);
        }
        resolve(null); // for tests
      }
    });
  });
}
