import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  commentsSelector,
  currentContouringSelector,
  currentTypeSelector,
  detailsSelector,
  findingsSelector,
  materialSelector,
  subTypeSelector,
  typeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import { NotificationContainer } from 'react-notifications';
import {
  outputSelector,
  setIsOutputModified,
} from '../../../redux/taskStateImages/outputSlice';
import { isEmptyToothMark, isValidToothIndication } from '../shared-logic';
import saveDialog from '../Dialogs/saveDialog';
import styles from './Save.module.css';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { overrideCtrlKey } from '../../../shared-logic/keyFunctions';
import { KeyCodes } from '../../../shared-logic/enums';
import { isOutputModifiedSelector } from '../../../redux/taskStateImages/outputSlice';
import { useSaveState, useGetNotesAfterSave } from '../customHooks';
import { NOT_SELECTED } from '../../../shared-logic/params';
import userActionLogs from '../../../shared-logic/userActionLogs';
import {
  isImage,
  isTier2ImageLevel,
} from '../../../shared-logic/taskLevelsTypesHelper';
import {
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { selectRole } from '../../../redux/tasks/tasksSlice';
import { isEqual } from 'lodash';
import { isSupervisor, isWatcher } from '../../TasksList/rolesUtil';
const Save = ({ disabled }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const details = useSelector(detailsSelector);
  const findings = useSelector(findingsSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const comments = useSelector(commentsSelector);
  const tier = useSelector(tierSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const role = useSelector(selectRole);
  const output = useSelector(outputSelector, isEqual);
  const isOutputModified = useSelector(isOutputModifiedSelector);
  const saveHitRef = useRef(false);

  const saveState = useSaveState();
  const getSupervisorNotes = useGetNotesAfterSave();

  overrideCtrlKey.add(KeyCodes.S_KEY);

  useHotkeys(
    'ctrl+s',
    () => {
      userActionLogs.addActionLog('ctrl+s clicked');
      handleSave();
    },
    [isOutputModified, output]
  );

  const showDialogSaveImageLevel = () => {
    return (
      (findings.toLowerCase() === NOT_SELECTED ||
        details.toLowerCase() === NOT_SELECTED) &&
      currentType !== null &&
      currentContouring !== null
    );
  };

  const showDialogSaveToothLevel = () => {
    return (
      !isEmptyToothMark(type, subType, material, comments) &&
      !isValidToothIndication(type, subType, material)
    );
  };

  const saveAction = async () => {
    await saveState();
    if (
      isTier2ImageLevel(tier, taskLevel) ||
      ((isWatcher(role) || isSupervisor(role)) && isImage(taskLevel))
    ) {
      await getSupervisorNotes();
    }
  };

  const handleSave = async () => {
    if (!saveHitRef.current) {
      saveHitRef.current = true;
      dispatch(setIsOutputModified(false));
    }

    if (showDialogSaveImageLevel() || showDialogSaveToothLevel()) {
      saveDialog(saveAction);
    } else {
      await saveAction();
    }
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      if (isOutputModified) {
        return '';
      }
    };

    return () => overrideCtrlKey.remove(KeyCodes.S_KEY);
  }, [isOutputModified]);

  useEffect(() => {
    return () => {
      if (history.location.pathname !== '/task') {
        //navigation within app
        saveState();
      }
    };
  }, [history.location, output, saveState, isOutputModified]);

  return (
    <>
      <button
        // NOTE: this is temporary requested feature, to be removed and reverted to the prev version when needed (commented below)
        // disabled={!isOutputModified}
        className={styles.buttonCdn}
        onClick={() => {
          userActionLogs.addActionLog('save clicked');
          handleSave();
        }}
        disabled={disabled}
      >
        Save
      </button>
      <NotificationContainer />
    </>
  );
};

Save.propTypes = {
  /**
   * If `true`, button is disabled
   */
  disabled: PropTypes.bool,
};

export default Save;
